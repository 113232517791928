import React from "react";
import Button from "@material-ui/core/Button";
import styled from "styled-components";

import ButtonGroup from "@material-ui/core/ButtonGroup";
import Container from "@material-ui/core/Container";

import loginIcon from "../../../assets/images/AppVault/ico_login.png";
import continueIcon from "../../../assets/images/AppVault/ico_tri.png";
import { getUrlParameterByName } from "../../../common/util/string_utility";

import { withRouter } from "react-router-dom";

const PrevLoginPage = (props) => {
  //const dispatch = useDispatch();
  //const auth = useSelector(selectAuth);

  //const [email, setEmail] = useState("");
  //const [password, setPassword] = useState("");
  const GroupedButton = styled(Button)`
    height: 75px !important;
    line-height: 75px;
    font-size: 15px !important;
    padding: 0 0 0 25px;
    text-align: left;
    text-transform: capitalize;
    font-weight: bold;
    font-size: 100%;
    justify-content: flex-start !important;
  `;
  const GroupedImg = styled.img`
    margin-right: 25px;
  `;
  const { history } = props;
  const company = getUrlParameterByName("company");
  return (
    <Container maxWidth="sm" style={{ minHeight: "100vh", marginTop: "40px" }}>
      <ButtonGroup
        orientation="vertical"
        size="large"
        aria-label="vertical contained primary button group"
        fullWidth={true}
      >
        <GroupedButton
          onClick={() => {
            history.push("/external/home?company=" + encodeURIComponent(company));
          }}
        >
          <GroupedImg src={loginIcon} />
          External Page
        </GroupedButton>
        <GroupedButton
          onClick={() => {
            history.push("/internal/home?company=" + encodeURIComponent(company));
          }}
        >
          <GroupedImg src={continueIcon} />
          Internal Page
        </GroupedButton>
      </ButtonGroup>
    </Container>
  );
};

export default withRouter(PrevLoginPage);
