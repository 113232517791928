import React from "react";

import { withRouter } from "react-router-dom";

import styled from "styled-components";
import headerImageAD from "../../assets/images/addev/ico_header.png";
import headerImageAES from "../../assets/images/Aes/ico_header.png";
import headerImagePP from "../../assets/images/Peapod/ico_header.png";
import headerImageGD from "../../assets/images/GiantDeliv/ico_header.png";
import headerImageDist from "../../assets/images/adusadist/ico_header.png";
import headerImageProc from "../../assets/images/adusaproc/ico_header.svg";
import headerImageASC from "../../assets/images/adusasupplychain/ico_header.svg";
import headerImageTrans from "../../assets/images/adusatrans/ico_header.png";
import headerImageFL from "../../assets/images/foodlion/ico_header.jpg";
import headerImageHA from "../../assets/images/hannaford/ico_header.svg";
import headerImageRBS from "../../assets/images/RBS/ico_header.png";
import headerImageSC from "../../assets/images/supplychain/ico_header.svg";
import headerImageCorp from "../../assets/images/adcorp/ico_header.png";
import headerImageSNS from "../../assets/images/stopnandshop/ico_header.png";
import headerImageGF from "../../assets/images/giantfood/ico_header.png";
import headerImageGC from "../../assets/images/giantcompany/ico_header.jpg";


import { getUrlParameterByName } from "../../common/util/string_utility";
import { BorderAll } from "@material-ui/icons";

const MainNav = (props) => {
  const company = getUrlParameterByName("company", true);
  const loginType =
    window.location.href.indexOf("internal") >= 0 ? "internal" : "external";
  let headerImage;
  if (company) {
    switch (company.toLowerCase()) {
      case "aedev":
        headerImage = headerImageAES;
        break;
      case "ahold e-commerce sales company":
        case "ahold+e-commerce+sales+company":
        headerImage = headerImageAES;
        break;
      case "pddev":
        headerImage = headerImagePP;
        break;
      case "peapod digital labs holdings llc":
      case "peapod digital labs llc":
        headerImage = headerImageCorp;
        break;
      case "addev":
        headerImage = headerImageAD;
        break;
      case "ahold delhaize corp support llc":
        case "ahold+delhaize+corp+support+llc":
        headerImage = headerImageAD;
        break;
      case "gddev":
        headerImage = headerImageGD;
        break;
      case "giant delivery llc":
        case "giant+delivery+llc":
        headerImage = headerImageGD;
        break;
        case "adusa+distribution,+llc":
      case "adusa distribution, llc":
        headerImage = headerImageDist;
        break;
      case "adusa transportation, llc":
        case "adusa+transportation,+llc":
        headerImage = headerImageTrans;
        break;
      case "adusa procurement, llc":
        case "adusa+procurement,+llc":
        headerImage = headerImageCorp;
        break;
      case "adusa supply chain services, llc":
      case "adusa supply chain, llc":
        headerImage = headerImageASC;
        break;
      case "food lion, llc":
        case "food+lion,+llc":
        headerImage = headerImageFL;
        break;
        case "hannaford bros. co., llc":
          case "martin's foods of south burlington, llc":
          case "victory distributors, llc":
          case "hannaford+bros.+co.,+llc":
          case "martin's+foods+of+south+burlington,+llc":
          case "victory+distributors,+llc":
        headerImage = headerImageHA;
        break;
      case "retail business services llc" :
        headerImage = headerImageCorp;
        break;
      case "ahold delhaize usa":
      case "ahold delhaize usa, inc.":
      case "guiding star licensing company":
      case "ahold delhaize usa services, llc":
        case "ahold+delhaize+usa":
          case "ahold+delhaize+usa,+inc.":
          case "guiding+star+licensing+company":
          case "ahold+delhaize+usa+services,+llc":
            
        headerImage = headerImageCorp;
        break;
        case "the+stop+and+shop+supermarket+company+llc":
          case "the+stop+&+shop+supermarket+company+llc":
      case "the stop and shop supermarket company llc":
      case "the stop & shop supermarket company llc":
        headerImage = headerImageSNS;
        break;
      case "the giant company llc":
        case "the+giant+company+llc":
        headerImage = headerImageGC;
        break;
      case "giant of maryland llc":
        case "giant+of+maryland+llc":
        headerImage = headerImageGF;
        break;
      default:
        headerImage = headerImageGD;
    }
  }

  const AppBar = styled.div`
    background-color: #fff;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #000;
    padding: 10px;
  `;
  const HeaderImg = styled.img`
    max-height: 60px;

    &:hover {
      cursor: pointer;
    }
  `;
  return (
    <AppBar position="static">
      {headerImage && (
        <HeaderImg
          src={headerImage}
          onClick={() => {
            if (loginType === "external")
              window.location.href = "/external/home?company=" + encodeURIComponent(company);
            else window.location.href = "/internal/home?company=" + encodeURIComponent(company);
          }}
        />
      )}
    </AppBar>
  );
};

export default withRouter(MainNav);
