import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getServiceBasePath,
  getUrlParameterByName,
} from "../../../common/util/string_utility";
import { userLogin } from "../../../features/auth/states/auth_slice";
import { jobApply } from "./job_slice";
import { callApi } from "../../../common/util/fetch";
import isArray from "lodash/isArray";
import map from "lodash/map";
import moment from "moment";


const jobId = getUrlParameterByName("jobId");
const company = getUrlParameterByName("company", true);
export const userRegister = createAsyncThunk(
  "external/register",
  async (params, thunkAPI) => {
    const baseUrl = getServiceBasePath("auth");

    return await callApi(
      thunkAPI,
      `${baseUrl}/register/external?jobId=${jobId}&company=${company}`,
      {
        method: "POST",
        body: JSON.stringify(params),
        fullEndpoint: true,
        // credentials: "include",
      }
    );
  }
);

export const initRegister = createAsyncThunk(
  "external/init-register",
  async (params, thunkAPI) => {
    const baseUrl = getServiceBasePath("auth");

    return await callApi(
      thunkAPI,
      `${baseUrl}/register/external?jobId=${jobId}&company=${company}`,
      {
        method: "GET",
        fullEndpoint: true,
        // credentials: "include",
      }
    );
  }
);

export const uploadResume = createAsyncThunk(
  "external/upload-resume",
  async (params, thunkAPI) => {
    const baseUrl = getServiceBasePath("auth");
    return await callApi(thunkAPI, `${baseUrl}/upload/external?type=resume`, {
      method: "POST",
      body: params,
      ignoreContentType: true,
      fullEndpoint: true,
      // credentials: "include",
    });
  }
);
export const uploadCoverLetter = createAsyncThunk(
  "external/upload-cl",
  async (params, thunkAPI) => {
    const baseUrl = getServiceBasePath("auth");
    return await callApi(
      thunkAPI,
      `${baseUrl}/upload/external?type=cover-letter`,
      {
        method: "POST",
        body: params,
        ignoreContentType: true,
        fullEndpoint: true,
        // credentials: "include",
      }
    );
  }
);
export const deleteResume = createAsyncThunk(
  "external/delete-resume",
  async (params, thunkAPI) => {
    const baseUrl = getServiceBasePath("auth");
    return await callApi(thunkAPI, `${baseUrl}/upload/external?type=resume`, {
      method: "DELETE",
      fullEndpoint: true,
      // credentials: "include",
    });
  }
);
export const deleteCoverLetter = createAsyncThunk(
  "external/delete-cl",
  async (params, thunkAPI) => {
    const baseUrl = getServiceBasePath("auth");
    return await callApi(
      thunkAPI,
      `${baseUrl}/upload/external?type=cover-letter`,
      {
        method: "DELETE",
        fullEndpoint: true,
        // credentials: "include",
      }
    );
  }
);
export const updateProfile = createAsyncThunk(
  "external/update-profile",
  async (params, thunkAPI) => {
    const baseUrl = getServiceBasePath("auth");
    return await callApi(thunkAPI, `${baseUrl}/profile/external`, {
      method: "POST",
      body: JSON.stringify(params),
      fullEndpoint: true,
      // credentials: "include",
    });
  }
);

export const submitJobApplication = createAsyncThunk(
  "external/job-application",
  async (params, thunkAPI) => {
    const baseUrl = getServiceBasePath("auth");
    return await callApi(thunkAPI, `${baseUrl}/application/external/apply`, {
      method: "POST",
      body: JSON.stringify(params),
      fullEndpoint: true,
      // credentials: "include",
    });
  }
);

// export const getInternalUser = createAsyncThunk(
//   "internal/getuser",
//   async (params, thunkAPI) => {
//       return await userManager.getUser();
//   }
// );

export const accountSlice = createSlice({
  name: "account",
  initialState: {
    message: undefined,
    errorField: undefined,
    onLoading: false,
    success: undefined,
    registerSuccess: undefined,
    account_profile: undefined,
    readyRegister: undefined,
    jobApplySuccess: undefined,
    application: undefined,
    errors: undefined,
    sessionExpired: undefined,
    onUploading: undefined,
    uploadingSuccess: undefined,
    uploadResumeErrorMessage: undefined,
    uploadCoverLetterSuccess: undefined,
    uploadCoverLetterErrorMessage: undefined,
    deleteResumeSuccess: undefined,
    deleteCoverLetterSuccess: undefined,
  },
  reducers: {},
  extraReducers: {
    [uploadResume.pending]: (state, action) => {
      state.onUploading = true;
      state.uploadingSuccess = undefined;
      state.uploadResumeErrorMessage = undefined;
    },
    [uploadResume.fulfilled]: (state, action) => {
      state.onUploading = undefined;
      state.uploadingSuccess =
        action.payload && action.payload.success === true;
      state.account_profile.resume = {
        exists: true,
        lastUpdated: moment().format("MM/DD/YYYY"),
      };
    },
    [uploadResume.rejected]: (state, action) => {
      state.onUploading = undefined;
      state.uploadingSuccess = undefined;
      state.uploadResumeErrorMessage =
        action.payload && action.payload.errors
          ? action.payload.errors[0].message
          : "Unknown error";
    },
    [uploadCoverLetter.pending]: (state, action) => {
      state.onUploading = true;
      state.uploadCoverLetterSuccess = undefined;
      state.uploadCoverLetterErrorMessage = undefined;
    },
    [uploadCoverLetter.fulfilled]: (state, action) => {
      state.onUploading = undefined;
      state.uploadCoverLetterSuccess =
        action.payload && action.payload.success === true;
      state.account_profile.coverLetter = {
        exists: true,
        lastUpdated: moment().format("MM/DD/YYYY"),
      };
    },
    [uploadCoverLetter.rejected]: (state, action) => {
      state.onUploading = undefined;
      state.uploadCoverLetterSuccess = undefined;
      state.uploadCoverLetterErrorMessage =
        action.payload && action.payload.errors
          ? action.payload.errors[0].message
          : "Unknown error";
    },

    [initRegister.fulfilled]: (state, action) => {
      state.readyRegister = true;
      state.account_profile = undefined;
    },
    [userRegister.pending]: (state, action) => {
      state.onLoading = true;
      state.message = undefined;
      state.registerSuccess = undefined;
      state.errorField = undefined;
      state.account_profile = undefined;
    },
    [userRegister.fulfilled]: (state, action) => {
      state.onLoading = false;
      if (action.payload && action.payload.success === true) {
        state.message = undefined;
        state.registerSuccess = true;
        state.account_profile = action.payload.profile;
        sessionStorage.setItem("appvault-login", true);
      } else {
        state.registerSuccess = false;
        if (action.payload.errors) {
          state.message = action.payload.errors.message;
          state.errorField = action.payload.errors.field;
        } else {
          state.message = action.payload.message;
        }
      }
    },
    [userRegister.rejected]: (state, action) => {
      state.onLoading = false;
      if (action.payload.errors && isArray(action.payload.errors)) {
        state.errorField = action.payload.errors[0].field;
        state.message = action.payload.errors[0].message;
      } else {
        state.message = action.payload.message;
      }
      state.registerSuccess = false;
      state.account_profile = undefined;
    },
    [jobApply.pending]: (state, action) => {
      state.account_profile = undefined;
    },
    [userLogin.pending]: (state, action) => {
      state.account_profile = undefined;
    },
    [userLogin.fulfilled]: (state, action) => {
      if (action.payload.success && action.payload.success === true) {
        state.account_profile = action.payload.profile;
      }
    },
    /*[getInternalUser.pending]: (state, action) => {
      state.internal_profile_loading = true;
      state.email = undefined;
    },*/
    // [getInternalUser.fulfilled]: (state, action) => {
    //   //if (action.payload.success && action.payload.success === true) {
    //   //  state.account_profile = action.payload.profile;
    //   //}
    //   state.internal_profile_loading = false;
    //   state.email = action.payload.profile.email;
    //   state.last_name = action.payload.profile.family_name;
    //   state.first_name = action.payload.profile.given_name;
    //   //state.internal_profile = {email: action.payload.email};
    // },
    [jobApply.fulfilled]: (state, action) => {
      if (action.payload && action.payload.success && action.payload.profile) {
        state.account_profile = action.payload.profile;
      }
    },
    [updateProfile.pending]: (state, action) => {
      state.onLoading = true;
      state.success = undefined;
      state.message = undefined;
      state.application = undefined;
      state.sessionExpired = undefined;
    },
    [updateProfile.fulfilled]: (state, action) => {
      state.onLoading = undefined;
      state.success = true;
      state.message = "Update profile success";
      state.sessionExpired = undefined;
      if (action.payload && action.payload.application) {
        state.application = action.payload.application;
      }
    },
    [updateProfile.rejected]: (state, action) => {
      state.onLoading = undefined;
      state.success = undefined;
      state.message = "";
      if (action.payload.errors && isArray(action.payload.errors)) {
        state.errorField = map(action.payload.errors, (error) => {
          return error.field;
        });
        state.message = map(action.payload.errors, (error) => {
          return error.message;
        });
        if (state.code === "SESSION_TIMEDOUT") {
          state.sessionExpired = true;
        }
      } else {
        state.message = action.payload.message
          ? [action.payload.message]
          : ["Unknown error"];
      }
    },
    [submitJobApplication.pending]: (state, action) => {
      state.onLoading = true;
      state.jobApplySuccess = undefined;
      state.errors = undefined;
    },
    [submitJobApplication.fulfilled]: (state, action) => {
      state.onLoading = false;
      state.jobApplySuccess = action.payload && action.payload.success === true;
      state.errors = undefined;
    },
    [submitJobApplication.rejected]: (state, action) => {
      state.onLoading = undefined;
      state.jobApplySuccess = false;
      state.errors =
        action.payload && action.payload.errors
          ? action.payload.errors
          : "Unknown error, please try again";
    },
    [deleteResume.pending]: (state, action) => {
      state.deleteResumeSuccess = false;
      state.onUploading = true;
    },
    [deleteResume.fulfilled]: (state, action) => {
      state.onUploading = false;
      state.deleteResumeSuccess = true;
    },
    [deleteCoverLetter.pending]: (state, action) => {
      state.deleteCoverLetterSuccess = false;
      state.onUploading = true;
    },
    [deleteCoverLetter.fulfilled]: (state, action) => {
      state.onUploading = false;
      state.deleteCoverLetterSuccess = true;
    },
  },
});

export const selectAccount = (state) => state.account;

export default accountSlice.reducer;
