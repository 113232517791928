import React from "react";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import UsStateSelector from "../../../common/components/us_state_selector";
import UsStateSelectorExt from "../../../common/components/us_state_selector_ext";

const BasicInformation = (props) => {
  const loginType =
    window.location.href.indexOf("internal") >= 0 ? "internal" : "external";
  const {
    userEmail,
    userPhone,
    homePhone,
    userAddress1,
    userAddress2,
    firstName,
    lastName,
    city,
    profileVisibility,
    employeeId,
    setUserPhone,
    setUserEmail,
    setHomePhone,
    setUserAddress1,
    setUserAddress2,
    setFirstName,
    setLastName,
    preFirstName,
    setPreFirstnName,
    middleName,
    setMiddleName,
    zipCode,
    setZipCode,
    setCity,
    state,
    setState,
    setProfileVisibility,
    setEmployeeId
  } = props;
  return (
    <Container maxWidth="sm">
      <Grid component="label"
        container
        alignItems="center"
        style={{textAlign: 'left', paddingBottom: '20px', paddingTop: '10px'}}>
           Please provide any additional information you would like us to consider.<br />
           {loginType === "internal" && (
             <Typography>
                  Don't know your <strong>Username?</strong> Please <a href="https://players.brightcove.net/4468173432001/default_default/index.html?videoId=6353966111112" target="_blank"><strong>CLICK HERE</strong></a> to see where you can find your <strong>Username</strong> within SuccessFactors.
             </Typography>
           )}
      </Grid>
      {loginType === "internal" && (
         <TextField
         required
         label="Username"
         fullWidth
         variant="outlined"
         value={employeeId}
         style={{ marginBottom: "20px" }}
         onChange={(e) => {
           setEmployeeId(e.target.value);
         }}
       />
      )}
      <TextField
        required
        label="First Name"
        fullWidth
        variant="outlined"
        value={firstName}
        style={{ marginBottom: "20px" }}
        onChange={(e) => {
          setFirstName(e.target.value);
        }}
      />
      <TextField
        label="Middle Name"
        fullWidth
        variant="outlined"
        value={middleName}
        style={{ marginBottom: "20px" }}
        onChange={(e) => {
          setMiddleName(e.target.value);
        }}
      />
      <TextField
        required
        label="Last Name"
        fullWidth
        variant="outlined"
        value={lastName}
        style={{ marginBottom: "20px" }}
        onChange={(e) => {
          setLastName(e.target.value);
        }}
      />
      <TextField
        label="Home Phone"
        fullWidth
        variant="outlined"
        value={homePhone}
        style={{ marginBottom: "20px" }}
        onChange={(e) => {
          setHomePhone(e.target.value);
        }}
      />
      <TextField
        required
        label="Cell Phone"
        fullWidth
        variant="outlined"
        value={userPhone}
        style={{ marginBottom: "20px" }}
        onChange={(e) => {
          setUserPhone(e.target.value);
        }}
      />
      <TextField
        required
        label="Email"
        fullWidth
        variant="outlined"
        value={userEmail}
        style={{ marginBottom: "20px" }}
        onChange={(e) => {
          setUserEmail(e.target.value);
        }}
      />
      <TextField
        label="Preferred Name"
        fullWidth
        variant="outlined"
        value={preFirstName}
        style={{ marginBottom: "20px" }}
        onChange={(e) => {
          setPreFirstnName(e.target.value);
        }}
      />
      {loginType === "external" && (
        <TextField
          required
          label="Address Line 1"
          fullWidth
          variant="outlined"
          style={{ marginBottom: "20px" }}
          value={userAddress1}
          onChange={(e) => {
            setUserAddress1(e.target.value);
          }}
        />
      )}
      {loginType === "external" && (
        <TextField
          label="Address Line 2"
          fullWidth
          variant="outlined"
          value={userAddress2}
          style={{ marginBottom: "20px" }}
          onChange={(e) => {
            setUserAddress2(e.target.value);
          }}
        />
      )}
         
      <Grid
        container
        spacing={1}
        style={{
          alignItems: "baseline",
        }}
      >
        <Grid item sm={6} xs={6} md={4} lg={4}>
          <TextField
            label="City"
            required
            fullWidth
            variant="outlined"
            value={city}
            style={{ marginBottom: "20px" }}
            onChange={(e) => {
              setCity(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={6} xs={6} md={4} lg={4}>
        {loginType === "internal" && (
            <UsStateSelector
              title="State *"
              data={state}
              handleChange={(e) => {
                setState(e.target.value);
              }}
            />
          )
        }
        {loginType === "external" && (
            <UsStateSelectorExt
              title="State *"
              data={state}
              handleChange={(e) => {
                setState(e.target.value);
                //console.log(e.nativeEvent.target.outerText);
              }}
            />
          )
        }
        </Grid>
        <Grid item sm={6} xs={6} md={4} lg={4}>
          <TextField
            label="ZIP code"
            required
            fullWidth
            variant="outlined"
            value={zipCode}
            style={{ marginBottom: "20px" }}
            onChange={(e) => {
              setZipCode(e.target.value);
            }}
          />
        </Grid>
      </Grid>
      
      <FormControl fullWidth variant="outlined">
        <InputLabel id="select-visibility-outlined-label">
          Profile Visibility
        </InputLabel>
        <Select
          labelId="select-visibility-outlined-label"
          id="visibility-select-outlined"
          value={profileVisibility}
          onChange={(e) => {
            setProfileVisibility(e.target.value);
          }}
          label="Profile Visibility"
        >
          <MenuItem value="1">Any company recruiter</MenuItem>
          <MenuItem value="0">
            Any company recruiter in my geographic region
          </MenuItem>
          <MenuItem value="2">
            Only recruiters managing jobs I apply to
          </MenuItem>
        </Select>
      </FormControl>
    </Container>
  );
};

export default BasicInformation;
