import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import FormHelperText from "@material-ui/core/FormHelperText";
import TermDialog from "../../auth/components/term_dialog";
import isEmail from "validator/lib/isEmail";
import AppLoading from "../../../common/components/loading";
import { getUrlParameterByName } from "../../../common/util/string_utility";
import {
  selectAccount,
  userRegister,
  initRegister,
} from "../../external/states/account_slice";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

const CreatAccountPage = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [openTermDialog, setOpenTermDialog] = useState(false);
  const [userEmail, setUserEmail] = useState();
  const [userFirstName, setUserFirstName] = useState();
  const [showSignIn, setShowSignIn] = useState(false);
  const [userLastName, setUserLastName] = useState();
  const [userPassword, setUserPassword] = useState();
  const [rePassword, setRePassword] = useState();
  const [userEmailError, setUserEmailError] = useState(undefined);
  const [userFirstNameError, setUserFirstNameError] = useState(undefined);
  const [userLastNameError, setUserLastNameError] = useState(undefined);
  const [userPasswordError, setUserPasswordError] = useState(undefined);
  const [rePasswordError, setRePasswordError] = useState(undefined);
  const [profileVisibility, setProfileVisibility] = useState("1");
  const [userCountry, setUserCountry] = useState("US");
  const [acceptUserTerms, setAcceptUserTerms] = useState(false);
  const accountState = useSelector(selectAccount);
  const dispatch = useDispatch();

  const loginType =
    window.location.href.indexOf("internal") >= 0 ? "internal" : "external";
  const company = getUrlParameterByName("company", true);

  const verifyInput = () => {
    setUserEmailError(undefined);
    setUserPasswordError(undefined);
    setRePasswordError(undefined);
    setUserFirstNameError(undefined);
    setUserLastNameError(undefined);
    setShowSignIn(false);
    let success = true;
    if (!userEmail || userEmail.trim() === "" || !isEmail(userEmail)) {
      setUserEmailError("Please input an email address");
      success = false;
    }
    if (!userPassword || userPassword.trim() === "") {
      setUserPasswordError("Password can not be empty!");
      success = false;
    } else {
      if (userPassword.trim().length < 8) {
        setUserPasswordError("Password length must be greater than 8");
        success = false;
      } else {
        if (userPassword !== rePassword) {
          setRePasswordError("re-enter password not matched");
          success = false;
        }
      }
    }
    if (!userFirstName || userFirstName.trim() === "") {
      setUserFirstNameError("First name can not be empty");
      success = false;
    }
    // if (!(/^(?=.*[a-zA-Z])([a-zA-Z]+)?(\d{1})?([a-zA-Z]+)?$/g.test(userFirstName))) {
    //   setUserFirstNameError("First name field is invalid");
    //   success = false;
    // }
    if (!userLastName || userLastName.trim() === "") {
      setUserLastNameError("Last name can not be empty");
      success = false;
    }
    // if (!(/^(?=.*[a-zA-Z])([a-zA-Z]+)?(\d{1})?([a-zA-Z]+)?$/g.test(userLastName))) {
    //   setUserLastNameError("Last name field is invalid");
    //   success = false;
    // }
    if (success) {
      if (loginType === "internal") {
        props.history.push(
          "/internal/profile?jobId=" +
            getUrlParameterByName("jobId") +
            "&company=" +
            encodeURIComponent(company)
        );
      }
      const body = {
        email: userEmail.trim(),
        reEmail: userEmail.trim(),
        password: userPassword.trim(),
        rePassword: rePassword.trim(),
        firstName: userFirstName.trim(),
        lastName: userLastName.trim(),
        tosAgreement: true,
        profileVisibility: profileVisibility,
        country: userCountry,
        company: company,
      };

      dispatch(userRegister(body));
    }
  };
  useEffect(() => {
    if (loginType === "internal") {
      //TODO
      return;
    }
    if (!accountState.readyRegister) {
      dispatch(initRegister());
    }
  }, [accountState.readyRegister]);
  useEffect(() => {
    if (loginType === "internal") {
      //TODL
      return;
    }
    if (accountState.registerSuccess === true) {
      props.history.push(
        "/external/profile?jobId=" +
          getUrlParameterByName("jobId") +
          "&company=" +
          encodeURIComponent(company)
      );
    } else if (accountState.registerSuccess === false) {
      if (accountState.message.toLowerCase().indexOf("password") >= 0) {
        setUserPasswordError(accountState.message);
      } else {
        setUserEmailError(accountState.message);
        setShowSignIn(true);
      }
    }
  }, [accountState.registerSuccess]);

  if (loginType === "external") {
    if (
      (accountState && accountState.onLoading === true) ||
      !accountState.readyRegister
    ) {
      return (
        <Container
          maxWidth="sm"
          style={{ marginTop: "20vh", textAlign: "center" }}
        >
          <AppLoading />
        </Container>
      );
    }
  }

  const passwordHint =
    "Password must be 8~18 characters long containing no white space, at least one upper case letter, lower case letter, and at least one non-alpha character";
  return (
    <Container maxWidth="sm" style={{ minHeight: "100vh", marginTop: "5vh" }}>
      <TextField
        required
        label="Email"
        fullWidth
        variant="outlined"
        value={userEmail}
        onChange={(e) => {
          setUserEmail(e.target.value.trim());
        }}
        error={userEmailError !== undefined}
        helperText={userEmailError}
      />
      {showSignIn === true && (
        <div style={{ color: "red", fontSize: "0.75rem", marginLeft: "15px" }}>
          Please{" "}
          <a
            style={{ color: "red" }}
            href={
              "/" +
              loginType +
              "/login?jobId=" +
              getUrlParameterByName("jobId") +
              "&company=" +
              encodeURIComponent(company)
            }
          >
            Sign In
          </a>{" "}
          with your existing account.
        </div>
      )}
      <FormControl fullWidth variant="outlined" style={{ marginTop: "20px" }}>
        <InputLabel
          htmlFor="outlined-adornment-password"
          error={userPasswordError !== undefined}
        >
          Password *
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          type={showPassword ? "text" : "password"}
          value={userPassword}
          error={userPasswordError !== undefined}
          onChange={(e) => {
            setUserPassword(e.target.value.trim());
            if (e.target.value && rePassword && e.target.value !== rePassword) {
              setRePasswordError("Password not matched");
            } else {
              setRePasswordError(undefined);
            }
          }}
          helperText={userPasswordError}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
                onMouseDown={(e) => {
                  e.preventDefault();
                }}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          labelWidth={85}
        />
        <FormHelperText
          id="outlined-adornment-password"
          error={userPasswordError}
        >
          {userPasswordError
            ? userPasswordError + ". " + passwordHint
            : passwordHint}
        </FormHelperText>
      </FormControl>
      <FormControl fullWidth variant="outlined" style={{ marginTop: "20px" }}>
        <InputLabel
          htmlFor="outlined-adornment-re-password"
          error={rePasswordError !== undefined}
        >
          Enter password again *
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-re-password"
          type={showPassword ? "text" : "password"}
          value={rePassword}
          error={rePasswordError !== undefined}
          onChange={(e) => {
            setRePassword(e.target.value.trim());
            if (
              e.target.value &&
              userPassword &&
              e.target.value !== userPassword
            ) {
              setRePasswordError("Password not matched");
            } else {
              setRePasswordError(undefined);
            }
          }}
          helperText={rePasswordError}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
                onMouseDown={(e) => {
                  e.preventDefault();
                }}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          labelWidth={180}
        />
        {rePasswordError && (
          <FormHelperText id="outlined-adornment-re-password" error>
            {rePasswordError}
          </FormHelperText>
        )}
      </FormControl>
      <TextField
        required
        label="First Name"
        fullWidth
        variant="outlined"
        error={userFirstNameError !== undefined}
        helperText={userFirstNameError}
        value={userFirstName}
        onChange={(e) => {
          setUserFirstName(e.target.value);
        }}
        style={{ margin: "20px 0" }}
      />
      <TextField
        required
        label="Last Name"
        fullWidth
        variant="outlined"
        value={userLastName}
        onChange={(e) => {
          setUserLastName(e.target.value);
        }}
        error={userLastNameError !== undefined}
        helperText={userLastNameError}
      />
      <FormControl fullWidth variant="outlined" style={{ marginTop: "20px" }}>
        <InputLabel id="select-outlined-label">
          Country of Residence *
        </InputLabel>
        <Select
          labelId="select-outlined-label"
          id="country-select-outlined"
          value={userCountry}
          onChange={(e) => {
            setUserCountry(e.target.value);
          }}
          label="Country of Residence *"
        >
          <MenuItem value="US">United States</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth variant="outlined" style={{ marginTop: "20px" }}>
        <InputLabel id="select-visibility-outlined-label">
          Profile Visibility
        </InputLabel>
        <Select
          labelId="select-visibility-outlined-label"
          id="visibility-select-outlined"
          value={profileVisibility}
          onChange={(e) => {
            setProfileVisibility(e.target.value);
          }}
          label="Profile Visibility"
        >
          <MenuItem value="1">Any company recruiter</MenuItem>
          <MenuItem value="0">
            Any company recruiter in my geographic region
          </MenuItem>
          <MenuItem value="2">
            Only recruiters managing jobs I apply to
          </MenuItem>
        </Select>
      </FormControl>
      <Grid
        container
        spacing={1}
        style={{
          textAlign: "center",
          marginTop: "40px",
          alignItems: "baseline",
        }}
      >
        <Grid
          item
          sm={4}
          xs={4}
          md={4}
          lg={4}
          style={{ textAlign: "left", paddingLeft: "20px" }}
        >
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setOpenTermDialog(true);
            }}
            style={{ top: "12px", position: "relative" }}
          >
            Click Here to Accept Terms of Use
          </a>
        </Grid>
        <Grid item sm={4} xs={4} md={4} lg={4}>
          <Button
            color="secondary"
            variant="contained"
            disabled={acceptUserTerms !== true}
            onClick={(e) => {
              verifyInput();
            }}
            fullWidth
          >
            Continue
          </Button>
        </Grid>
        <Grid item sm={4} xs={4} md={4} lg={4}></Grid>
      </Grid>
      <TermDialog
        open={openTermDialog}
        setAgreeTerms={(b) => {
          setAcceptUserTerms(b);
          setOpenTermDialog(false);
        }}
      />
    </Container>
  );
};

export default withRouter(CreatAccountPage);
