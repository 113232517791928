import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import { selectJob, jobRequest, jobApply } from "../states/job_slice";
import { selectAccount } from "../states/account_slice";
import { useDispatch, useSelector } from "react-redux";
import { getUrlParameterByName } from "../../../common/util/string_utility";
import AppLoading from "../../../common/components/loading";
import { withRouter } from "react-router-dom";

const ExternalIndex = (props) => {
  const dispatch = useDispatch();
  const jobState = useSelector(selectJob);
  const accountState = useSelector(selectAccount);
  const jobId = getUrlParameterByName("jobId");
  const companyId = getUrlParameterByName("company", true);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  useEffect(() => {
    if (!jobState.onLoading && accountState.account_profile === undefined) {
      dispatch(jobRequest(jobId));
    }
  }, [jobId]);
  /*
  useEffect(() => {
    if (jobApplySuccess === false) {
    }
  }, [jobState.jobApplySuccess]);*/
  useEffect(() => {
    if (jobState.jobReqSuccess === true) {
      dispatch(jobApply());
    } else {
      if (jobState.errorMessage) {
        setOpenErrorDialog(true);
      }
    }
  }, [jobState.jobReqSuccess]);
  useEffect(() => {
    if (jobState.jobApplySuccess === true) {
      if (jobState.message === "Login page displayed") {
        props.history.push(
          "/external/login?jobId=" + jobId + "&company=" + encodeURIComponent(companyId)
        );
      }
    } else {
      if (jobState.errorMessage) setOpenErrorDialog(true);
    }
  }, [jobState.jobApplySuccess]);
  useEffect(() => {
    if (accountState.account_profile) {
      props.history.push(
        "/external/profile?jobId=" + jobId + "&company=" + encodeURIComponent(companyId)
      );
    }
  }, [accountState.account_profile]);

  if (jobState.onLoading === true) {
    return (
      <Container
        maxWidth="sm"
        style={{ minHeight: "100vh", marginTop: "20vh", textAlign: "center" }}
      >
        <AppLoading />
      </Container>
    );
  }

  return (
    <Container maxWidth="sm">
      <Dialog fullWidth={true} maxWidth={"sm"} open={openErrorDialog}>
        <DialogContent>
          <DialogContentText>
            <strong>{jobState.errorMessage}</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            color="secondary"
            variant="contained"
            onClick={(e) => {
              sessionStorage.removeItem("ma-session");
              window.location.replace("/external/home?company=" + encodeURIComponent(companyId));
            }}
          >
            Go to job list
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default withRouter(ExternalIndex);
