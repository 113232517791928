import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getServiceBasePath,
  getUrlParameterByName,
} from "../../../common/util/string_utility";
import { jobApply } from "./job_slice";
import { callApi } from "../../../common/util/fetch";
import isArray from "lodash/isArray";
import map from "lodash/map";
import moment from "moment";
import { StaticDateRangePicker } from "@material-ui/pickers";

const jobId = getUrlParameterByName("jobId");
const company = getUrlParameterByName("company", true);

export const internalUploadResume = createAsyncThunk(
  "internal/upload-resume",
  async (params, thunkAPI) => {
    const baseUrl = getServiceBasePath("auth");
    return await callApi(thunkAPI, `${baseUrl}/upload/internal?type=resume`, {
      method: "POST",
      body: params,
      ignoreContentType: true,
      fullEndpoint: true,
      // credentials: "include",
    });
  }
);
export const internalUploadCoverLetter = createAsyncThunk(
  "internal/upload-cl",
  async (params, thunkAPI) => {
    const baseUrl = getServiceBasePath("auth");
    return await callApi(
      thunkAPI,
      `${baseUrl}/upload/internal?type=cover-letter`,
      {
        method: "POST",
        body: params,
        ignoreContentType: true,
        fullEndpoint: true,
        // credentials: "include",
      }
    );
  }
);
export const internalDeleteResume = createAsyncThunk(
  "internal/delete-resume",
  async (params, thunkAPI) => {
    const baseUrl = getServiceBasePath("auth");
    return await callApi(thunkAPI, `${baseUrl}/upload/internal?type=resume`, {
      method: "DELETE",
      fullEndpoint: true,
      body: JSON.stringify(params),
      // credentials: "include",
    });
  }
);
export const internalDeleteCoverLetter = createAsyncThunk(
  "internal/delete-cl",
  async (params, thunkAPI) => {
    const baseUrl = getServiceBasePath("auth");
    return await callApi(
      thunkAPI,
      `${baseUrl}/upload/internal?type=cover-letter`,
      {
        method: "DELETE",
        fullEndpoint: true,
        // credentials: "include",
      }
    );
  }
);
export const internalUpdateProfile = createAsyncThunk(
  "internal/update-profile",
  async (params, thunkAPI) => {
    const baseUrl = getServiceBasePath("auth");
    return await callApi(thunkAPI, `${baseUrl}/profile/internal`, {
      method: "POST",
      body: JSON.stringify(params),
      fullEndpoint: true,
      // credentials: "include",
    });
  }
);

export const getProfile = createAsyncThunk(
   "internal/get-profile",
   async (params, thunkAPI) => {
    const baseUrl = getServiceBasePath("auth");
    return await callApi(thunkAPI, `${baseUrl}/profile/internal`, {
      method: "GET",
      body: JSON.stringify(params),
      fullEndpoint: true,
      // credentials: "include",
    });
  }
);

export const submitJobApplication = createAsyncThunk(
  "internal/job-application",
  async (params, thunkAPI) => {
    const baseUrl = getServiceBasePath("auth");
    return await callApi(thunkAPI, `${baseUrl}/application/internal/apply`, {
      method: "POST",
      body: JSON.stringify(params),
      fullEndpoint: true,
      // credentials: "include",
    });
  }
);



// export const getInternalUser = createAsyncThunk(
//   "internal/getuser",
//   async (params, thunkAPI) => {
//       return await userManager.getUser();
//   }
// );

export const internalAccountSlice = createSlice({
  name: "internalaccount",
  initialState: {
    message: undefined,
    errorField: undefined,
    onLoading: false,
    success: undefined,
    account_profile: undefined,
    jobApplySuccess: undefined,
    application: undefined,
    errors: undefined,
    onUploading: undefined,
    uploadingSuccess: undefined,
    uploadResumeErrorMessage: undefined,
    uploadCoverLetterSuccess: undefined,
    uploadCoverLetterErrorMessage: undefined,
    deleteResumeSuccess: undefined,
    deleteCoverLetterSuccess: undefined,
    getProfileSuccess: undefined
  },
  reducers: {},
  extraReducers: {
    [internalUploadResume.pending]: (state, action) => {
      state.onUploading = true;
      state.uploadingSuccess = undefined;
      state.uploadResumeErrorMessage = undefined;
    },
    [internalUploadResume.fulfilled]: (state, action) => {
      state.onUploading = undefined;
      state.uploadingSuccess =
        action.payload && action.payload.success === true;
      state.account_profile.resume = {
        exists: true,
        lastUpdated: moment().format("MM/DD/YYYY"),
        attachmentId: action.payload.attachmentId
      };
    },
    [internalUploadResume.rejected]: (state, action) => {
      state.onUploading = undefined;
      state.uploadingSuccess = undefined;
      state.uploadResumeErrorMessage =
        action.payload && action.payload.errors
          ? action.payload.errors[0].message
          : "Unknown error";
    },
    [internalUploadCoverLetter.pending]: (state, action) => {
      state.onUploading = true;
      state.uploadCoverLetterSuccess = undefined;
      state.uploadCoverLetterErrorMessage = undefined;
    },
    [internalUploadCoverLetter.fulfilled]: (state, action) => {
      state.onUploading = undefined;
      state.uploadCoverLetterSuccess =
        action.payload && action.payload.success === true;
      state.account_profile.coverLetter = {
        exists: true,
        lastUpdated: moment().format("MM/DD/YYYY"),
        attachmentId: action.payload.attachmentId
      };
    },
    [internalUploadCoverLetter.rejected]: (state, action) => {
      state.onUploading = undefined;
      state.uploadCoverLetterSuccess = undefined;
      state.uploadCoverLetterErrorMessage =
        action.payload && action.payload.errors
          ? action.payload.errors[0].message
          : "Unknown error";
    },

    [jobApply.pending]: (state, action) => {
      state.account_profile = undefined;
    },
    /*[getInternalUser.pending]: (state, action) => {
      state.internal_profile_loading = true;
      state.email = undefined;
    },*/
    // [getInternalUser.fulfilled]: (state, action) => {
    //   //if (action.payload.success && action.payload.success === true) {
    //   //  state.account_profile = action.payload.profile;
    //   //}
    //   state.internal_profile_loading = false;
    //   state.email = action.payload.profile.email;
    //   state.last_name = action.payload.profile.family_name;
    //   state.first_name = action.payload.profile.given_name;
    //   //state.internal_profile = {email: action.payload.email};
    // },
    [jobApply.fulfilled]: (state, action) => {
      if (action.payload && action.payload.success && action.payload.profile) {
        state.account_profile = action.payload.profile;
      }
    },
    [internalUpdateProfile.pending]: (state, action) => {
      state.onLoading = true;
      state.success = undefined;
      state.message = undefined;
      state.application = undefined;
      state.sessionExpired = undefined;
    },
    [internalUpdateProfile.fulfilled]: (state, action) => {
      state.onLoading = undefined;
      state.success = true;
      state.message = "Update profile success";
      state.sessionExpired = undefined;
      if (action.payload && action.payload.application) {
        state.application = action.payload.application;
      }
    },
    [internalUpdateProfile.rejected]: (state, action) => {
      state.onLoading = undefined;
      state.success = undefined;
      state.message = "";
      if (action.payload.errors && isArray(action.payload.errors)) {
        state.errorField = map(action.payload.errors, (error) => {
          return error.field;
        });
        state.message = map(action.payload.errors, (error) => {
          return error.message;
        });
        if (state.code === "SESSION_TIMEDOUT") {
          state.sessionExpired = true;
        }
      } else {
        state.message = action.payload.message
          ? [action.payload.message]
          : ["Unknown error"];
      }
    },
    [submitJobApplication.pending]: (state, action) => {
      state.onLoading = true;
      state.jobApplySuccess = undefined;
      state.errors = undefined;
    },
    [submitJobApplication.fulfilled]: (state, action) => {
      state.onLoading = false;
      state.jobApplySuccess = action.payload && action.payload.success === true;
      state.errors = undefined;
    },
    [submitJobApplication.rejected]: (state, action) => {
      state.onLoading = undefined;
      state.jobApplySuccess = false;
      state.errors =
        action.payload && action.payload.errors
          ? action.payload.errors
          : "Unknown error, please try again";
    },
    [internalDeleteResume.pending]: (state, action) => {
      state.deleteResumeSuccess = false;
      state.onUploading = true;
    },
    [internalDeleteResume.fulfilled]: (state, action) => {
      state.onUploading = false;
      state.deleteResumeSuccess = true;
    },
    [internalDeleteCoverLetter.pending]: (state, action) => {
      state.deleteCoverLetterSuccess = false;
      state.onUploading = true;
    },
    [internalDeleteCoverLetter.fulfilled]: (state, action) => {
      state.onUploading = false;
      state.deleteCoverLetterSuccess = true;
    },
    [getProfile.fulfilled]: (state, action) => {
      state.getProfileSuccess = true;
      state.account_profile = {};
    }
  },
});

export const internalSelectAccount = (state) => state.internalaccount;

export default internalAccountSlice.reducer;
