import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import { getUrlParameterByName } from "../../common/util/string_utility";

const Home = (props) => {
  const company = getUrlParameterByName("company", true);
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      overflow: "hidden",
      backgroundColor: theme.palette.background.paper,
      padding: 0,
      borderRadius: "5px",
    },
    paper: {
      maxWidth: 400,
      margin: `${theme.spacing(1)}px auto`,
      padding: theme.spacing(2),
    },
  }));
  const classes = useStyles();

  return (
    <Container maxWidth="sm" style={{ minHeight: "100vh", marginTop: "20px" }}>
      <List
        component="nav"
        className={classes.root}
        aria-label="external innternal"
      >
        <ListItem
          button
          onClick={() => {
            props.history.push("/external/home?company=" + encodeURIComponent(company));
          }}
          style={{ padding: "15px 20px" }}
        >
          <ListItemText primary="External Job List" />
        </ListItem>
        <Divider />
        <ListItem
          button
          onClick={() => {
            props.history.push("/internal/home?company=" + encodeURIComponent(company));
          }}
          style={{ padding: "15px 20px" }}
        >
          <ListItemText primary="Internal Job List" />
        </ListItem>
      </List>
    </Container>
  );
};

export default withRouter(Home);
