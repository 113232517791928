import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import { useSnackbar } from "notistack";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import AppLoading from "../../../common/components/loading";
import { getUrlParameterByName } from "../../../common/util/string_utility";
import { useDispatch, useSelector } from "react-redux";
import map from "lodash/map";
import findIndex from "lodash/findIndex";
import cloneDeep from "lodash/cloneDeep";
import ApplicationQuestions from "../../common/components/questions";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import moment from "moment";
import {
  internalSelectAccount,
  submitJobApplication
} from "../states/account_slice";
import forEach from "lodash/forEach";
import isArray from "lodash/isArray";
import ReactGA from 'react-ga4';
import {
  getBooleanOptions
} from "../../internal/components/job_options";
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";
import { MobileDatePicker, LocalizationProvider } from "@material-ui/pickers";

const InternalJobApply = (props) => {
  const dispatch = useDispatch();
  const accountState = useSelector(internalSelectAccount);

  const [gaInit, setGaInit] = useState(false);
  const [jobApplySuccess, setJobApplySuccess] = useState(false);
  const [firstName, setFirstName] = useState();
  const [middleName, setMiddleName] = useState();
  const [lastName, setLastName] = useState();
  const [suffix, setSuffix] = useState();
  const [contactEmail, setContactEmail] = useState();
  const [cellPhone, setCellPhone] = useState();
  const [homePhone, setHomePhone] = useState();
  const [over18YearsOld, setOver18YearsOld] = useState(undefined);
  const [hasFormerName, setHasFormerName] = useState();
  const [formerName, setFormerName] = useState();
  const [questionValues, setQuestionValues] = useState([]);

  const [isRetail, setIsRetail] = useState();
  const [
    polygraphStatementMarylandReceived,
    setPolygraphStatementMarylandReceived,
  ] = useState(undefined);
  const [
    polygraphStatementMassachusettsReceived,
    setPolygraphStatementMassachusettsReceived,
  ] = useState(undefined);
  const [agreementWaiver, setAgreementWaiver] = useState(undefined);
  const [shiftAvailabilities, setShiftAvailabilities] = useState();
  const [
    preferredMethodOfCommunication,
    setPreferredMethodOfCommunication,
  ] = useState();
  const [gender /*, setGender*/] = useState();
  const [ethnic /*, setEthic*/] = useState();
  const [signature, setSignature] = useState();
  const [isPharmacy, setIsPharmacy] = useState();
  const [pharmacy, setPharmacy] = useState(undefined);
  const [isDOT, setIsDOT] = useState();
  const [dot, setDOT] = useState(undefined);
  const [dateOfBirth, setDateOfBirth] = useState(null);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const action = (key) => (
    <Button
      size="small"
      color="default"
      onClick={() => {
        closeSnackbar(key);
      }}
    >
      Dismiss
    </Button>
  );
  
  const company = getUrlParameterByName("company", true);

  useEffect(() => {
    if (accountState.errors) {
      if (isArray(accountState.errors)) {
        forEach(accountState.errors, (error) => {
          enqueueSnackbar(error.message, {
            variant: "error",

            action,
          });
        });
      } else {
        enqueueSnackbar(accountState.errors, {
          variant: "error",

          action,
        });
      }
    }
  }, [accountState.errors]);
  useEffect(() => {
    if (accountState.application) {
      const {
        firstName,
        middleName,
        lastName,
        suffix,
        contactEmail,
        cellPhone,
        homePhone,
        preferredMethodOfCommunication,
        over18YearsOld,
        hasFormerName,
        formerName,
        shiftAvailabilities,
        isRetail,
        questions,
        isDOT,
        dot,
        isPharmacy,
        pharmacy
      } = accountState.application;
      setFirstName(firstName);
      setLastName(lastName);
      setMiddleName(middleName);
      setSuffix(suffix);
      setContactEmail(contactEmail);
      setCellPhone(cellPhone);
      setHomePhone(homePhone);
      setPreferredMethodOfCommunication(preferredMethodOfCommunication);
      if (over18YearsOld !== undefined && isRetail === true) {
        setOver18YearsOld(over18YearsOld);
      }
      if (shiftAvailabilities !== undefined && isRetail === true) {
        setShiftAvailabilities(shiftAvailabilities);
      }
      setHasFormerName(hasFormerName);
      setFormerName(formerName);
      setShiftAvailabilities(shiftAvailabilities);
      setIsRetail(isRetail);
      setIsDOT(isDOT);
      setDOT(dot);
      setIsPharmacy(isPharmacy);
      setPharmacy(pharmacy);
      const vals = [];
      map(questions, (q) => {
        vals.push({ id: q.id, value: q.value });
      });
      setQuestionValues(vals);
    }
  }, accountState.application);

  const setQuestionVal = (idx, val) => {
    const index = findIndex(questionValues, { id: idx });
    let qvals = cloneDeep(questionValues);
    qvals.splice(index, 1, { id: idx, value: val });
    setQuestionValues(qvals);
  };

  useEffect(() => {
    if (!gaInit) {
      let tracker2 = 'G-E126M15ZVB';
      if (company) {
        switch (company.toLowerCase()) {
            case "ahold e-commerce sales company":
              case "ahold+e-commerce+sales+company":
              tracker2 = 'G-Y6LBBD1JM8';
              break;
            case "peapod digital labs llc":
              tracker2 = 'G-0SRCNRM49R';
              break;
            case "ahold delhaize corp support llc":
              case "ahold+delhaize+corp+support+llc":
              tracker2 = 'G-FNF6G20BGE';
              break;
            case "giant delivery llc":
              case "giant+delivery+llc":
              tracker2 = 'G-F597CJX59P';
              break; 
              case "adusa+distribution,+llc":          
            case "adusa distribution, llc":
              tracker2 = 'G-6BVGD5VBX6';
              break;
            case "adusa transportation, llc":
              case "adusa+transportation,+llc":
              tracker2 = 'G-6BVGD5VBX6';
              break;
            case "adusa procurement, llc":
              case "adusa+procurement,+llc":
              tracker2 = 'G-YQ24BBTRHX';
              break;
            case "adusa supply chain services, llc":
            case "adusa supply chain, llc":
              tracker2 = 'G-6BVGD5VBX6';
              break;
            case "food lion, llc":
              case "food+lion,+llc":
              tracker2 = 'G-JCGW010YGQ';
              break;
              case "hannaford bros. co., llc":
                case "martin's foods of south burlington, llc":
                case "victory distributors, llc":
                case "hannaford+bros.+co.,+llc":
                case "martin's+foods+of+south+burlington,+llc":
                case "victory+distributors,+llc":
              tracker2 = 'G-WKDMPMX8GJ';
              break;
            case "ahold delhaize usa":
            case "ahold delhaize usa, inc.":
            case "guiding star licensing company":
            case "ahold delhaize usa services, llc":
              case "ahold+delhaize+usa":
          case "ahold+delhaize+usa,+inc.":
          case "guiding+star+licensing+company":
          case "ahold+delhaize+usa+services,+llc":
              tracker2 = 'G-4YP43BLGZ2';
              break;
            case "retail business services llc" :
              tracker2 = 'G-4YP43BLGZ2';
              break;
            case "the stop and shop supermarket company llc":
              tracker2 = 'G-LH1X5V2MF8';
              break;
              case "the+giant+company+llc":
            case "the giant company llc":
              tracker2 = 'G-TZGHT43SBL';
              break;
            case "giant of maryland llc":
              case "giant+of+maryland+llc":
              tracker2 = 'G-MQQQ3DJQE3';
              break;
            case "aedev":
              tracker2 = 'G-FNF6G20BGE';
              break;
            case "gddev":
              tracker2 = 'G-0SRCNRM49R';
              break;
            case "addev":
              tracker2 = 'G-0SRCNRM49R';
              break;
            case "pddev":
            default:
              tracker2 = 'G-0SRCNRM49R';
              break;
          }
        }

        ReactGA.initialize(
          [
            {
              trackingId: 'G-E126M15ZVB',
              gaOptions: {
                name: 'tracker1'
              }
            },
            {
              trackingId: tracker2,
              gaOptions: { name: 'tracker2' }
            }
          ]
        );
        if (getUrlParameterByName("jobId")) {
          ReactGA.send({hitType: 'pageview', page: '/internal/application.html?jobId=' + getUrlParameterByName("jobId"), title: "Application Page"});
        }
        else {
          ReactGA.send({hitType: 'pageview', page: '/internal/application.html', title: "Application Page"});
        }
        setGaInit(true);
    }
  }, [gaInit]);

  const btnApplyClick = (e) => {
    e.preventDefault();
    let hasError = false;

    if (isEmpty(firstName) || isUndefined(firstName)) {
      enqueueSnackbar("First name can not be empty", {
        variant: "error",

        action,
      });
      hasError = true;
    }
    if (isEmpty(lastName) || isUndefined(lastName)) {
      enqueueSnackbar("Last name can not be empty", {
        variant: "error",
        action,
      });
      hasError = true;
    }
    if (isEmpty(contactEmail) || isUndefined(contactEmail)) {
      enqueueSnackbar("Email can not be empty", {
        variant: "error",

        action,
      });
      hasError = true;
    }
    if (isEmpty(cellPhone) || isUndefined(cellPhone)) {
      enqueueSnackbar("Mobile phone can not be empty", {
        variant: "error",

        action,
      });
      hasError = true;
    }
    if (
      hasFormerName === "468" &&
      (isEmpty(formerName) || isUndefined(formerName))
    ) {
      enqueueSnackbar("Previous known name(s) can not be empty", {
        variant: "error",

        action,
      });
      hasError = true;
    }
    if (
      isEmpty(polygraphStatementMarylandReceived) ||
      isUndefined(polygraphStatementMarylandReceived)
    ) {
      enqueueSnackbar(
        "Please select whether accept the statement of Maryland",
        {
          variant: "error",

          action,
        }
      );
      hasError = true;
    }
    if (
      isEmpty(polygraphStatementMassachusettsReceived) ||
      isUndefined(polygraphStatementMassachusettsReceived)
    ) {
      enqueueSnackbar(
        "Please select whether accept the statement of Massachusetts",
        {
          variant: "error",

          action,
        }
      );
      hasError = true;
    }
    
    if (isEmpty(signature) || isUndefined(signature)) {
      enqueueSnackbar("Signature can not be empty", {
        variant: "error",

        action,
      });
      hasError = true;
    }
    if (isUndefined(hasFormerName) || isEmpty(hasFormerName)) {
      enqueueSnackbar(
        '"Have you been known previously by any other name?" is required',
        {
          variant: "error",

          action,
        }
      );
      hasError = true;
    }
    if (isRetail === true) {
      if (isUndefined(shiftAvailabilities.monday) || isEmpty(shiftAvailabilities.monday)) {
          enqueueSnackbar(
            'Shift availablity on Monday is required',
            {
              variant: "error",
    
              action,
            }
          );
          hasError = true;
      }
      if (isUndefined(shiftAvailabilities.tuesday) || isEmpty(shiftAvailabilities.tuesday)) {
          enqueueSnackbar(
            'Shift availablity on Tuesday is required',
            {
              variant: "error",
    
              action,
            }
          );
          hasError = true;
      }
      if (isUndefined(shiftAvailabilities.wednesday) || isEmpty(shiftAvailabilities.wednesday)) {
          enqueueSnackbar(
            'Shift availablity on Wednesday is required',
            {
              variant: "error",
    
              action,
            }
          );
          hasError = true;
      }
      if (isUndefined(shiftAvailabilities.thursday) || isEmpty(shiftAvailabilities.thursday)) {
          enqueueSnackbar(
            'Shift availablity on Thursday is required',
            {
              variant: "error",

              action,
            }
          );
          hasError = true;
      }
      if (isUndefined(shiftAvailabilities.friday) || isEmpty(shiftAvailabilities.friday)) {
          enqueueSnackbar(
            'Shift availablity on Friday is required',
            {
              variant: "error",

              action,
            }
          );
          hasError = true;
        }
        if (isUndefined(shiftAvailabilities.saturday) || isEmpty(shiftAvailabilities.saturday)) {
          enqueueSnackbar(
            'Shift availablity on Saturday is required',
            {
              variant: "error",

              action,
            }
          );
          hasError = true;
        }
        if (isUndefined(shiftAvailabilities.sunday) || isEmpty(shiftAvailabilities.sunday)) {
          enqueueSnackbar(
            'Shift availablity on Sunday is required',
            {
              variant: "error",

              action,
            }
          );
          hasError = true;
        }
    }
    if (isDOT === true) {
      if (isUndefined(dot.ageCheck) || isEmpty(dot.ageCheck)) {
        enqueueSnackbar(
          'Are you at least 21 years old? is required.',
          {
            variant: "error",
  
            action,
          }
        );
        hasError = true;
      }
      if (isUndefined(dot.licenseSuspend) || isEmpty(dot.licenseSuspend)) {
        enqueueSnackbar(
          'Has your driver license, permit, or privilege to operate a motor vehicle ever been denied, suspended or revoked? is required.',
          {
            variant: "error",
  
            action,
          }
        );
        hasError = true;
      }
      if (isUndefined(dot.dateOfBirth) || isEmpty(dot.dateOfBirth)) {
        enqueueSnackbar(
          'Date of Birth is required.',
          {
            variant: "error",
  
            action,
          }
        );
        hasError = true;
      }
    }
    if (isPharmacy === true) {
      if (isUndefined(pharmacy.regPharmacist) || isEmpty(pharmacy.regPharmacist)) {
        enqueueSnackbar(
          ' Are you a registered Pharmacist? is required',
          {
            variant: "error",
  
            action,
          }
        );
        hasError = true;
      }
      if (isUndefined(pharmacy.pharLicense) || isEmpty(pharmacy.pharLicense)) {
        enqueueSnackbar(
          'Please list the License Numbers is required',
          {
            variant: "error",
  
            action,
          }
        );
        hasError = true;
      }
      if (isUndefined(pharmacy.pharBoard1) || isEmpty(pharmacy.pharBoard1)) {
        enqueueSnackbar(
          'Have you ever appeared before any pharmacy board for violation of any pharmacy codes? is required',
          {
            variant: "error",
  
            action,
          }
        );
        hasError = true;
      }
      if (isUndefined(pharmacy.pharBoard2) || isEmpty(pharmacy.pharBoard2)) {
        enqueueSnackbar(
          'Has any state licensing or pharmacy board ever conducted an investigation into your practice? is required',
          {
            variant: "error",
  
            action,
          }
        );
        hasError = true;
      }
      if (isUndefined(pharmacy.pharBoard3) || isEmpty(pharmacy.pharBoard3)) {
        enqueueSnackbar(
          'Has any state licensing or pharmacy board ever denied, restricted, suspended, terminated or imposed probation, reprimand of any other terms and conditions on your license to practice pharmacy? is required',
          {
            variant: "error",
  
            action,
          }
        );
        hasError = true;
      }
      if (isUndefined(pharmacy.pharSanction) || isEmpty(pharmacy.pharSanction)) {
        enqueueSnackbar(
          'Have you, or any pharmacy that you have been associated with, ever been sanctioned by state Medicaid, Federal Medicare Program, or any other governmental Agency? is required',
          {
            variant: "error",
  
            action,
          }
        );
        hasError = true;
      }
      if (isUndefined(pharmacy.pharEduCredit) || isEmpty(pharmacy.pharEduCredit)) {
        enqueueSnackbar(
          'Have you fulfilled all required continuing education credits? is required',
          {
            variant: "error",
  
            action,
          }
        );
        hasError = true;
      }
    }
    if (accountState.application && accountState.application.questions) {
      map(accountState.application.questions, (q, idx) => {
        if (q.required && !questionValues[idx].value) {
          enqueueSnackbar(q.label + " is required", {
            variant: "error",

            action,
          });
          hasError = true;
        }
      });
    }
    
    let params = {
      firstName: firstName,
      lastName: lastName,
      contactEmail: contactEmail,
      cellPhone: cellPhone,
      hasFormerName: hasFormerName,
      formerName: hasFormerName ? formerName : undefined,
      polygraphStatementMarylandReceived: polygraphStatementMarylandReceived,
      polygraphStatementMassachusettsReceived: polygraphStatementMassachusettsReceived,
      gender: gender,
      ethnic: ethnic,
      signature: signature,
      suffix: suffix,
      isRetail: isRetail,
      jobId: getUrlParameterByName("jobId"),
      questions: questionValues,
      isPharmacy: isPharmacy,
      isDOT: isDOT
    };
    if (over18YearsOld !== undefined && isRetail === true && isDOT === false) {
      if (over18YearsOld === "") {
        enqueueSnackbar('"Are you at least 18 years old?" is required', {
          variant: "error",

          action,
        });
        hasError = true;
      }
      params.over18YearsOld = over18YearsOld;
    }
    if (hasError === true) return;
    if (shiftAvailabilities !== undefined && isRetail === true) {
      params.shiftAvailabilities = shiftAvailabilities;
    }
    if (pharmacy !== undefined && isPharmacy === true) {
      params.pharmacy = pharmacy;
    }
    if (dot !== undefined && isDOT === true) {
      params.dot = dot;
    }
    dispatch(submitJobApplication(params));
    //setJobApplySuccess(true);
    //localStorage.clear();
  };
  
  if (accountState.onLoading === true) {
    return (
      <Container
        maxWidth="sm"
        style={{ marginTop: "20vh", textAlign: "center" }}
      >
        <AppLoading />
      </Container>
    );
  }
  if (accountState.jobApplySuccess === true) {
    sessionStorage.removeItem("ma-session");
    if (getUrlParameterByName("jobId")) {
      ReactGA.send({hitType: 'pageview', page: '/internal/thankyou.html?jobId=' + getUrlParameterByName("jobId"), title: "Thank You Page"});
    }
    else {
      ReactGA.send({hitType: 'pageview', page: '/internal/thankyou.html', title: "Thank You Page"});
    }
    return (
      <Container
        maxWidth="sm"
        style={{
          minHeight: "50vh",
          marginTop: "40px",
          padding: "50px",
          textAlign: "center",
        }}
      >
        <h3>Congratulations!</h3>
        Thank you for your application and your interest! We are currently in
        the process of receiving and reviewing applications and will be in touch
        with you as soon as possible.
      </Container>
    );
  }

  return (
    <Container maxWidth="sm" style={{ marginTop: "20px" }}>
      <TextField
        required
        label="First Name"
        fullWidth
        variant="outlined"
        value={firstName}
        style={{ marginBottom: "20px" }}
        onChange={(e) => {
          setFirstName(e.target.value);
        }}
      />
      <TextField
        label="Middle Name"
        fullWidth
        variant="outlined"
        value={middleName}
        style={{ marginBottom: "20px" }}
        onChange={(e) => {
          setMiddleName(e.target.value);
        }}
      />
      <TextField
        required
        label="Last Name"
        fullWidth
        variant="outlined"
        value={lastName}
        style={{ marginBottom: "20px" }}
        onChange={(e) => {
          setLastName(e.target.value);
        }}
      />
      <TextField
        required
        label="Email"
        fullWidth
        variant="outlined"
        value={contactEmail}
        style={{ marginBottom: "20px" }}
        onChange={(e) => {
          setContactEmail(e.target.value.trim());
        }}
      />
      <FormControl
        fullWidth
        variant="outlined"
        style={{ marginBottom: "20px" }}
      >
        <InputLabel id="select-suffix-label">Suffix</InputLabel>
        <Select
          labelId="select-suffix-label"
          id="suffix-outlined"
          value={suffix}
          onChange={(e) => {
            setSuffix(e.target.value);
          }}
          label="Gender"
        >
          <MenuItem value="">No Selection</MenuItem>
          <MenuItem value="1141">I</MenuItem>
          <MenuItem value="1142">II</MenuItem>
          <MenuItem value="556">III</MenuItem>
          <MenuItem value="1143">IV</MenuItem>
          <MenuItem value="557">Jr</MenuItem>
          <MenuItem value="558">Sr</MenuItem>
          <MenuItem value="1144">V</MenuItem>
        </Select>
      </FormControl>
      <TextField
        required
        label="Mobile Phone"
        fullWidth
        variant="outlined"
        value={cellPhone}
        style={{ marginBottom: "20px" }}
        onChange={(e) => {
          setCellPhone(e.target.value.trim());
        }}
      />
      <TextField
        label="Home Phone"
        fullWidth
        variant="outlined"
        value={homePhone}
        style={{ marginBottom: "20px" }}
        onChange={(e) => {
          setHomePhone(e.target.value.trim());
        }}
      />
      <FormControl fullWidth variant="outlined">
        <InputLabel id="select-pref-communication-label">
          Preferred Method of Communication
        </InputLabel>
        <Select
          labelId="select-pref-communication-label"
          id="pref-communication-outlined"
          value={preferredMethodOfCommunication}
          onChange={(e) => {
            setPreferredMethodOfCommunication(e.target.value);
          }}
          label="Preferred Method of Communication"
        >
          <MenuItem value="">No Selection</MenuItem>
          <MenuItem value="74385">Email</MenuItem>
          <MenuItem value="74387">Home Phone</MenuItem>
          <MenuItem value="74386">Mobile Phone - Call</MenuItem>
          <MenuItem value="76640">Mobile Phone - SMS</MenuItem>
        </Select>
      </FormControl>
      {dot !== undefined && isDOT === true && (
        <Grid
        component="label"
        container
        alignItems="center"
        spacing={1}
        style={{
          marginTop: "20px",
          border: "solid 1px grey",
          marginLeft: "1px",
          borderRadius: "5px",
          padding: "10px",
          width: "100%",
        }}
        >
          <div>Department of Transportation Driver Questions</div>
          
          <Grid
            component="label"
            container
            alignItems="center"
            spacing={1}
            style={{
              marginTop: "20px",
              border: "solid 1px grey",
              marginLeft: "1px",
              borderRadius: "5px",
              padding: "10px",
              width: "100%",
            }}
          >
              <Grid
                item
                sm={8}
                xs={8}
                md={10}
                lg={10}
                style={{ color: "rgba(0, 0, 0, 0.7)" }}
              >
                * Are you at least 21 years old?
              </Grid>
              <Grid item>
                <Select
                  variant="outlined"
                  id="ageCheck"
                  fullWidth
                  value={dot.ageCheck}
                  onChange={(e) => {
                    setDOT({
                      ...dot,
                      ageCheck: e.target.value,
                    });
                  }}
                >
                  {getBooleanOptions(company)}
                </Select>
              </Grid>
            </Grid>
            <LocalizationProvider dateAdapter={DateFnsAdapter}>
              <Grid item sm={5} xs={5} md={5} lg={5}>
                <MobileDatePicker
                  views={["year", "month", "date"]}
                  clearable
                  label="Date of Birth"
                  value={dateOfBirth}
                  
                  onChange={(newValue) => {
                    setDOT({
                      ...dot,
                      dateOfBirth: moment(newValue).format("MM/DD/YYYY"),
                    });
                    setDateOfBirth(moment(newValue).format("MM/DD/YYYY"));
                  }}
                  renderInput={(props) => (
                    <TextField 
                    fullWidth
                    variant="outlined"
                    style={{ marginBottom: "10px", marginTop: "20px" }}
                    {...props} />
                  )}
                />
              </Grid>
            </LocalizationProvider>
            <TextareaAutosize
              rowsMax={12}
              rowsMin={4}
              id="licenses"
              value={dot.licenses}
              label="* List all Driver License's (State, License#, License Class, Endorsements, Expiration Date mm/dd/yyyy)"
              placeholder = "* List all Driver License's (State, License#, License Class, Endorsements, Expiration Date mm/dd/yyyy)"
              style={{
                resize: "none",
                width: "100%",
                borderRadius: "5px",
                background: "transparent",
                padding: "5px 10px",
                font: "inherit",
                color: "inherit",
                marginTop: "10px"
              }}
              onChange={(e) => {
                setDOT({
                  ...dot,
                  licenses: e.target.value,
                });
              }}
            ></TextareaAutosize>
            <Grid
            component="label"
            container
            alignItems="center"
            spacing={1}
            style={{
              marginTop: "10px",
              border: "solid 1px grey",
              marginLeft: "1px",
              borderRadius: "5px",
              padding: "10px",
              width: "100%",
            }}
          >
              <Grid
                item
                sm={8}
                xs={8}
                md={10}
                lg={10}
                style={{ color: "rgba(0, 0, 0, 0.7)" }}
              >
                * Has your driver license, permit, or privilege to operate a motor vehicle ever been denied, suspended or revoked?
              </Grid>
              <Grid item>
                <Select
                  variant="outlined"
                  id="licenseSuspend"
                  fullWidth
                  value={dot.licenseSuspend}
                  onChange={(e) => {
                    setDOT({
                      ...dot,
                      licenseSuspend: e.target.value,
                    });
                  }}
                >
                  {getBooleanOptions(company)}
                </Select>
              </Grid>
            
            <TextareaAutosize
              rowsMax={12}
              rowsMin={4}
              id="licenseSuspendText"
              value={dot.licenseSuspendText}
              label="If yes, please explain"
              placeholder = "If yes, please explain"
              style={{
                resize: "none",
                width: "100%",
                borderRadius: "5px",
                background: "transparent",
                padding: "5px 10px",
                font: "inherit",
                color: "inherit",
                marginTop: "10px"
              }}
              onChange={(e) => {
                setDOT({
                  ...dot,
                  licenseSuspendText: e.target.value,
                });
              }}
            ></TextareaAutosize>
          </Grid>
          <TextareaAutosize
              rowsMax={12}
              rowsMin={4}
              id="violations"
              value={dot.violations}
              label="* Please list all motor vehicle violations you have been convicted of or forfeited bond or collateral within the past three (3) years. If not applicable, please enter N/A."
              placeholder = "* Please list all motor vehicle violations you have been convicted of or forfeited bond or collateral within the past three (3) years. If not applicable, please enter N/A."
              style={{
                resize: "none",
                width: "100%",
                borderRadius: "5px",
                background: "transparent",
                padding: "5px 10px",
                font: "inherit",
                color: "inherit",
                marginTop: "10px"
              }}
              onChange={(e) => {
                setDOT({
                  ...dot,
                  violations: e.target.value,
                });
              }}
            ></TextareaAutosize>
            <TextareaAutosize
              rowsMax={12}
              rowsMin={4}
              id="accidents"
              value={dot.accidents}
              label="* Please Indicate all motor vehicle accidents within the past three years (Accident date (mm/dd/yyyy), Nature of the accident (head-on, etc.,), Fatalities, Injuries). If not applicable, please enter N/A."
              placeholder = "* Please Indicate all motor vehicle accidents within the past three years (Accident date (mm/dd/yyyy), Nature of the accident (head-on, etc.,), Fatalities, Injuries). If not applicable, please enter N/A."
              style={{
                resize: "none",
                width: "100%",
                borderRadius: "5px",
                background: "transparent",
                padding: "5px 10px",
                font: "inherit",
                color: "inherit",
                marginTop: "10px"
              }}
              onChange={(e) => {
                setDOT({
                  ...dot,
                  accidents: e.target.value,
                });
              }}
            ></TextareaAutosize>
            <TextareaAutosize
              rowsMax={12}
              rowsMin={4}
              id="commercialLicenses"
              value={dot.commercialLicenses}
              label="* Please enter the state, number and expiration date of each unexpired commercial motor vehicle operator license or permit issued to you."
              placeholder = "* Please enter the state, number and expiration date of each unexpired commercial motor vehicle operator license or permit issued to you."
              style={{
                resize: "none",
                width: "100%",
                borderRadius: "5px",
                background: "transparent",
                padding: "5px 10px",
                font: "inherit",
                color: "inherit",
                marginTop: "10px"
              }}
              onChange={(e) => {
                setDOT({
                  ...dot,
                  commercialLicenses: e.target.value,
                });
              }}
            ></TextareaAutosize>
            <TextareaAutosize
              rowsMax={12}
              rowsMin={4}
              id="equipment"
              value={dot.equipment}
              label="* Please indicate the Class Equipment Experience (including type of Equipment (van, tank, flat, Etc)."
              placeholder = "* Please indicate the Class Equipment Experience (including type of Equipment (van, tank, flat, Etc)."
              style={{
                resize: "none",
                width: "100%",
                borderRadius: "5px",
                background: "transparent",
                padding: "5px 10px",
                font: "inherit",
                color: "inherit",
                marginTop: "10px"
              }}
              onChange={(e) => {
                setDOT({
                  ...dot,
                  equipment: e.target.value,
                });
              }}
            ></TextareaAutosize>
            <TextareaAutosize
              rowsMax={12}
              rowsMin={4}
              id="addresses"
              value={dot.addresses}
              label="* Please provide any addresses you've had in the last three years (Start Date and End Date and format mm/dd/yyyy)."
              placeholder = "* Please provide any addresses you've had in the last three years (Start Date and End Date and format mm/dd/yyyy)."
              style={{
                resize: "none",
                width: "100%",
                borderRadius: "5px",
                background: "transparent",
                padding: "5px 10px",
                font: "inherit",
                color: "inherit",
                marginTop: "10px"
              }}
              onChange={(e) => {
                setDOT({
                  ...dot,
                  addresses: e.target.value,
                });
              }}
            ></TextareaAutosize>
            <TextareaAutosize
              rowsMax={12}
              rowsMin={4}
              id="employers"
              value={dot.employers}
              label="* DOT Drivers need to provide previous employers for the three years preceding this application. Please list, date of employment (three years), reason for leaving, and whether you were subject to FMCSR and if it was a safety sensitive function. If not applicable, please enter N/A."
              placeholder = "* DOT Drivers need to provide previous employers for the three years preceding this application. Please list, date of employment (three years), reason for leaving, and whether you were subject to FMCSR and if it was a safety sensitive function. If not applicable, please enter N/A."
              style={{
                resize: "none",
                width: "100%",
                borderRadius: "5px",
                background: "transparent",
                padding: "5px 10px",
                font: "inherit",
                color: "inherit",
                marginTop: "10px"
              }}
              onChange={(e) => {
                setDOT({
                  ...dot,
                  employers: e.target.value,
                });
              }}
            ></TextareaAutosize>
        </Grid>
        
      )}
      {pharmacy !== undefined && isPharmacy === true && (
        <Grid
          component="label"
          container
          alignItems="center"
          spacing={1}
          style={{
            marginTop: "20px",
            border: "solid 1px grey",
            marginLeft: "1px",
            borderRadius: "5px",
            padding: "10px",
            width: "100%",
          }}
        >
          <div>Pharmacy Questions</div>
          <Grid
            component="label"
            container
            alignItems="center"
            spacing={1}
            style={{
              marginTop: "20px",
              border: "solid 1px grey",
              marginLeft: "1px",
              borderRadius: "5px",
              padding: "10px",
              width: "100%",
            }}
          >
            <Grid
              item
              sm={8}
              xs={8}
              md={10}
              lg={10}
              style={{ color: "rgba(0, 0, 0, 0.7)" }}
            >
              * Are you a registered Pharmacist?
            </Grid>
            <Grid item>
              <Select
                variant="outlined"
                id="regPharmacist"
                fullWidth
                value={pharmacy.regPharmacist}
                onChange={(e) => {
                  setPharmacy({
                    ...pharmacy,
                    regPharmacist: e.target.value,
                  });
                }}
              >
                {getBooleanOptions(company)}
              </Select>
            </Grid>
          </Grid>
          <TextField
            required
            label="Please list the License Numbers"
            fullWidth
            variant="outlined"
            value={pharmacy.pharLicense}
            style={{ marginBottom: "20px", marginTop: "20px" }}
            onChange={(e) => {
              setPharmacy({
                ...pharmacy,
                pharLicense: e.target.value.trim(),
              });
            }}
          />
          <Grid
            component="label"
            container
            alignItems="center"
            spacing={1}
            style={{
              marginTop: "20px",
              border: "solid 1px grey",
              marginLeft: "1px",
              borderRadius: "5px",
              padding: "10px",
              width: "100%",
            }}
          >
            <Grid
              item
              sm={8}
              xs={8}
              md={10}
              lg={10}
              style={{ color: "rgba(0, 0, 0, 0.7)" }}
            >
              * Have you ever appeared before any pharmacy board for violation of any pharmacy codes?
            </Grid>
            <Grid item>
              <Select
                variant="outlined"
                fullWidth
                id="pharBoard1"
                value={pharmacy.pharBoard1}
                onChange={(e) => {
                  setPharmacy({
                    ...pharmacy,
                    pharBoard1: e.target.value,
                  });
                }}
              >
                {getBooleanOptions(company)}
              </Select>
            </Grid>
            <TextareaAutosize
              rowsMax={12}
              rowsMin={4}
              id="pharBoard1Text"
              value={pharmacy.pharBoard1Text}
              label="If yes, please explain"
              placeholder = "If yes, please explain"
              style={{
                resize: "none",
                width: "100%",
                borderRadius: "5px",
                background: "transparent",
                padding: "5px 10px",
                font: "inherit",
                color: "inherit",
                marginTop: "10px"
              }}
              onChange={(e) => {
                setPharmacy({
                  ...pharmacy,
                  pharBoard1Text: e.target.value,
                });
              }}
            ></TextareaAutosize>
          </Grid>
          <Grid
            component="label"
            container
            alignItems="center"
            spacing={1}
            style={{
              marginTop: "20px",
              border: "solid 1px grey",
              marginLeft: "1px",
              borderRadius: "5px",
              padding: "10px",
              width: "100%",
            }}
          >
            <Grid
              item
              sm={8}
              xs={8}
              md={10}
              lg={10}
              style={{ color: "rgba(0, 0, 0, 0.7)" }}
            >
              * Has any state licensing or pharmacy board ever conducted an investigation into your practice?
            </Grid>
            <Grid item>
              <Select
                variant="outlined"
                fullWidth
                id="pharBoard2"
                value={pharmacy.pharBoard2}
                onChange={(e) => {
                  setPharmacy({
                    ...pharmacy,
                    pharBoard2: e.target.value,
                  });
                }}
              >
                {getBooleanOptions(company)}
              </Select>
            </Grid>
            <TextareaAutosize
              rowsMax={12}
              rowsMin={4}
              id="pharBoard2Text"
              value={pharmacy.pharBoard2Text}
              label="If yes, please explain"
              placeholder = "If yes, please explain"
              style={{
                resize: "none",
                width: "100%",
                borderRadius: "5px",
                background: "transparent",
                padding: "5px 10px",
                font: "inherit",
                color: "inherit",
                marginTop: "10px"
              }}
              onChange={(e) => {
                setPharmacy({
                  ...pharmacy,
                  pharBoard2Text: e.target.value,
                });
              }}
            ></TextareaAutosize>
          </Grid>
          <Grid
            component="label"
            container
            alignItems="center"
            spacing={1}
            style={{
              marginTop: "20px",
              border: "solid 1px grey",
              marginLeft: "1px",
              borderRadius: "5px",
              padding: "10px",
              width: "100%",
            }}
          >
            <Grid
              item
              sm={8}
              xs={8}
              md={10}
              lg={10}
              style={{ color: "rgba(0, 0, 0, 0.7)" }}
            >
              * Has any state licensing or pharmacy board ever denied, restricted, suspended, terminated or imposed probation, reprimand of any other terms and conditions on your license to practice pharmacy?
            </Grid>
            <Grid item>
              <Select
                variant="outlined"
                fullWidth
                id="pharBoard3"
                value={pharmacy.pharBoard3}
                onChange={(e) => {
                  setPharmacy({
                    ...pharmacy,
                    pharBoard3: e.target.value,
                  });
                }}
              >
                {getBooleanOptions(company)}
              </Select>
            </Grid>
            <TextareaAutosize
              rowsMax={12}
              rowsMin={4}
              id="pharBoard3Text"
              value={pharmacy.pharBoard3Text}
              label="If yes, please explain"
              placeholder = "If yes, please explain"
              style={{
                resize: "none",
                width: "100%",
                borderRadius: "5px",
                background: "transparent",
                padding: "5px 10px",
                font: "inherit",
                color: "inherit",
                marginTop: "10px"
              }}
              onChange={(e) => {
                setPharmacy({
                  ...pharmacy,
                  pharBoard3Text: e.target.value,
                });
              }}
            ></TextareaAutosize>
          </Grid>
          <Grid
            component="label"
            container
            alignItems="center"
            spacing={1}
            style={{
              marginTop: "20px",
              border: "solid 1px grey",
              marginLeft: "1px",
              borderRadius: "5px",
              padding: "10px",
              width: "100%",
            }}
          >
            <Grid
              item
              sm={8}
              xs={8}
              md={10}
              lg={10}
              style={{ color: "rgba(0, 0, 0, 0.7)" }}
            >
              * Have you, or any pharmacy that you have been associated with, ever been sanctioned by state Medicaid, Federal Medicare Program, or any other governmental Agency?
            </Grid>
            <Grid item>
              <Select
                variant="outlined"
                fullWidth
                id="pharSanction"
                value={pharmacy.pharSanction}
                onChange={(e) => {
                  setPharmacy({
                    ...pharmacy,
                    pharSanction: e.target.value,
                  });
                }}
              >
                {getBooleanOptions(company)}
              </Select>
            </Grid>
            <TextareaAutosize
              rowsMax={12}
              rowsMin={4}
              id="pharSanctionText"
              value={pharmacy.pharSanctionText}
              label="If yes, please explain"
              placeholder = "If yes, please explain"
              style={{
                resize: "none",
                width: "100%",
                borderRadius: "5px",
                background: "transparent",
                padding: "5px 10px",
                font: "inherit",
                color: "inherit",
                marginTop: "10px"
              }}
              onChange={(e) => {
                setPharmacy({
                  ...pharmacy,
                  pharSanctionText: e.target.value,
                });
              }}
            ></TextareaAutosize>
          </Grid>
          <Grid
            component="label"
            container
            alignItems="center"
            spacing={1}
            style={{
              marginTop: "20px",
              border: "solid 1px grey",
              marginLeft: "1px",
              borderRadius: "5px",
              padding: "10px",
              width: "100%",
            }}
          >
            <Grid
              item
              sm={8}
              xs={8}
              md={10}
              lg={10}
              style={{ color: "rgba(0, 0, 0, 0.7)" }}
            >
              * Have you fulfilled all required continuing education credits?
            </Grid>
            <Grid item>
              <Select
                variant="outlined"
                fullWidth
                id="pharEduCredit"
                value={pharmacy.pharEduCredit}
                onChange={(e) => {
                  setPharmacy({
                    ...pharmacy,
                    pharEduCredit: e.target.value,
                  });
                }}
              >
                {getBooleanOptions(company)}
              </Select>
            </Grid>
          </Grid>
        </Grid> 
      )}
      {over18YearsOld !== undefined && isRetail === true && isDOT === false && (
        <FormControl fullWidth variant="outlined" style={{ marginTop: "20px" }}>
          <InputLabel id="select-pref-communication-label">
            * Are you at least 18 years old? *
          </InputLabel>
          <Select
            labelId="select-over-18-label"
            id="over-18-outlined"
            value={over18YearsOld}
            onChange={(e) => {
              setOver18YearsOld(e.target.value);
            }}
            label="* Are you at least 18 years old?"
          >
            <MenuItem value="">No Selection</MenuItem>
            <MenuItem value="782">No</MenuItem>
            <MenuItem value="781">Yes</MenuItem>
          </Select>
        </FormControl>
      )}
      {shiftAvailabilities !== undefined && isRetail === true && (
        <Grid
          component="label"
          container
          alignItems="center"
          spacing={1}
          style={{
            marginTop: "20px",
            border: "solid 1px grey",
            marginLeft: "1px",
            borderRadius: "5px",
            padding: "10px",
            width: "100%",
            color: "rgba(0, 0, 0, 0.7)",
          }}
        >
          <div>* Shift Availability</div>
          <small>
            Please let us know what is your shift availability by each day of
            the week. Shifts: Day (between 7 am – 3 pm); Evening (between 3 pm –
            11 pm); Night (Between 11 pm and 7 am).
          </small>
          <FormControl
            fullWidth
            variant="outlined"
            style={{ marginTop: "10px" }}
          >
            <InputLabel id="select-monday-shift-label">Monday</InputLabel>
            <Select
              labelId="select-monday-shift-label"
              id="monday-shiift-outlined"
              value={shiftAvailabilities.monday}
              onChange={(e) => {
                setShiftAvailabilities({
                  ...shiftAvailabilities,
                  monday: e.target.value,
                });
              }}
              label="Monday"
            >
              <MenuItem value="" selected="">No Selection</MenuItem>
              <MenuItem value="74696">Any time</MenuItem>
              <MenuItem value="74697">Day</MenuItem>
              <MenuItem value="74698">Day and/or Evening</MenuItem>
              <MenuItem value="74699">Day and/or Night Shift</MenuItem>
              <MenuItem value="74700">Evening</MenuItem>
              <MenuItem value="74701">Evening and/or Night Shift</MenuItem>
              <MenuItem value="74702">Night Shift</MenuItem>
              <MenuItem value="74703">Not available on this day</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            fullWidth
            variant="outlined"
            style={{ marginTop: "10px" }}
          >
            <InputLabel id="select-tuesday-shift-label">Tuesday</InputLabel>
            <Select
              labelId="select-tuesday-shift-label"
              id="tuesday-shiift-outlined"
              value={shiftAvailabilities.tuesday}
              onChange={(e) => {
                setShiftAvailabilities({
                  ...shiftAvailabilities,
                  tuesday: e.target.value,
                });
              }}
              label="Tuesday"
            >
              <MenuItem value="" selected="">No Selection</MenuItem>
              <MenuItem value="74696">Any time</MenuItem>
              <MenuItem value="74697">Day</MenuItem>
              <MenuItem value="74698">Day and/or Evening</MenuItem>
              <MenuItem value="74699">Day and/or Night Shift</MenuItem>
              <MenuItem value="74700">Evening</MenuItem>
              <MenuItem value="74701">Evening and/or Night Shift</MenuItem>
              <MenuItem value="74702">Night Shift</MenuItem>
              <MenuItem value="74703">Not available on this day</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            fullWidth
            variant="outlined"
            style={{ marginTop: "10px" }}
          >
            <InputLabel id="select-wednesday-shift-label">Wednesday</InputLabel>
            <Select
              labelId="select-wednesday-shift-label"
              id="wednesday-shiift-outlined"
              value={shiftAvailabilities.wednesday}
              onChange={(e) => {
                setShiftAvailabilities({
                  ...shiftAvailabilities,
                  wednesday: e.target.value,
                });
              }}
              label="Wednesday"
            >
              <MenuItem value="" selected="">No Selection</MenuItem>
              <MenuItem value="74696">Any time</MenuItem>
              <MenuItem value="74697">Day</MenuItem>
              <MenuItem value="74698">Day and/or Evening</MenuItem>
              <MenuItem value="74699">Day and/or Night Shift</MenuItem>
              <MenuItem value="74700">Evening</MenuItem>
              <MenuItem value="74701">Evening and/or Night Shift</MenuItem>
              <MenuItem value="74702">Night Shift</MenuItem>
              <MenuItem value="74703">Not available on this day</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            fullWidth
            variant="outlined"
            style={{ marginTop: "10px" }}
          >
            <InputLabel id="select-thursday-shift-label">Thursday</InputLabel>
            <Select
              labelId="select-thursday-shift-label"
              id="thursday-shiift-outlined"
              value={shiftAvailabilities.thursday}
              onChange={(e) => {
                setShiftAvailabilities({
                  ...shiftAvailabilities,
                  thursday: e.target.value,
                });
              }}
              label="Thursday"
            >
              <MenuItem value="" selected="">No Selection</MenuItem>
              <MenuItem value="74696">Any time</MenuItem>
              <MenuItem value="74697">Day</MenuItem>
              <MenuItem value="74698">Day and/or Evening</MenuItem>
              <MenuItem value="74699">Day and/or Night Shift</MenuItem>
              <MenuItem value="74700">Evening</MenuItem>
              <MenuItem value="74701">Evening and/or Night Shift</MenuItem>
              <MenuItem value="74702">Night Shift</MenuItem>
              <MenuItem value="74703">Not available on this day</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            fullWidth
            variant="outlined"
            style={{ marginTop: "10px" }}
          >
            <InputLabel id="select-friday-shift-label">Friday</InputLabel>
            <Select
              labelId="select-friday-shift-label"
              id="friday-shiift-outlined"
              value={shiftAvailabilities.friday}
              onChange={(e) => {
                setShiftAvailabilities({
                  ...shiftAvailabilities,
                  friday: e.target.value,
                });
              }}
              label="Friday"
            >
              <MenuItem value="" selected="">No Selection</MenuItem>
              <MenuItem value="74696">Any time</MenuItem>
              <MenuItem value="74697">Day</MenuItem>
              <MenuItem value="74698">Day and/or Evening</MenuItem>
              <MenuItem value="74699">Day and/or Night Shift</MenuItem>
              <MenuItem value="74700">Evening</MenuItem>
              <MenuItem value="74701">Evening and/or Night Shift</MenuItem>
              <MenuItem value="74702">Night Shift</MenuItem>
              <MenuItem value="74703">Not available on this day</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            fullWidth
            variant="outlined"
            style={{ marginTop: "10px" }}
          >
            <InputLabel id="select-saturday-shift-label">Saturday</InputLabel>
            <Select
              labelId="select-saturday-shift-label"
              id="saturday-shiift-outlined"
              value={shiftAvailabilities.saturday}
              onChange={(e) => {
                setShiftAvailabilities({
                  ...shiftAvailabilities,
                  saturday: e.target.value,
                });
              }}
              label="Saturday"
            >
              <MenuItem value="" selected="">No Selection</MenuItem>
              <MenuItem value="74696">Any time</MenuItem>
              <MenuItem value="74697">Day</MenuItem>
              <MenuItem value="74698">Day and/or Evening</MenuItem>
              <MenuItem value="74699">Day and/or Night Shift</MenuItem>
              <MenuItem value="74700">Evening</MenuItem>
              <MenuItem value="74701">Evening and/or Night Shift</MenuItem>
              <MenuItem value="74702">Night Shift</MenuItem>
              <MenuItem value="74703">Not available on this day</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            fullWidth
            variant="outlined"
            style={{ marginTop: "10px" }}
          >
            <InputLabel id="select-sunday-shift-label">Sunday</InputLabel>
            <Select
              labelId="select-sunday-shift-label"
              id="sunday-shiift-outlined"
              value={shiftAvailabilities.sunday}
              onChange={(e) => {
                setShiftAvailabilities({
                  ...shiftAvailabilities,
                  sunday: e.target.value,
                });
              }}
              label="Sundayday"
            >
              <MenuItem value="" selected="">No Selection</MenuItem>
              <MenuItem value="74696">Any time</MenuItem>
              <MenuItem value="74697">Day</MenuItem>
              <MenuItem value="74698">Day and/or Evening</MenuItem>
              <MenuItem value="74699">Day and/or Night Shift</MenuItem>
              <MenuItem value="74700">Evening</MenuItem>
              <MenuItem value="74701">Evening and/or Night Shift</MenuItem>
              <MenuItem value="74702">Night Shift</MenuItem>
              <MenuItem value="74703">Not available on this day</MenuItem>
            </Select>
          </FormControl>
          <TextareaAutosize
            rowsMax={12}
            rowsMin={4}
            value={shiftAvailabilities.comments}
            label="Comments about your availability"
            placeholder = "Comments about your availability"
            style={{
              resize: "none",
              width: "100%",
              borderRadius: "5px",
              background: "transparent",
              padding: "5px 10px",
              font: "inherit",
              color: "inherit",
              marginTop: "10px"
            }}
            onChange={(e) => {
              setShiftAvailabilities({
                ...shiftAvailabilities,
                comments: e.target.value,
              });
            }}
          ></TextareaAutosize>
        </Grid>
      )}
      <Grid
        component="label"
        container
        alignItems="center"
        spacing={1}
        style={{
          marginTop: "20px",
          border: "solid 1px grey",
          marginLeft: "1px",
          borderRadius: "5px",
          padding: "10px",
          width: "100%",
        }}
      >
        <Grid
          item
          sm={8}
          xs={8}
          md={10}
          lg={10}
          style={{ color: "rgba(0, 0, 0, 0.7)" }}
        >
          * Have you been known previously by any other name(s)?
        </Grid>
        <Grid item>
          <Select
            variant="outlined"
            fullWidth
            value={hasFormerName}
            onChange={(e) => {
              setHasFormerName(e.target.value);
            }}
          >
            <MenuItem value=""> </MenuItem>
            <MenuItem value="781">Yes</MenuItem>
            <MenuItem value="782">No</MenuItem>
          </Select>
        </Grid>
        {hasFormerName === "781" && (
          <Grid item sm={12} xs={12} md={12} lg={12}>
            <TextField
              required
              label=""
              fullWidth
              variant="outlined"
              value={formerName}
              onChange={(e) => {
                setFormerName(e.target.value);
              }}
            />
          </Grid>
        )}
      </Grid>
      <ApplicationQuestions
        questions={
          accountState.application ? accountState.application.questions : []
        }
        values={questionValues}
        changeValue={setQuestionVal}
      />
      
      <Grid component="div" container style={{ border: '1px solid lightgray', padding: '10px', marginTop: '25px'}}>
        <Grid
            item
            style={{ textAlign: 'left', paddingBottom: '10px'}}
          >
            <strong>Maryland Polygraph Statement </strong><br /><br />Under
                      Maryland law, an employer may not require or demand, as a
                      condition of employment, prospective employment, or
                      continued employment, that an individual submit to or take
                      a lie detector or similar test. An employer who violates
                      this law is guilty of a misdemeanor and subject to a fine
                      not to exceed $100.

          </Grid>

        <FormControl fullWidth variant="outlined">
            <Select
              fullWidth
              id="select-maryland-poly-statement"
              value={polygraphStatementMarylandReceived}
              onChange={(e) => {
                setPolygraphStatementMarylandReceived(e.target.value);
              }}
            >
              <MenuItem value="">No Selection</MenuItem>
              <MenuItem value="76644">No</MenuItem>
              <MenuItem value="76645">Not Applicable</MenuItem>
              <MenuItem value="76643">Yes</MenuItem>
            </Select>
          </FormControl>
        
      </Grid>
      <Grid component="div" container style={{ border: '1px solid lightgray', padding: '10px', marginTop: '25px'}}>
        <Grid
            item
            style={{ textAlign: 'left', paddingBottom: '10px'}}
          >
            <strong>Massachusetts Polygraph Statement </strong><br /><br />For
                      applicants seeking employment in the Commonwealth of
                      Massachusetts: It is unlawful in Massachusetts to require
                      or administer a lie detector test as a condition of
                      employment or continued employment. An employer who
                      violates this law shall be subject to criminal penalties
                      and civil liability.

          </Grid>

          <FormControl fullWidth variant="outlined">
            <Select
              fullWidth
              id="select-massachusetts-poly-statement"
              value={polygraphStatementMassachusettsReceived}
              onChange={(e) => {
                setPolygraphStatementMassachusettsReceived(e.target.value);
              }}
            >
              <MenuItem value="">No Selection</MenuItem>
              <MenuItem value="76644">No</MenuItem>
              <MenuItem value="76645">Not Applicable</MenuItem>
              <MenuItem value="76643">Yes</MenuItem>
            </Select>
          </FormControl>
        
      </Grid>
      <Grid
          component="label"
          container
          alignItems="center"
          spacing={1}
          style={{
            width: "100%",
            border: '1px solid lightgray', 
            padding: '10px', 
            marginTop: '25px'
          }}
        >
          <strong>Agreement of Waiver</strong><br /><br />
          <TextareaAutosize
            rowsMax={12}
            rowsMin={4}
            readOnly
            value = {"I certify that the statements made on this application are complete and accurate to the best of my knowledge. I understand that such statements may be investigated to verify accuracy. I further understand that any misleading or incorrect information, misrepresentation, or omission of facts may render this application void or may be cause for immediate dismissal whenever discovered.\r\n\r\nI understand that, where applicable, an offer of employment may be conditional on the results of a job related physical examination and/or functional job capacity test and/or drug screening test by a physician and/or laboratory designated by the Company. Furthermore, any job offer may also be contingent upon satisfactory references.\r\n\r\nThe Company’s acceptance of this application does not necessarily indicate that there are positions open at present. I recognize that any of the Company’s policies, plans, and procedures may be modified or amended at any time at the discretion of the Company. If employed, I agree to conform to the Company’s applicable rules and policies. If I become employed in a position covered by a collective bargaining agreement, the terms and conditions of my employment will be governed by such collective bargaining agreement.\r\n\r\nIf I am not employed in a position covered by a collective bargaining agreement, I understand that I will be an employee at-will and my employment may be terminated by either party at any time for any reason or no reason at all."}
            style={{
              resize: "none",
              width: "100%",
              borderRadius: "5px",
              border: "none",
              background: "transparent",
              font: "inherit",
              color: "inherit",
            }}
          >
          </TextareaAutosize>
      </Grid>
      <TextField
        required
        label="Signature"
        fullWidth
        variant="outlined"
        value={signature}
        style={{ marginBottom: "20px", marginTop: "10px" }}
        onChange={(e) => {
          setSignature(e.target.value);
        }}
      />
      <div style={{ padding: "20px", textAlign: "center" }}>
        <Button
          color="primary"
          variant="contained"
          component="div"
          onClick={btnApplyClick}
        >
          Apply
        </Button>
      </div>
    </Container>
  );
};

export default InternalJobApply;
