import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";
import { MobileDatePicker, LocalizationProvider } from "@material-ui/pickers";
const WorkInfo = (props) => {
  const loginType =
    window.location.href.indexOf("internal") >= 0 ? "internal" : "external";

  const { handleRemove, info } = props;

  const [workTitle, setWorkTitle] = useState();
  const [companyName, setCompanyName] = useState();
  const [typeOfBusiness, setTypeOfBusiness] = useState(
    info ? info.typeOfBusiness : undefined
  );
  const [workFunc, setWorkFunc] = useState(info ? info.function : undefined);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  useEffect(() => {
    if (!isEmpty(info.endDate) && info.endDate !== "MM/DD/YYYY") {
      setEndDate(moment(info.endDate).toDate());
    }
    if (!isEmpty(info.startDate) && info.startDate !== "MM/DD/YYYY") {
      setStartDate(moment(info.startDate).toDate());
    }
    setWorkTitle(info.title);
    setCompanyName(info.companyName);
    setTypeOfBusiness(info.typeOfBusiness);
    setWorkFunc(info.function);
  }, []);
  useEffect(() => {
    if (
      !(
        workTitle === info.title &&
        companyName === info.companyName &&
        typeOfBusiness === info.typeOfBusiness &&
        workFunc === info.function &&
        startDate === info.startDate &&
        endDate === info.endDate
      )
    ) {
      props.setInfo({
        title: workTitle,
        companyName: companyName,
        typeOfBusiness: typeOfBusiness ? typeOfBusiness.toString() : undefined,
        function: workFunc,
        startDate: startDate
          ? moment(startDate).format("MM/DD/YYYY")
          : undefined,
        endDate: endDate ? moment(endDate).format("MM/DD/YYYY") : undefined,
      });
    }
  }, [workFunc, workTitle, companyName, typeOfBusiness, startDate, endDate]);
  return (
    <LocalizationProvider dateAdapter={DateFnsAdapter}>
      <Container maxWidth="sm">
        <Grid
          container
          spacing={1}
          style={{
            marginBottom: "10px",
          }}
        >
          <Grid item sm={6} xs={6} md={5} lg={5}>
            <MobileDatePicker
              views={["year", "month", "date"]}
              label="From Date *"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              renderInput={(props) => (
                <TextField variant="outlined" fullWidth {...props} />
              )}
            />
          </Grid>
          <Grid item sm={5} xs={5} md={5} lg={5}>
            <MobileDatePicker
              views={["year", "month", "date"]}
              clearable
              label="End Date"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              renderInput={(props) => (
                <TextField variant="outlined" fullWidth {...props} />
              )}
            />
          </Grid>
          <Grid
            item
            sm={1}
            xs={1}
            md={2}
            lg={2}
            style={{
              marginTop: "15px",
            }}
          >
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleRemove();
              }}
            >
              Remove
            </a>
          </Grid>
        </Grid>

        <FormControl
          variant="outlined"
          fullWidth
          style={{ marginBottom: "10px" }}
        >
          <InputLabel htmlFor="outlined-type-of-business">
            Type of business
          </InputLabel>
          {loginType === "internal" && (
          <Select
            fullWidth
            value={typeOfBusiness}
            onChange={(e) => {
              setTypeOfBusiness(e.target.value);
            }}
            label={"Business Type"}
            inputProps={{
              name: "type-of-business",
              id: "outlined-type-of-business",
            }}
          >
            <MenuItem value="" selected="">No Selection</MenuItem>
            <MenuItem value="1265">Advertising/Marketing</MenuItem>
            <MenuItem value="1264">Agriculture and Forestry</MenuItem>
            <MenuItem value="1233">Architectural Services</MenuItem>
            <MenuItem value="1232">Arts</MenuItem><MenuItem value="1235">Banking</MenuItem>
            <MenuItem value="1234">Biotechnology and Pharmaceutical</MenuItem>
            <MenuItem value="1237">Building Materials</MenuItem>
            <MenuItem value="1236">Communications</MenuItem>
            <MenuItem value="1239">Construction</MenuItem>
            <MenuItem value="1238">Consulting Services</MenuItem>
            <MenuItem value="1241">Distribution</MenuItem>
            <MenuItem value="1240">Educational Services</MenuItem>
            <MenuItem value="1243">Engineering</MenuItem><MenuItem value="1242">Environmental</MenuItem>
            <MenuItem value="1245">Financial &amp; Insurance</MenuItem>
            <MenuItem value="1244">Food Services</MenuItem>
            <MenuItem value="1247">Health Care and Social Assistance</MenuItem>
            <MenuItem value="1246">Hospitality/Tourism</MenuItem>
            <MenuItem value="1248">Information Technology</MenuItem>
            <MenuItem value="1249">Legal Services</MenuItem>
            <MenuItem value="1250">Manufacturing</MenuItem>
            <MenuItem value="1251">Military</MenuItem>
            <MenuItem value="1252">Oil</MenuItem>
            <MenuItem value="1253">Other</MenuItem>
            <MenuItem value="1254">Public Relations</MenuItem>
            <MenuItem value="1255">Publishing/Printing</MenuItem>
            <MenuItem value="1256">Real Estate</MenuItem>
            <MenuItem value="1257">Retail</MenuItem>
            <MenuItem value="1258">State</MenuItem>
            <MenuItem value="1259">Telecommunications</MenuItem>
            <MenuItem value="1260">Transportation</MenuItem>
            <MenuItem value="1261">Utility - Electric</MenuItem>
            <MenuItem value="1262">Utility - Other</MenuItem>
            <MenuItem value="1263">Wholesale</MenuItem>    
          </Select>
          )}
          {loginType === "external" && (
          <Select
            fullWidth
            value={typeOfBusiness}
            onChange={(e) => {
              setTypeOfBusiness(e.target.value);
            }}
            label={"Business Type"}
            inputProps={{
              name: "type-of-business",
              id: "outlined-type-of-business",
            }}
          >
            <MenuItem value="" selected="">No Selection</MenuItem>
            <MenuItem value="Advertising/Marketing">Advertising/Marketing</MenuItem>
            <MenuItem value="Agriculture and Forestry">Agriculture and Forestry</MenuItem>
            <MenuItem value="Architectural Services">Architectural Services</MenuItem>
            <MenuItem value="Arts">Arts</MenuItem>
            <MenuItem value="Banking">Banking</MenuItem>
            <MenuItem value="Biotechnology and Pharmaceutical">Biotechnology and Pharmaceutical</MenuItem>
            <MenuItem value="Building Materials">Building Materials</MenuItem>
            <MenuItem value="Communications">Communications</MenuItem>
            <MenuItem value="Construction">Construction</MenuItem>
            <MenuItem value="Consulting Services">Consulting Services</MenuItem>
            <MenuItem value="Distribution">Distribution</MenuItem>
            <MenuItem value="Educational Services">Educational Services</MenuItem>
            <MenuItem value="Engineering">Engineering</MenuItem>
            <MenuItem value="Environmental">Environmental</MenuItem>
            <MenuItem value="Financial &amp; Insurance">Financial &amp; Insurance</MenuItem>
            <MenuItem value="Food Services">Food Services</MenuItem>
            <MenuItem value="Health Care and Social Assistance">Health Care and Social Assistance</MenuItem>
            <MenuItem value="Hospitality/Tourism">Hospitality/Tourism</MenuItem>
            <MenuItem value="Information Technology">Information Technology</MenuItem>
            <MenuItem value="Legal Services">Legal Services</MenuItem>
            <MenuItem value="Manufacturing">Manufacturing</MenuItem>
            <MenuItem value="Military">Military</MenuItem>
            <MenuItem value="Oil">Oil</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
            <MenuItem value="Public Relations">Public Relations</MenuItem>
            <MenuItem value="Publishing/Printing">Publishing/Printing</MenuItem>
            <MenuItem value="Real Estate">Real Estate</MenuItem>
            <MenuItem value="Retail">Retail</MenuItem>
            <MenuItem value="State">State</MenuItem>
            <MenuItem value="Telecommunications">Telecommunications</MenuItem>
            <MenuItem value="Transportation">Transportation</MenuItem>
            <MenuItem value="Utility - Electric">Utility - Electric</MenuItem>
            <MenuItem value="Utility - Other">Utility - Other</MenuItem>
            <MenuItem value="Wholesale">Wholesale</MenuItem>    
          </Select>
          )}
        </FormControl>

        <TextField
          required
          label="Title"
          fullWidth
          variant="outlined"
          value={workTitle}
          style={{ marginBottom: "10px" }}
          onChange={(e) => {
            setWorkTitle(e.target.value);
          }}
        />
        <TextField
          required
          label="Company Name"
          fullWidth
          variant="outlined"
          value={companyName}
          style={{ marginBottom: "10px" }}
          onChange={(e) => {
            setCompanyName(e.target.value);
          }}
        />
        <FormControl
          variant="outlined"
          fullWidth
          style={{ marginBottom: "10px" }}
        >
          <InputLabel htmlFor="outlined-work-func">Function</InputLabel>
          {loginType === "internal" && (
            <Select
              fullWidth
              value={workFunc}
              onChange={(e) => {
                setWorkFunc(e.target.value);
              }}
              label={"Function"}
              inputProps={{
                name: "work-func",
                id: "outlined-work-func",
              }}
            >
              <MenuItem value="" selected="">No Selection</MenuItem>
              <MenuItem value="2625">Brand</MenuItem>
              <MenuItem value="695">Business Support</MenuItem>
              <MenuItem value="696">Communication (PA &amp; PR)</MenuItem>
              <MenuItem value="697">Finance &amp; Accounting</MenuItem>
              <MenuItem value="698">Format</MenuItem><MenuItem value="699">General Management</MenuItem>
              <MenuItem value="700">Human Resources</MenuItem><MenuItem value="701">Information Technology</MenuItem>
              <MenuItem value="702">Internal Audit</MenuItem>
              <MenuItem value="703">Legal</MenuItem>
              <MenuItem value="704">Marketing</MenuItem>
              <MenuItem value="705">Merchandising</MenuItem>
              <MenuItem value="706">Other</MenuItem>
              <MenuItem value="707">Production-Manufacturing</MenuItem>
              <MenuItem value="708">Quality Assurance- Health &amp; Safety</MenuItem>
              <MenuItem value="709">RE, Property Mgmt &amp; Constr.</MenuItem>
              <MenuItem value="710">Replenishment</MenuItem>
              <MenuItem value="711">Security &amp; Loss Prevention</MenuItem>
              <MenuItem value="712">Sourcing</MenuItem>
              <MenuItem value="713">Store Operations</MenuItem>
              <MenuItem value="714">Supply Chain</MenuItem>
              <MenuItem value="715">Wholesale</MenuItem>
            </Select>
          )}
          {loginType === "external" && (
              <Select
              fullWidth
              value={workFunc}
              onChange={(e) => {
                setWorkFunc(e.target.value);
              }}
              label={"Function"}
              inputProps={{
                name: "work-func",
                id: "outlined-work-func",
              }}
            >
              <MenuItem value="" selected="">No Selection</MenuItem>
              <MenuItem value="Brand">Brand</MenuItem>
              <MenuItem value="Business Support">Business Support</MenuItem>
              <MenuItem value="Communication (PA &amp; PR)">Communication (PA &amp; PR)</MenuItem>
              <MenuItem value="Finance &amp; Accounting">Finance &amp; Accounting</MenuItem>
              <MenuItem value="Format">Format</MenuItem>
              <MenuItem value="General Management">General Management</MenuItem>
              <MenuItem value="Human Resources">Human Resources</MenuItem>
              <MenuItem value="Information Technology">Information Technology</MenuItem>
              <MenuItem value="Internal Audit">Internal Audit</MenuItem>
              <MenuItem value="Legal">Legal</MenuItem>
              <MenuItem value="Marketing">Marketing</MenuItem>
              <MenuItem value="Merchandising">Merchandising</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
              <MenuItem value="Production-Manufacturing">Production-Manufacturing</MenuItem>
              <MenuItem value="Quality Assurance- Health &amp; Safety">Quality Assurance- Health &amp; Safety</MenuItem>
              <MenuItem value="RE, Property Mgmt &amp; Constr.">RE, Property Mgmt &amp; Constr.</MenuItem>
              <MenuItem value="Replenishment">Replenishment</MenuItem>
              <MenuItem value="Security &amp; Loss Prevention">Security &amp; Loss Prevention</MenuItem>
              <MenuItem value="Sourcing">Sourcing</MenuItem>
              <MenuItem value="Store Operations">Store Operations</MenuItem>
              <MenuItem value="Supply Chain">Supply Chain</MenuItem>
              <MenuItem value="Wholesale">Wholesale</MenuItem>
            </Select>
          )}
        </FormControl>
      </Container>
    </LocalizationProvider>
  );
};

export default WorkInfo;
