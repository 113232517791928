import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import FormHelperText from "@material-ui/core/FormHelperText";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Typography from "@material-ui/core/Typography";
import AppLoading from "../../../common/components/loading";
import { getUrlParameterByName } from "../../../common/util/string_utility";
import { selectAuth, userLogin } from "../states/auth_slice";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

const LoginPage = (props) => {
  const { history } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [userName, setUserName] = useState();
  const [userPassword, setUserPassword] = useState();
  const [userNameError, setUserNameError] = useState(undefined);
  const [userPasswordError, setUserPasswordError] = useState(undefined);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const authState = useSelector(selectAuth);
  const loginType =
    window.location.href.indexOf("internal") >= 0 ? "internal" : "external";
  const dispatch = useDispatch();
  const jobId = getUrlParameterByName("jobId");
  const company = getUrlParameterByName("company", true);

  const verifyInput = () => {
    setUserNameError(undefined);
    setUserPasswordError(undefined);

    if (!userName || userName.trim() === "") {
      setUserNameError("User name can not be empty!");
      return;
    }
    if (!userPassword || userPassword.trim() === "") {
      setUserPasswordError("Password can not be empty!");
      return;
    } /*else {
      if (userPassword.trim().length < 8) {
        setUserPasswordError("Password length must be greater than 8");
        return;
      }
    }*/
    const body = {
      username: userName.trim(),
      password: userPassword.trim(),
    };
    //dispatch(getUserSessionId());
    if (loginType === "external") {
      dispatch(userLogin(body));
    } else {
      sessionStorage.setItem("appvault-login", true);
      props.history.push(
        "/internal/profile?jobId=" + jobId + "&company=" + encodeURIComponent(company)
      );
    }
  };

const isWave2 = () => {
    if (company) {
      switch (company.toLowerCase()) {
        case "ahold e-commerce sales company":
          case "ahold+e-commerce+sales+company":
        case "peapod digital labs holdings llc":
        case "peapod digital labs llc":
        case "ahold delhaize corp support llc":
          case "ahold+delhaize+corp+support+llc":
        case "giant delivery llc":
          return false;
        case "adusa distribution, llc":
        case "adusa transportation, llc":
          case "adusa+transportation,+llc":
        case "adusa procurement, llc":
          case "adusa+procurement,+llc":
        case "adusa supply chain services, llc":
        case "adusa supply chain, llc":
        case "food lion, llc":
          case "food+lion,+llc":
        case "hannaford bros. co., llc":
        case "martin's foods of south burlington, llc":
        case "victory distributors, llc":
        case "ahold delhaize usa, inc.":
        case "ahold delhaize usa services, llc":
        case "ahold delhaize usa":
        case "guiding star licensing company":
        case "retail business services llc" :
          return true;
        default:
          return false;
      }
    }
    return false;
  };

  useEffect(() => {
    if (authState.success === true) {
      props.history.push(
        "/external/profile?jobId=" + jobId + "&company=" + encodeURIComponent(company)
      );
    }
    if (authState.success === false) {
      if (
        authState.errorMessage.indexOf("already applied for this position.") >=
        0
      ) {
        setOpenErrorDialog(true);
      } else {
        setUserPasswordError(authState.errorMessage);
      }
    }
  }, [authState.success]);

  if (authState && authState.onLoading === true) {
    return (
      <Container
        maxWidth="sm"
        style={{ minHeight: "100vh", marginTop: "20vh", textAlign: "center" }}
      >
        <AppLoading />
      </Container>
    );
  }
  return (
    <Container maxWidth="sm" style={{ minHeight: "100vh", marginTop: "5vh" }}>
      <Dialog fullWidth={true} maxWidth={"sm"} open={openErrorDialog}>
        <DialogContent>
          <DialogContentText>
            <strong>{authState.errorMessage}</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            color="secondary"
            variant="contained"
            onClick={(e) => {
              sessionStorage.removeItem("ma-session");
              if (loginType === "external") {
                window.location.replace("/external/home?company=" + encodeURIComponent(company));
              } else {
                window.location.replace("/internal/home?company=" + encodeURIComponent(company));
              }
            }}
          >
            Go to job list
          </Button>
        </DialogActions>
      </Dialog>
      {isWave2() === true && (<Typography>
        <p>Thank you for visiting our new careers site, which we just launched!  We hope you’ll find a role that fits your interests and skills.</p>
        <p>If you’re a returning to our site, welcome back!  This new portal requires a new account with an updated username and password when you apply.</p>
        <p>Thanks for your interest.  We look forward to connecting with you!</p>
      </Typography>)}
      <TextField
        required
        label="Email Address"
        fullWidth
        variant="outlined"
        error={userNameError !== undefined}
        value={userName}
        onChange={(e) => {
          setUserName(e.target.value);
        }}
        helperText={userNameError}
      />
      <FormControl fullWidth variant="outlined" style={{ marginTop: "20px" }}>
        <InputLabel
          htmlFor="outlined-adornment-password"
          error={userPasswordError !== undefined}
        >
          Password *
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          type={showPassword ? "text" : "password"}
          value={userPassword}
          error={userPasswordError !== undefined}
          onChange={(e) => {
            setUserPassword(e.target.value);
          }}
          helperText={userPasswordError}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
                onMouseDown={(e) => {
                  e.preventDefault();
                }}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          labelWidth={85}
        />
        {userPasswordError && (
          <FormHelperText id="outlined-adornment-password" error>
            {userPasswordError}
          </FormHelperText>
        )}
      </FormControl>
      <Grid
        container
        spacing={1}
        style={{
          textAlign: "center",
          marginTop: "50px",
          alignItems: "baseline",
        }}
      >
        <Grid item sm={3} xs={2} md={3} lg={4}></Grid>
        <Grid item sm={6} xs={8} md={6} lg={4}>
          <Button
            color="secondary"
            variant="contained"
            onClick={(e) => {
              verifyInput();
            }}
            fullWidth
          >
            Sign In{" "}
          </Button>
          {loginType === "external" && (
            <div style={{ margin: "20px 0" }}>--------or---------</div>
          )}
        </Grid>
        <Grid item sm={3} xs={2} md={3} lg={4}></Grid>
      </Grid>
      {loginType === "external" && (
        <Grid
          container
          spacing={1}
          style={{
            textAlign: "center",
            alignItems: "baseline",
          }}
        >
          <Grid item sm={12} xs={12} md={12} lg={12}>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                let c = encodeURIComponent(company);
                history.push(
                  `/${loginType}/forgot_password?jobId=${jobId}&company=${c}`
                );
              }}
            >
              Forgot Your Password?
            </a>
            <a
              href="#"
              style={{ marginLeft: "20px" }}
              onClick={(e) => {
                e.preventDefault();
                let c = encodeURIComponent(company);
                history.push(
                  `/${loginType}/create_account?jobId=${jobId}&company=${c}`
                );
              }}
            >
              Create an Account
            </a>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default withRouter(LoginPage);
