import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";
import { MobileDatePicker, LocalizationProvider } from "@material-ui/pickers";
const InternalWorkInfo = (props) => {
  const { handleRemove, info } = props;

  const [workTitle, setWorkTitle] = useState();
  const [brand, setBrand] = useState(info ? info.brand : undefined);
  const [workFunc, setWorkFunc] = useState(info ? info.function : undefined);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  useEffect(() => {
    if (!isEmpty(info.endDate) && info.endDate !== "MM/DD/YYYY") {
      setEndDate(moment(info.endDate).toDate());
    }
    if (!isEmpty(info.startDate) && info.startDate !== "MM/DD/YYYY") {
      setStartDate(moment(info.startDate).toDate());
    }
    setWorkTitle(info.title);
    setWorkFunc(info.function);
    setBrand(info.brand);
  }, []);
  useEffect(() => {
    if (
      !(
        workTitle === info.title &&
        workFunc === info.function &&
        startDate === info.startDate &&
        endDate === info.endDate &&
        brand === info.brand
      )
    ) {
      props.setInfo({
        title: workTitle,
        function: workFunc,
        startDate: startDate
          ? moment(startDate).format("MM/DD/YYYY")
          : undefined,
        endDate: endDate ? moment(endDate).format("MM/DD/YYYY") : undefined,
        brand: brand
      });
    }
  }, [workFunc, workTitle, startDate, endDate, brand]);
  return (
    <LocalizationProvider dateAdapter={DateFnsAdapter}>
      <Container maxWidth="sm">
        <Grid
          container
          spacing={1}
          style={{
            marginBottom: "10px",
          }}
        >
          <Grid item sm={6} xs={6} md={5} lg={5}>
            <MobileDatePicker
              views={["year", "month", "date"]}
              label="From Date *"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              renderInput={(props) => (
                <TextField variant="outlined" fullWidth {...props} />
              )}
            />
          </Grid>
          <Grid item sm={5} xs={5} md={5} lg={5}>
            <MobileDatePicker
              views={["year", "month", "date"]}
              clearable
              label="End Date"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              renderInput={(props) => (
                <TextField variant="outlined" fullWidth {...props} />
              )}
            />
          </Grid>
          <Grid
            item
            sm={1}
            xs={1}
            md={2}
            lg={2}
            style={{
              marginTop: "15px",
            }}
          >
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleRemove();
              }}
            >
              Remove
            </a>
          </Grid>
        </Grid>

        <TextField
          required
          label="Title"
          fullWidth
          variant="outlined"
          value={workTitle}
          style={{ marginBottom: "10px" }}
          onChange={(e) => {
            setWorkTitle(e.target.value);
          }}
        />
        <FormControl
          variant="outlined"
          fullWidth
          style={{ marginBottom: "10px" }}
        >
          <InputLabel htmlFor="internal-outlined-brand">Brand</InputLabel>
          <Select
            fullWidth
            value={brand}
            onChange={(e) => {
              setBrand(e.target.value);
            }}
            label={"Brand"}
            inputProps={{
              name: "internal-brand",
              id: "internal-outlined-brand",
            }}
          >
            <MenuItem value="" selected="">No Selection</MenuItem>
            <MenuItem value="1347">Ahold Delhaize USA</MenuItem>
            <MenuItem value="1346">AH to go</MenuItem>
            <MenuItem value="1348">Albert</MenuItem>
            <MenuItem value="1349">Albert Heijn</MenuItem>
            <MenuItem value="1350">Alfa Beta</MenuItem>
            <MenuItem value="1351">bfresh</MenuItem>
            <MenuItem value="1352">bol.com</MenuItem>
            <MenuItem value="1353">Delhaize le Lion</MenuItem>
            <MenuItem value="1354">Delhaize Serbia</MenuItem>
            <MenuItem value="1355">ENA</MenuItem>
            <MenuItem value="1356">Etos</MenuItem>
            <MenuItem value="1357">Food Lion</MenuItem>
            <MenuItem value="1358">Gall &amp; Gall</MenuItem>
            <MenuItem value="1359">Giant Food</MenuItem>
            <MenuItem value="1360">Giant Martins</MenuItem>
            <MenuItem value="1361">GSO</MenuItem>
            <MenuItem value="1362">Hannaford</MenuItem>
            <MenuItem value="1363">Maxi</MenuItem>
            <MenuItem value="1364">Mega Image</MenuItem>
            <MenuItem value="1365">Peapod</MenuItem>
            <MenuItem value="1366">Peapod Digital Labs</MenuItem>
            <MenuItem value="1367">Retail Business Services</MenuItem>
            <MenuItem value="1368">Stop &amp; Shop</MenuItem>
          </Select>
        </FormControl>
        <FormControl
          variant="outlined"
          fullWidth
          style={{ marginBottom: "10px" }}
        >
          <InputLabel htmlFor="internal-outlined-work-func">Function</InputLabel>
          <Select
            fullWidth
            value={workFunc}
            onChange={(e) => {
              setWorkFunc(e.target.value);
            }}
            label={"Function"}
            inputProps={{
              name: "internal-work-func",
              id: "internal-outlined-work-func",
            }}
          >
            <MenuItem value="" selected="">
              No Selection
            </MenuItem>
            <MenuItem value="74117">Brand</MenuItem>
            <MenuItem value="74115">Business Support</MenuItem>
            <MenuItem value="74103">Communication (PA&amp;PR)</MenuItem>
            <MenuItem value="74104">Finance &amp; Accounting</MenuItem>
            <MenuItem value="74118">Format</MenuItem>
            <MenuItem value="74116">General Management</MenuItem>
            <MenuItem value="74105">Human Resources</MenuItem>
            <MenuItem value="74109">Information Technology</MenuItem>
            <MenuItem value="74123">Internal Audit</MenuItem>
            <MenuItem value="74113">Legal</MenuItem>
            <MenuItem value="74110">Marketing</MenuItem>
            <MenuItem value="74108">Merchandising</MenuItem>
            <MenuItem value="74114">Other</MenuItem>
            <MenuItem value="74106">Production - Manufacturing</MenuItem>
            <MenuItem value="74111">Quality Assurance - Health &amp; Safety</MenuItem>
            <MenuItem value="74122">Real Estate &amp; Property Mgt</MenuItem>
            <MenuItem value="74112">Replenishment</MenuItem>
            <MenuItem value="74107">Security &amp; Loss Prevention</MenuItem>
            <MenuItem value="74124">Sourcing</MenuItem>
            <MenuItem value="74119">Store Operations</MenuItem>
            <MenuItem value="74120">Supply Chain</MenuItem>
            <MenuItem value="74121">Wholesale</MenuItem>
          </Select>
        </FormControl>
      </Container>
    </LocalizationProvider>
  );
};

export default InternalWorkInfo;
