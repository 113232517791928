import { useDispatch, useSelector } from "react-redux";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import {
  selectAuth,
  getUserSession,
} from "../../features/auth/states/auth_slice";
import AppLoading from "../../common/components/loading";
import { getUrlParameterByName } from "../../common/util/string_utility";

import { makeStyles } from "@material-ui/core/styles";

const ExternalHome = (props) => {
  const auth = useSelector(selectAuth);
  const company = getUrlParameterByName("company", true);
  const jobId = getUrlParameterByName("jobId");

  const dispatch = useDispatch();
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      overflow: "hidden",
      padding: theme.spacing(0, 3),
    },
    paper: {
      margin: `${theme.spacing(1)}px auto`,
      padding: theme.spacing(2),
    },
  }));
  const classes = useStyles();
  if (auth.onLoadingSession !== true && auth.loadingSessionSuccess === undefined) {
    sessionStorage.removeItem("ma-session");
    dispatch(getUserSession());
  }
  if (auth.onLoadingSession === true) {
    return (
      <Container
        maxWidth="sm"
        style={{ marginTop: "20vh", textAlign: "center" }}
      >
        <AppLoading />
      </Container>
    );
  }
  if (jobId && auth.loadingSessionSuccess === true && sessionStorage.getItem("ma-session") && sessionStorage.getItem("ma-session") !== '') {
    if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1){
      setTimeout(function(){ 
        window.location.href = '/external?jobId=' + jobId + "&company=" + encodeURIComponent(company); 
      }, 500);
    } else {
      window.location.href = '/external?jobId=' + jobId + "&company=" + encodeURIComponent(company);
    }
  }
  if (jobId) {
    return (
      <Container
        maxWidth="sm"
        style={{ marginTop: "20vh", textAlign: "center" }}
      >
        <AppLoading />
      </Container>
    );
  }
  if (!jobId) {
    switch (company.toLowerCase()) {
      case "ahold e-commerce sales company":
        case "ahold+e-commerce+sales+company":
        window.location.href = 'https://aholdecommercesales.careerswithus.com/'
        break;
      case "peapod digital labs llc":
        window.location.href = 'https://peapoddigitallabs.careerswithus.com/'
        break;
      case "ahold delhaize corp support llc":
        case "ahold+delhaize+corp+support+llc":
        window.location.href = 'https://aholddelhaizeusacorporatesupport.careerswithus.com/'
        break;
      case "giant delivery llc":
        case "giant+delivery+llc":
        window.location.href = 'https://giantdelivers.careerswithus.com/'
        break;
        case "adusa+distribution,+llc":
        case "adusa distribution, llc":
          window.location.href = 'https://ADUSADistribution.careerswithus.com/'
          break;
        case "adusa transportation, llc":
          case "adusa+transportation,+llc":
          window.location.href = 'https://ADUSATransportation.careerswithus.com/'
          break;
        case "adusa procurement, llc":
          case "adusa+procurement,+llc":
          window.location.href = 'https://ADUSAProcurement.careerswithus.com/'
          break;
        case "adusa supply chain services, llc":
        case "adusa supply chain, llc":
          window.location.href = 'https://ADUSASupplyChainServices.careerswithus.com/'
          break;
        case "food lion, llc":
          case "food+lion,+llc":
          window.location.href = 'https://FoodLion.careerswithus.com/'
          break;
          case "hannaford bros. co., llc":
            case "martin's foods of south burlington, llc":
            case "victory distributors, llc":
            case "hannaford+bros.+co.,+llc":
            case "martin's+foods+of+south+burlington,+llc":
            case "victory+distributors,+llc":
          window.location.href = 'https://Hannaford.careerswithus.com/'
          break;
        case "ahold delhaize usa":
        case "ahold delhaize usa, inc.":
        case "guiding star licensing company":
        case "ahold delhaize usa services, llc":
          case "ahold+delhaize+usa":
          case "ahold+delhaize+usa,+inc.":
          case "guiding+star+licensing+company":
          case "ahold+delhaize+usa+services,+llc":
          window.location.href = 'https://AholdDelhaizeUSA.careerswithus.com/'
          break;
        case "retail business services llc" :
          window.location.href = 'https://RetailBusinessServices.careerswithus.com/'
          break;
          case "the+stop+and+shop+supermarket+company+llc":
        case "the stop and shop supermarket company llc":
          window.location.href = 'https://stopnshop.careerswithus.com/'
          break;
          case "the+giant+company+llc":
        case "the giant company llc":
          window.location.href = 'https://giantcompany.careerswithus.com/'
          break;
        case "giant of maryland llc":
          case "giant+of+maryland+llc":
          window.location.href = 'https://giantfood.careerswithus.com/'
          break;
        default:
          window.location.href = 'https://giantdelivers.careerswithus.com/'
          break;
    }
    return (
      <Container maxWidth="sm" style={{ minHeight: "100vh", marginTop: "20px" }}>
        {company.toLowerCase() === "addev" && (
          <Paper className={classes.paper}>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item>
                <Avatar>Job</Avatar>
              </Grid>
              <Grid item xs="7" md="8">
                <Typography>
                  Truck Driver DC09 (2061)
                  <br />
                  <strong>Ahold Delhaize</strong>
                </Typography>
              </Grid>
              <Grid item xs="1">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = "/external?jobId=2061&company=ADDEV";
                  }}
                >
                  Apply
                </Button>
              </Grid>
            </Grid>
          </Paper>
        )}
        {company.toLowerCase() === "aedev" && (
          <Paper className={classes.paper}>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item>
                <Avatar>Job</Avatar>
              </Grid>
              <Grid item xs="7" md="8">
                <Typography>
                  Truck Driver DC09 (2061)
                  <br />
                  <strong>Ahold eCommerce Sales Company</strong>
                </Typography>
              </Grid>
              <Grid item xs="1">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = "/external?jobId=2061&company=AEDEV";
                  }}
                >
                  Apply
                </Button>
              </Grid>
            </Grid>
          </Paper>
        )}
        {company.toLowerCase() === "pddev" && (
          <Paper className={classes.paper}>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item>
                <Avatar>Job</Avatar>
              </Grid>
              <Grid item xs="7" md="8">
                <Typography>
                  Truck Driver DC09 (2061)
                  <br />
                  <strong>Peapod Digital</strong>
                </Typography>
              </Grid>
              <Grid item xs="1">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = "/external?jobId=2061&company=PDDEV";
                  }}
                >
                  Apply
                </Button>
              </Grid>
            </Grid>
          </Paper>
        )}
        {company.toLowerCase() === "gddev" && (
          <Paper className={classes.paper}>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item>
                <Avatar>Job</Avatar>
              </Grid>
              <Grid item xs="7" md="8">
                <Typography>
                  Truck Driver DC09 (2061)
                  <br />
                  <strong>Giant Delivers</strong>
                </Typography>
              </Grid>
              <Grid item xs="1">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = "/external?jobId=2061&company=GDDEV";
                  }}
                >
                  Apply
                </Button>
              </Grid>
            </Grid>
          </Paper>
        )}
        {company.toLowerCase() === "addev" && (
          <Paper className={classes.paper}>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item>
                <Avatar>Job</Avatar>
              </Grid>
              <Grid item xs="7" md="8">
                <Typography>
                  VP Human Resources (2101) <br />
                  <strong>Ahold Delhaize</strong>
                </Typography>
              </Grid>
              <Grid item xs="1">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = "/external?jobId=2101&company=ADDEV";
                  }}
                >
                  Apply
                </Button>
              </Grid>
            </Grid>
          </Paper>
        )}
        {company.toLowerCase() === "aedev" && (
          <Paper className={classes.paper}>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item>
                <Avatar>Job</Avatar>
              </Grid>
              <Grid item xs="7" md="8">
                <Typography>
                  VP Human Resources (2101) <br />
                  <strong>Ahold eCommerce Sales Company</strong>
                </Typography>
              </Grid>
              <Grid item xs="1">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = "/external?jobId=2101&company=AEDEV";
                  }}
                >
                  Apply
                </Button>
              </Grid>
            </Grid>
          </Paper>
        )}
        {company.toLowerCase() === "pddev" && (
          <Paper className={classes.paper}>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item>
                <Avatar>Job</Avatar>
              </Grid>
              <Grid item xs="7" md="8">
                <Typography>
                  VP Human Resources (2101) <br />
                  <strong>Peapod Digital</strong>
                </Typography>
              </Grid>
              <Grid item xs="1">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = "/external?jobId=2101&company=PDDEV";
                  }}
                >
                  Apply
                </Button>
              </Grid>
            </Grid>
          </Paper>
        )}
        {company.toLowerCase() === "gddev" && (
          <Paper className={classes.paper}>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item>
                <Avatar>Job</Avatar>
              </Grid>
              <Grid item xs="7" md="8">
                <Typography>
                  VP Human Resources (2101) <br />
                  <strong>Giant Delivers</strong>
                </Typography>
              </Grid>
              <Grid item xs="1">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = "/external?jobId=2101&company=GDDEV";
                  }}
                >
                  Apply
                </Button>
              </Grid>
            </Grid>
          </Paper>
        )}
      </Container>
    );
  }
};

export default withRouter(ExternalHome);
