import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ReactToPrint from "react-to-print";

export default function TermDialog(props) {
  const [open, setOpen] = React.useState(false);
  const printEl = React.useRef(null);

  const handleClose = (agree) => {
    if (props.setAgreeTerms) {
      props.setAgreeTerms(agree);
    }
    setOpen(false);
  };

  React.useEffect(() => {
    setOpen(props.open);
  }, [props]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title">{"Term of Use Policy"}</DialogTitle>
      <DialogContent divider>
        <DialogContentText id="alert-dialog-description" ref={printEl}>
          <p>
            <strong>Data Privacy Consent Statement</strong>
          </p>
          <h3>Careers Page Privacy Statement</h3>
          <p>
            <strong>Effective Date:</strong> August 26, 2019
          </p>
          <p>
            The brands of Ahold Delhaize USA ("ADUSA") respects your concerns
            about privacy. This Privacy Statement describes the types of
            personal information we collect on Ahold Delhaize USA Career site,
            how we use the information, with whom we share it and the choices
            available to you regarding our use of the information. We also
            describe measures we take to protect the security of the information
            and how you can contact us about our privacy practices.
          </p>
          <p>
            This Privacy Statement applies to the information we collect on the
            Site and the App. It does not apply to other websites, applications
            or services provided by The brands of Ahold Delhaize USA or other
            affiliated entities. In addition, this Privacy Statement does not
            apply to the offline data collection and use practices of The brands
            of Ahold Delhaize USA or other affiliated entities, such as data
            collection by telephone or in a retail store.
          </p>
          <p>
            <strong>Information You Provide to Us</strong>
          </p>
          <p>
            The types of personal information you may provide to us when you
            visit our Site or use our App may include:
          </p>
          <ul>
            <li>
              <p>
                Contact information (such as name, telephone number, postal
                address and email address);
              </p>
            </li>
            <li>
              <p>
                Login credentials (such as username and password) to access your
                profile;
              </p>
            </li>
            <li>
              <p>Date of birth;</p>
            </li>
            <li>
              <p>Citizenship and work authorization status;</p>
            </li>
            <li>
              <p>Prior addresses and length of time at each address;</p>
            </li>
            <li>
              <p>Previous work experience;</p>
            </li>
            <li>
              <p>Resume or CV;</p>
            </li>
            <li>
              <p>Education history; and</p>
            </li>
            <li>
              <p>
                Other information you choose to provide in your application.
              </p>
            </li>
          </ul>
          <p>
            If you apply for a position with the brands of Ahold Delhaize USA,
            you may provide information on your gender, race, ethnicity, veteran
            status and disability status on a voluntary basis. The brands of
            Ahold Delhaize USA will not consider either this information or your
            choice to decline to provide it in evaluating your qualifications
            for employment with us. We may use this information to the extent
            necessary or appropriate for compliance with relevant legal or
            contractual obligations and for other internal purposes.
          </p>
          <p>
            <strong>Automated Collection of Information</strong>
          </p>
          <p>
            When you visit our Site, we may collect certain information by
            automated means, such as cookies and other technologies. The
            information we collect in this manner may include your device IP
            address, unique device identifier, web browser characteristics,
            device characteristics, operating system, language preferences,
            referring URLs, clickstream data, and dates and times of website
            visits.
          </p>
          <p>
            When you use our App, we also may collect certain information by
            automated means, such as through device logs, server logs and other
            technologies. The information we collect in this manner may include
            the device type used, the mobile operating system, device
            identifiers and similar unique identifiers, device settings and
            configurations, IP addresses, battery and signal strength, usage
            statistics, referring emails and web addresses, dates and times of
            usage, actions taken on the App, and other information regarding use
            of the App.
          </p>
          <p>
            <em>Cookies and Web Beacons</em>
          </p>
          <p>
            Cookies are files that websites send to your computer to uniquely
            identify your browser or to store information or settings in the
            browser. A web beacon (also known as an Internet tag, pixel tag or
            clear GIF) links web pages to web servers and their cookies and may
            be used to transmit information collected through cookies back to a
            web server. Your browser may tell you how to be notified when you
            receive certain types of cookies or how to restrict or disable
            certain types of cookies. Please note, however, that without cookies
            you may not be able to use all of the features of the Site.
          </p>
          <p>
            Our Site is not designed to respond to “do not track” signals
            received from browsers.
          </p>
          <p>
            <strong>How We Use the Information We Obtain</strong>
          </p>
          <p>We may use the personal information we obtain about you to:</p>
          <ul>
            <li>
              <p>· Evaluate you for available positions;</p>
            </li>
            <li>
              <p>
                · Respond to and communicate with you about your applications,
                requests, questions and comments;
              </p>
            </li>
            <li>
              <p>· Establish and manage your profile;</p>
            </li>
            <li>
              <p>· Contact you regarding possible employment;</p>
            </li>
            <li>
              <p>· Customize your visit to the Site and App;</p>
            </li>
            <li>
              <p>
                · Provide you with information regarding open positions similar
                to those for which you applied;
              </p>
            </li>
            <li>
              <p>· Carry out our human resources functions and activities;</p>
            </li>
            <li>
              <p>
                · Protect against and prevent fraud, unauthorized transactions,
                claims and other liabilities;
              </p>
            </li>
            <li>
              <p>
                · Operate, evaluate and improve our business (including managing
                the Site and App; developing new products and services;
                analyzing, enhancing and improving our products and services;
                managing our communications; performing data analytics; and
                performing accounting, auditing and other internal functions);
                and
              </p>
            </li>
            <li>
              <p>
                · Comply with and enforce applicable legal requirements,
                industry standards and company policies and terms.
              </p>
            </li>
          </ul>
          <p>
            We also may use the information in other ways for which we provide
            specific notice at the time of collection.
          </p>
          <p>
            <strong>Information We Share</strong>
          </p>
          <p>
            We do not disclose personal information we obtain about you, except
            as described in this Privacy Statement. We may share your personal
            information with our subsidiaries and affiliates for the purposes
            described in this Privacy Statement. We also may share personal
            information with service providers who perform services on our
            behalf based on our instructions. We do not authorize these service
            providers to use or disclose the information except as necessary to
            perform services on our behalf or to comply with legal requirements.
          </p>
          <p>
            In addition, we may disclose information about you if required to do
            so by law or if we believe such action is necessary to comply with
            legal process. We also reserve the right to transfer personal
            information we have about you in the event we sell or transfer all
            or a portion of our business or assets (including in the event of a
            merger, acquisition, joint venture, reorganization, divestiture,
            dissolution or liquidation).
          </p>
          <p>
            <strong>Your Choices</strong>
          </p>
          <p>
            You may update your profile preferences, job alert settings and
            candidate data by logging into your profile. You may also contact us
            as described in the <u>How to Contact Us</u> section below.
          </p>
          <p>
            <strong>How We Protect Personal Information</strong>
          </p>
          <p>
            We maintain administrative, technical and physical safeguards
            designed to protect the personal information you provide against
            accidental, unlawful or unauthorized access, destruction, loss,
            alteration, disclosure or use.
          </p>
          <p>
            <strong>Children’s Personal Information</strong>
          </p>
          <p>
            Our Site and App are designed for a general audience and are not
            directed to children. We do not knowingly collect or solicit
            personal information from children under the age of thirteen (13)
            through our Site or App. If we become aware that we have collected
            personal information from a child under thirteen (13), we will
            promptly delete the information from our records. If you believe a
            child under thirteen (13) may have provided us with personal
            information, please contact us as specified in the{" "}
            <u>How to Contact Us</u> section of this Privacy Statement.
          </p>
          <p>
            <strong>
              Links to Non-The brands of Ahold Delhaize USA Sites and Services
            </strong>
          </p>
          <p>
            For your convenience and information, our Site and App may provide
            links to or plug-ins of non-ADUSA apps, tools, widgets, websites or
            services that may be operated by companies not affiliated with
            ADUSA. The privacy practices of the relevant third parties,
            including details on the information they may collect about you, is
            subject to the privacy statements of these parties, which we
            strongly suggest you review. We are not responsible for the privacy
            practices of any non-ADUSA apps, tools, widgets, websites, services
            or plug-ins.
          </p>
          <p>
            <strong>Updates to Our Privacy Statement</strong>
          </p>
          <p>
            This Privacy Statement may be updated periodically to reflect
            changes in our personal information practices. Changes to the
            Privacy Statement will be posted on this page. For significant
            changes, we will notify you by posting a prominent notice on our
            Site and App indicating at the top of the Statement when it was most
            recently updated.
          </p>
          <p>
            <strong>How to Contact Us</strong>
          </p>
          <p>
            You can update your profile, job alert settings and preferences
            through the career portal. You can also contact us with any
            questions you have about this Privacy Statement by sending an email
            to{" "}
            <a href="mailto:privacy@retailbusinessservices.com">
              privacy@retailbusinessservices.com
            </a>{" "}
            or writing to the Privacy Officer at Retail Business Services, LLC,
            145 Pleasant Hill Road, Mail Sort 9805, Scarborough, ME 04074.
          </p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ReactToPrint
          trigger={() => {
            return <Button>Print</Button>;
          }}
          content={() => printEl.current}
        />
        <Button color="info" onClick={() => handleClose(false)}>
          Disagree
        </Button>
        <Button color="info" onClick={() => handleClose(true)} autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
}
