import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../../features/auth/states/auth_slice";
import accountReducer from "../../features/external/states/account_slice";
import internalAccountReducer from "../../features/internal/states/account_slice";
import jobReducer from "../../features/external/states/job_slice";

export default configureStore({
  reducer: {
    auth: authReducer,
    account: accountReducer,
    internalaccount: internalAccountReducer,
    job: jobReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
});
