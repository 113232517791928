import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const UsStateSelector = (props) => {
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel htmlFor="outlined-state-native-simple">
        {props.title}
      </InputLabel>
      <Select
        value={props.data ? props.data + "" : ""}
        onChange={props.handleChange}
        label={props.title}
        inputProps={{
          name: "state",
          id: "outlined-state-native-simple",
        }}
      >
        <MenuItem value="">No Selection</MenuItem>
        <MenuItem value="75286">Alabama</MenuItem>
        <MenuItem value="75285">Alaska</MenuItem>
        <MenuItem value="75288">Arizona</MenuItem>
        <MenuItem value="75289">Arkansas</MenuItem>
        <MenuItem value="75290">California</MenuItem>
        <MenuItem value="75291">Colorado</MenuItem>
        <MenuItem value="75292">Connecticut</MenuItem>
        <MenuItem value="75293">Delaware</MenuItem>
        <MenuItem value="75294">District of Columbia</MenuItem>
        <MenuItem value="75295">Florida</MenuItem>
        <MenuItem value="75296">Georgia</MenuItem>
        <MenuItem value="75297">Hawaii</MenuItem>
        <MenuItem value="75298">Idaho</MenuItem>
        <MenuItem value="75299">Illinois</MenuItem>
        <MenuItem value="75300">Indiana</MenuItem>
        <MenuItem value="75301">Iowa</MenuItem>
        <MenuItem value="75302">Kansas</MenuItem>
        <MenuItem value="75303">Kentucky</MenuItem>
        <MenuItem value="75304">Louisiana</MenuItem>
        <MenuItem value="75305">Maine</MenuItem>
        <MenuItem value="75306">Maryland</MenuItem>
        <MenuItem value="75307">Massachusetts</MenuItem>
        <MenuItem value="75308">Michigan</MenuItem>
        <MenuItem value="75309">Minnesota</MenuItem>
        <MenuItem value="75310">Mississippi</MenuItem>
        <MenuItem value="75311">Missouri</MenuItem>
        <MenuItem value="75312">Montana</MenuItem>
        <MenuItem value="75313">Nebraska</MenuItem>
        <MenuItem value="75314">Nevada</MenuItem>
        <MenuItem value="75315">New Hampshire</MenuItem>
        <MenuItem value="75316">New Jersey</MenuItem>
        <MenuItem value="75317">New Mexico</MenuItem>
        <MenuItem value="75318">New York</MenuItem>
        <MenuItem value="75319">North Carolina</MenuItem>
        <MenuItem value="75287">North Dakota</MenuItem>
        <MenuItem value="75320">Ohio</MenuItem>
        <MenuItem value="75321">Oklahoma</MenuItem>
        <MenuItem value="75322">Oregon</MenuItem>
        <MenuItem value="75323">Pennsylvania</MenuItem>
        <MenuItem value="75336">Puerto Rico</MenuItem>
        <MenuItem value="75324">Rhode Island</MenuItem>
        <MenuItem value="75325">South Carolina</MenuItem>
        <MenuItem value="75326">South Dakota</MenuItem>
        <MenuItem value="75327">Tennessee</MenuItem>
        <MenuItem value="75328">Texas</MenuItem>
        <MenuItem value="75329">Utah</MenuItem>
        <MenuItem value="75330">Vermont</MenuItem>
        <MenuItem value="75331">Virginia</MenuItem>
        <MenuItem value="75337">Virgin Islands</MenuItem>
        <MenuItem value="75332">Washington</MenuItem>
        <MenuItem value="75333">West Virginia</MenuItem>
        <MenuItem value="75334">Wisconsin</MenuItem>
        <MenuItem value="75335">Wyoming</MenuItem>
      </Select>
    </FormControl>
  );
};

export default UsStateSelector;
