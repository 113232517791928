import React from "react";
import Routes from "./routes";
import { getTheme } from "../theme";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

import { ThemeProvider } from "@material-ui/styles";
import { getUrlParameterByName } from "../../common/util/string_utility";

function App() {
  let company = getUrlParameterByName("company");
  if (!company) {
    company = localStorage.getItem("company");
  }

  return (
    <ThemeProvider theme={getTheme(company)}>
      <ReactNotification />
      <Routes />
    </ThemeProvider>
  );
}

export default App;
