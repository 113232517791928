import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import isEmail from "validator/lib/isEmail";
import AppLoading from "../../../common/components/loading";
import { store } from "react-notifications-component";
import { withRouter } from "react-router-dom";
import {
  selectAuth,
  resetPassword,
  initResetPassword,
} from "../states/auth_slice";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { getUrlParameterByName } from "../../../common/util/string_utility";

const ForgotPasswordPage = (props) => {
  const authState = useSelector(selectAuth);
  const dispatch = useDispatch();
  const [userEmail, setUserEmail] = useState();
  const [userEmailError, setUserEmailError] = useState(undefined);
  const company = getUrlParameterByName("company", true);
  const clickSubmit = () => {
    if (!userEmailError) {
      dispatch(resetPassword({ username: userEmail.trim() }));
    }
  };

  useEffect(() => {
    if (!authState.readyForgotpassword) {
      dispatch(initResetPassword());
    }
  }, [authState.readyForgotpassword]);
  useEffect(() => {
    if (authState.success === true) {
      store.addNotification({
        title: "",
        message: "Reset password email sent successfully",
        type: "success",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      setTimeout(() => {
        window.location.href =
          "/external?jobid=" +
          getUrlParameterByName("jobid") +
          "&company=" +
          encodeURIComponent(company);
      }, 5000);
    }
  }, [authState.success]);

  if (!authState.readyForgotpassword) {
    return (
      <Container
        maxWidth="sm"
        style={{ minHeight: "100vh", marginTop: "20vh", textAlign: "center" }}
      >
        <AppLoading />
      </Container>
    );
  }
  return (
    <Container maxWidth="sm" style={{ minHeight: "100vh", marginTop: "5vh" }}>
      <h3>
        Please identify your account, and we will email you a link to reset your
        password.
      </h3>
      {authState.onLoading === true ? (
        <div style={{ textAlign: "center" }}>
          <AppLoading />
        </div>
      ) : (
        <TextField
          required
          label="Email"
          fullWidth
          variant="outlined"
          value={userEmail}
          onChange={(e) => {
            setUserEmail(e.target.value.trim());
            if (!isEmail(e.target.value.trim())) {
              setUserEmailError("Please input a valid email address");
            } else {
              setUserEmailError(undefined);
            }
          }}
          error={userEmailError !== undefined}
          helperText={userEmailError}
        />
      )}
      <Grid
        container
        spacing={1}
        style={{
          marginTop: "40px",
          alignItems: "baseline",
        }}
      >
        <Grid
          item
          sm={6}
          xs={6}
          md={6}
          lg={6}
          style={{ textAlign: "right", paddingRight: "10px" }}
        >
          <Button
            color="primary"
            variant="contained"
            disabled={
              userEmailError !== undefined ||
              isEmpty(userEmail) ||
              authState.onLoading === true
            }
            onClick={(e) => {
              e.preventDefault();
              clickSubmit();
            }}
          >
            Submit
          </Button>
        </Grid>
        <Grid
          item
          sm={6}
          xs={6}
          md={6}
          lg={6}
          style={{ textAlign: "left", paddingLeft: "10px" }}
        >
          <Button
            color="default"
            variant="contained"
            disabled={authState.onLoading === true}
            onClick={(e) => {
              e.preventDefault();
              props.history.push(
                "/external/login?jobId=" +
                  getUrlParameterByName("jobId") +
                  "&company=" +
                  encodeURIComponent(getUrlParameterByName("company", true))
              );
            }}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default withRouter(ForgotPasswordPage);
