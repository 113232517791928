import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "../../features/home";
import PrevLoginPage from "../../features/auth/components/prev_login";
import LoginPage from "../../features/auth/components/login";
import CreateAccountPage from "../../features/common/components/create_account";
import ForgotPasswordPage from "../../features/auth/components/forgot_password";
import CandidateProfilePage from "../../features/common/components/candidate_profile";
import ExternalHome from "../../features/home/external_index";
import InternalHome from "../../features/home/internal_index";
import ExternalIndex from "../../features/external/components/index";
import InternalIndex from "../../features/internal/components/index";
import JobApply from "../../features/external/components/job_apply";
import InternalJobApply from "../../features/internal/components/job_apply";
import PropTypes from "prop-types";
import App from "./app";
import { useSelector } from "react-redux";
import { selectAuth } from "../../features/auth/states/auth_slice";

const AppRoute = ({ component: Component, ...rest }) => {
  const auth = useSelector(selectAuth);
  const isLogin =
    true ||
    /*!isNil(auth.session) ||
    !isNil(localStorage.getItem("session")) ||*/
    [
      "/external/login",
      "/external/create_account",
      "/external/forgot_password",
      "/external/profile",
      "/internal",
    ].indexOf(rest.path) >= 0;
  const { title } = rest;
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <App {...rest}>
            {isLogin ? (
              <Component {...rest} {...props} />
            ) : (
              <PrevLoginPage
                title={"First time applicant?"}
                {...rest}
                {...props}
              />
            )}
          </App>
        );
      }}
    />
  );
};

AppRoute.propTypes = {
  component: PropTypes.any,
};

const Routes = () => {
  return (
    <Router>
      <Switch>
        <AppRoute
          exact
          path="/external"
          component={ExternalIndex}
          title={"Job Information"}
        />
        <AppRoute
          exact
          path="/internal"
          component={InternalIndex}
          title={"Job Information"}
        />
        <AppRoute
          exact
          path="/external/login"
          component={LoginPage}
          title="login"
        />
        <AppRoute
          exact
          path="/internal/login"
          component={LoginPage}
          title="login"
        />
        <AppRoute
          exact
          path="/external/create_account"
          component={CreateAccountPage}
          title="Create Account"
        />
        <AppRoute
          exact
          path="/internal/create_account"
          component={CreateAccountPage}
          title="Create Account"
        />
        <AppRoute
          exact
          path="/external/profile"
          component={CandidateProfilePage}
          title="Candidate Profile"
        />
        <AppRoute
          exact
          path="/internal/profile"
          component={CandidateProfilePage}
          title="Candidate Profile"
        />
        <AppRoute
          exact
          path="/external/apply"
          component={JobApply}
          title="Apply Job"
        />
        <AppRoute
          exact
          path="/internal/apply"
          component={InternalJobApply}
          title="Apply Job"
        />
        <AppRoute
          exact
          path="/external/forgot_password"
          component={ForgotPasswordPage}
          title={"Forgot Password"}
        />
        <AppRoute exact path="/external/home" component={ExternalHome} />
        <AppRoute exact path="/internal/home" component={InternalHome} />
        <AppRoute exact path="/" component={PrevLoginPage} />
      </Switch>
    </Router>
  );
};

export default Routes;
