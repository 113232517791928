import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import { getUrlParameterByName } from "../../../common/util/string_utility";

const InternalIndex = (props) => {
  const jobId = getUrlParameterByName("jobId");
  const company = getUrlParameterByName("company", true);
  const [openErrorDialog /*, setOpenErrorDialog*/] = useState(false);
  props.history.push("/internal/profile?jobId=" + jobId + "&company=" + encodeURIComponent(company));

  return (
    <Container maxWidth="sm">
      <Dialog fullWidth={true} maxWidth={"sm"} open={openErrorDialog}>
        <DialogContent>
          <DialogContentText>
            <strong>{/*jobState.errorMessage*/}</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            color="secondary"
            variant="contained"
            onClick={(e) => {
              sessionStorage.removeItem("ma-session");
              window.location.replace("/internal/home");
            }}
          >
            Go to job list
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default InternalIndex;
