import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getServiceBasePath,
  getUrlParameterByName,
} from "../../../common/util/string_utility";
import { callApi } from "../../../common/util/fetch";
import map from "lodash/map";

const jobId = getUrlParameterByName("jobId");
const company = "ADPROD"; //getUrlParameterByName("company", true);

export const jobRequest = createAsyncThunk(
  "internal/job-request",
  async (params, thunkAPI) => {
    const baseUrl = getServiceBasePath("auth");
    return await callApi(
      thunkAPI,
      `${baseUrl}/jobreq/internal?jobId=${jobId}&company=${company}`,
      {
        method: "GET",
        fullEndpoint: true,
        // credentials: "include",
      }
    );
  }
);

export const jobApply = createAsyncThunk(
  "internal/job-apply",
  async (params, thunkAPI) => {
    const baseUrl = getServiceBasePath("auth");
    return await callApi(thunkAPI, `${baseUrl}/jobreq/internal/apply`, {
      method: "POST",
      fullEndpoint: true,
      // credentials: "include",
    });
  }
);

export const jobSlice = createSlice({
  name: "job",
  initialState: {
    errorMessage: undefined,
    onLoading: undefined,
    jobReqSuccess: undefined,
    jobApplySuccess: undefined,
    message: undefined,
  },
  reducers: {},
  extraReducers: {
    [jobRequest.pending]: (state, action) => {
      state.onLoading = true;
      state.jobReqSuccess = undefined;
      state.message = undefined;
      state.errorMessage = undefined;
    },
    [jobRequest.fulfilled]: (state, action) => {
      state.onLoading = undefined;
      state.message = action.job;
      state.jobReqSuccess = true;
      state.errorMessage = undefined;
    },
    [jobRequest.rejected]: (state, action) => {
      state.onLoading = undefined;
      state.jobReqSuccess = false;
      state.message = undefined;
      state.errorMessage =
        action.payload &&
        action.payload.errors &&
        action.payload.errors.length > 0
          ? map(action.payload.errors, (err) => {
              return err.message;
            })
          : ["Click to return to the Career Search"];
    },
    [jobApply.pending]: (state, action) => {
      state.onLoading = true;
      state.jobApplySuccess = undefined;
      state.message = undefined;
      state.errorMessage = undefined;
    },
    [jobApply.fulfilled]: (state, action) => {
      state.onLoading = undefined;
      state.jobApplySuccess = action.payload.success;
      state.message = action.payload.message;
      state.errorMessage = undefined;
    },
    [jobApply.rejected]: (state, action) => {
      state.onLoading = undefined;
      state.jobApplySuccess = false;
      state.message = undefined;
      state.errorMessage =
        action.payload &&
        action.payload.errors &&
        action.payload.errors.length > 0
          ? map(action.payload.errors, (err) => {
              return err.message;
            })
          : ["Click to return to the Career Search"];
    },
  },
});

export const selectJob = (state) => state.job;

export default jobSlice.reducer;
