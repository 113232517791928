import React, { useState, useEffect } from "react";
import Stepper from "@material-ui/core/Stepper";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import moment from "moment";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { withRouter } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import CreateIcon from "@material-ui/icons/Create";
import {
  selectAccount,
  uploadResume,
  uploadCoverLetter,
  updateProfile,
  deleteCoverLetter,
  deleteResume
} from "../../external/states/account_slice";

import {
  internalSelectAccount,
  internalUploadResume,
  internalUploadCoverLetter,
  internalUpdateProfile,
  internalDeleteCoverLetter,
  internalDeleteResume,
  getProfile
} from "../../internal/states/account_slice";

import { useDispatch, useSelector } from "react-redux";
import BasicInformation from "./basic_info";
import EducationInfo from "./education_info";
import WorkInfo from "./work_info";
import InternalWorkInfo from "../../internal/components/work_info";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import AppLoading from "../../../common/components/loading";
import { getUrlParameterByName } from "../../../common/util/string_utility";
import { useSnackbar } from "notistack";
import { forEach } from "lodash";
import { formatDistanceToNow } from "date-fns";
import ReactGA from 'react-ga4';

var STEPS_COUNT = 6;
const CandidateProfilePage = (props) => {
  const loginType =
    window.location.href.indexOf("internal") >= 0 ? "internal" : "external";

  if (loginType === "internal") {
    STEPS_COUNT = 6;
  }

  const accountState = useSelector(selectAccount);
  const internalAccountState = useSelector(internalSelectAccount);
  const dispatch = useDispatch();
  const jobId = getUrlParameterByName("jobId");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const action = (key) => (
    <Button
      size="small"
      color="default"
      onClick={() => {
        closeSnackbar(key);
      }}
    >
      Dismiss
    </Button>
  );
  const [gaInit, setGaInit] = useState(false);
  const [userPhone, setUserPhone] = useState();
  const [homePhone, setHomePhone] = useState();
  const [userEmail, setUserEmail] = useState();
  const [firstName, setFirstName] = useState();
  const [middleName, setMiddleName] = useState(undefined);
  const [lastName, setLastName] = useState();
  const [userAddress1, setUserAddress1] = useState();
  const [userAddress2, setUserAddress2] = useState();
  const [county, setCounty] = useState();
  const [city, setCity] = useState();
  const [zipCode, setZipCode] = useState();
  const [state, setState] = useState();
  const [preFirstName, setPreFirstnName] = useState(undefined);
  const [activeStep, setActiveStep] = React.useState(0);
  const [eduInfos, setEduInfos] = useState([]);
  const [workInfos, setWorkInfos] = useState([]);
  const [internalWorkInfos, setInternalWorkInfos] = useState([]);
  const [profileVisibility, setProfileVisibility] = useState("1");
  const [resume, setResume] = useState({ exists: false });
  const [coverLetter, setCoverLetter] = useState({ exists: false });
  const [employeeId, setEmployeeId] = useState();

  const company = getUrlParameterByName("company", true);

  useEffect(() => {
    if (!gaInit) {
      let tracker2 = 'G-E126M15ZVB';
      if (company) {
        switch (company.toLowerCase()) {
            case "ahold e-commerce sales company":
              tracker2 = 'G-Y6LBBD1JM8';
              break;
            case "peapod digital labs llc":
              tracker2 = 'G-0SRCNRM49R';
              break;
            case "ahold delhaize corp support llc":
              case "ahold+delhaize+corp+support+llc":
              tracker2 = 'G-FNF6G20BGE';
              break;
            case "giant delivery llc":
              case "giant+delivery+llc":
              tracker2 = 'G-F597CJX59P';
              break;  
              case "adusa+distribution,+llc":         
            case "adusa distribution, llc":
              tracker2 = 'G-6BVGD5VBX6';
              break;
            case "adusa transportation, llc":
              case "adusa+transportation,+llc":
              tracker2 = 'G-6BVGD5VBX6';
              break;
            case "adusa procurement, llc":
              case "adusa+procurement,+llc":
              tracker2 = 'G-YQ24BBTRHX';
              break;
            case "adusa supply chain services, llc":
            case "adusa supply chain, llc":
              tracker2 = 'G-6BVGD5VBX6';
              break;
            case "food lion, llc":
              case "food+lion,+llc":
              tracker2 = 'G-JCGW010YGQ';
              break;
              case "hannaford bros. co., llc":
                case "martin's foods of south burlington, llc":
                case "victory distributors, llc":
                case "hannaford+bros.+co.,+llc":
                case "martin's+foods+of+south+burlington,+llc":
                case "victory+distributors,+llc":
              tracker2 = 'G-WKDMPMX8GJ';
              break;
            case "ahold delhaize usa":
            case "ahold delhaize usa, inc.":
            case "guiding star licensing company":
            case "ahold delhaize usa services, llc":
              case "ahold+delhaize+usa":
                case "ahold+delhaize+usa,+inc.":
                case "guiding+star+licensing+company":
                case "ahold+delhaize+usa+services,+llc":
              tracker2 = 'G-4YP43BLGZ2';
              break;
            case "retail business services llc" :
              tracker2 = 'G-4YP43BLGZ2';
              break;
              case "the+stop+and+shop+supermarket+company+llc":
            case "the stop and shop supermarket company llc":
              tracker2 = 'G-LH1X5V2MF8';
              break;
              case "the+giant+company+llc":
            case "the giant company llc":
              tracker2 = 'G-TZGHT43SBL';
              break;
            case "giant of maryland llc":
              case "giant+of+maryland+llc":
              tracker2 = 'G-MQQQ3DJQE3';
              break;
            case "aedev":
              tracker2 = 'G-FNF6G20BGE';
              break;
            case "gddev":
              tracker2 = 'G-0SRCNRM49R';
              break;
            case "addev":
              tracker2 = 'G-0SRCNRM49R';
              break;
            case "pddev":
            default:
              tracker2 = 'G-0SRCNRM49R';
              break;
          }
        }

        ReactGA.initialize(
          [
            {
              trackingId: 'G-E126M15ZVB',
              gaOptions: {
                name: 'tracker1'
              }
            },
            {
              trackingId: tracker2,
              gaOptions: { name: 'tracker2' }
            }
          ]
        );
        if (getUrlParameterByName("jobId")) {
          ReactGA.send({hitType: 'pageview', page: `/${loginType}/profile.html?jobId=` + getUrlParameterByName("jobId"), title: "Profile Page"});
        }
        else {
          ReactGA.send({hitType: 'pageview', page: `/${loginType}/profile.html`, title: "Profile Page"});
        }
        setGaInit(true);
    }
  }, [gaInit]);

  useEffect(() => {
    function initProfile() {
      if (loginType === "external") {
        if (accountState.account_profile) {
          setUserEmail(accountState.account_profile.contactEmail);
          setUserPhone(accountState.account_profile.cellPhone);
          setHomePhone(accountState.account_profile.homePhone);
          setCounty(accountState.account_profile.county);
          setUserAddress1(accountState.account_profile.address);
          setUserAddress2(accountState.account_profile.address2);
          setZipCode(accountState.account_profile.zip);
          setWorkInfos(accountState.account_profile.previousEmployments);
          setInternalWorkInfos(accountState.account_profile.internalPreviousEmployments);
          setEduInfos(accountState.account_profile.educations);
          setFirstName(accountState.account_profile.firstName);
          setLastName(accountState.account_profile.lastName);
          setCity(accountState.account_profile.city);
          setState(accountState.account_profile.state);
          setPreFirstnName(accountState.account_profile.preferredName);
          setMiddleName(accountState.account_profile.middleName);
          setProfileVisibility(
            accountState.account_profile.profileVisibility + ""
          );
          setResume(accountState.account_profile.resume);
          setCoverLetter(accountState.account_profile.coverLetter);
        } else {
          props.history.push(`/${loginType}?jobId=${jobId}&company=${company}`);
        }
      }
      else {
        if (internalAccountState.getProfileSuccess !== true) {
           dispatch(getProfile());
        }
        if (accountState.account_profile) {
          setUserEmail(accountState.account_profile.contactEmail);
          setUserPhone(accountState.account_profile.cellPhone);
          setHomePhone(accountState.account_profile.homePhone);
          setCounty(accountState.account_profile.county);
          setUserAddress1(accountState.account_profile.address);
          setUserAddress2(accountState.account_profile.address2);
          setZipCode(accountState.account_profile.zip);
          setWorkInfos(accountState.account_profile.previousEmployments);
          setInternalWorkInfos(accountState.account_profile.internalPreviousEmployments);
          setEduInfos(accountState.account_profile.educations);
          setFirstName(accountState.account_profile.firstName);
          setLastName(accountState.account_profile.lastName);
          setCity(accountState.account_profile.city);
          setState(accountState.account_profile.state);
          setPreFirstnName(accountState.account_profile.preferredName);
          setMiddleName(accountState.account_profile.middleName);
          setProfileVisibility(
            accountState.account_profile.profileVisibility + ""
          );
          setResume(accountState.account_profile.resume);
          setCoverLetter(accountState.account_profile.coverLetter);
        }
      }
    }
    
    initProfile();
  }, []); //[accountState.account_profile, jobId, company, loginType, props.history]);

  useEffect(() => {
    if (loginType === "external") {
      if (accountState.success === true) {
        props.history.push(
          `/${loginType}/apply?jobId=` +
            getUrlParameterByName("jobId") +
            "&company=" +
            encodeURIComponent(company)
        );
      }
    }
  }, [accountState.success, company, loginType, props.history]);
  
  useEffect(() => {
    if (loginType === "internal") {
      if (internalAccountState.success === true) {
        props.history.push(
          `/${loginType}/apply?jobId=` +
            getUrlParameterByName("jobId") +
            "&company=" +
            encodeURIComponent(company)
        );
      }
    }
  }, [internalAccountState.success, company, loginType, props.history]);

  useEffect(() => {
    if (loginType === "external") {
      if (accountState.sessionExpired === true) {
        window.location.href =
          `/${loginType}?jobId=` + jobId + "&company=" + encodeURIComponent(company) + "&expired";
      }
    }
  }, [accountState.sessionExpired, company, loginType, jobId]);
  useEffect(() => {
    if (accountState.uploadingSuccess === true) {
      setResume({
        exists: true,
        lastUpdated: moment().format("MM/DD/YYYY"),
      });
    }
  }, [accountState.uploadingSuccess]);
  useEffect(() => {
    if (accountState.uploadCoverLetterSuccess === true) {
      setCoverLetter({
        exists: true,
        lastUpdated: moment().format("MM/DD/YYYY"),
      });
    }
  }, [accountState.uploadCoverLetterSuccess]);
  useEffect(() => {
    if (internalAccountState.uploadingSuccess === true && internalAccountState.account_profile && internalAccountState.account_profile.resume) {
      setResume({
        exists: true,
        lastUpdated: moment().format("MM/DD/YYYY"),
        attachmentId: internalAccountState.account_profile.resume.attachmentId
      });
    }
  }, [internalAccountState.uploadingSuccess]);
  useEffect(() => {
    if (internalAccountState.uploadCoverLetterSuccess === true && internalAccountState.account_profile && internalAccountState.account_profile.coverLetter) {
      setCoverLetter({
        exists: true,
        lastUpdated: moment().format("MM/DD/YYYY"),
        attachmentId: internalAccountState.account_profile.coverLetter.attachmentId
      });
    }
  }, [internalAccountState.uploadCoverLetterSuccess]);
  useEffect(() => {
    if (accountState.deleteResumeSuccess === true) {
      setResume({
        exists: false,
      });
    }
  }, [accountState.deleteResumeSuccess]);
  useEffect(() => {
    if (accountState.deleteCoverLetterSuccess === true) {
      setCoverLetter({
        exists: false,
      });
    }
  }, [accountState.deleteCoverLetterSuccess]);
  useEffect(() => {
    if (accountState.message) {
      if (!accountState.success) {
        forEach(accountState.message, (message) => {
          enqueueSnackbar(message, {
            variant: "error",

            action,
          });
        });
        setActiveStep(0);
      } else {
        enqueueSnackbar(accountState.message, {
          variant: "info",
          action
        });
      }
    }
  }, [accountState.message, accountState.success, enqueueSnackbar]);

  useEffect(() => {
    if (internalAccountState.message) {
      if (!internalAccountState.success) {
        forEach(internalAccountState.message, (message) => {
          enqueueSnackbar(message, {
            variant: "error",

            action,
          });
        });
        setActiveStep(0);
      } else {
        enqueueSnackbar(internalAccountState.message, {
          variant: "info",
          action
        });
      }
    }
  }, [internalAccountState.message, internalAccountState.success, enqueueSnackbar]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleUploadResume = (e) => {
    const file = e.target.files[0];
    if (file.size > 1048576 * 10) {
      alert("File size can not larger than 10M");
      return;
    }
    const formData = new FormData();
    formData.append("resume", file);
    if (loginType === "external") {
        dispatch(uploadResume(formData));
    }
    else {
      formData.append("employeeId", employeeId);
      dispatch(internalUploadResume(formData));
    }
  };
  const handleUploadCoverLetter = (e) => {
    const file = e.target.files[0];
    if (file.size > 1048576 * 10) {
      alert("File size can not larger than 10M");
      return;
    }
    const formData = new FormData();
    formData.append("cover_letter", file);
    if (loginType === "external") {
      dispatch(uploadCoverLetter(formData));
    }
    else {
      formData.append("employeeId", employeeId);
      dispatch(internalUploadCoverLetter(formData));
    }
  };
  const btnContinueClick = (e) => {
    e.preventDefault();

    if (loginType === "internal") {
      //props.history.push(`/internal/apply?jobId=${jobId}&company=${company}`);
      let profile_info = {
        firstName: firstName,
        lastName: lastName,
        cellPhone: userPhone,
        contactEmail: userEmail,
        preferredName: preFirstName,
        profileVisibility: profileVisibility,
        //country: accountState.account_profile.country,
        county: county,
        address: userAddress1,
        address2: userAddress2,
        city: city,
        zip: zipCode,
        homePhone: homePhone,
        state: state,
        employeeId: employeeId,
        jobId: jobId,
        company: company
      };
      if (!isEmpty(preFirstName)) {
        profile_info.preferredName = preFirstName;
      }
      if (!isEmpty(middleName)) {
        profile_info.middleName = middleName;
      }
      if (!isEmpty(eduInfos)) {
        profile_info.educations = eduInfos;
      }
      if (!isEmpty(workInfos)) {
        profile_info.previousEmployments = workInfos;
      }
      if (!isEmpty(internalWorkInfos)) {
        profile_info.internalPreviousEmployments = internalWorkInfos;
      }
      dispatch(internalUpdateProfile(profile_info));
    } else {
      let profile_info = {
        firstName: firstName,
        lastName: lastName,
        cellPhone: userPhone,
        contactEmail: userEmail,
        preferredName: preFirstName,
        profileVisibility: profileVisibility,
        country: accountState.account_profile.country,
        county: county,
        address: userAddress1,
        address2: userAddress2,
        city: city,
        zip: zipCode,
        homePhone: homePhone,
        state: state,
      };
      if (!isEmpty(preFirstName)) {
        profile_info.preferredName = preFirstName;
      }
      if (!isEmpty(middleName)) {
        profile_info.middleName = middleName;
      }
      if (!isEmpty(eduInfos)) {
        profile_info.educations = eduInfos;
      }
      if (!isEmpty(workInfos)) {
        profile_info.previousEmployments = workInfos;
      }
      if (!isEmpty(internalWorkInfos)) {
        profile_info.internalPreviousEmployments = internalWorkInfos;
      }
      dispatch(updateProfile(profile_info));
    }
  };
  if (accountState.onLoading === true && loginType === "external") {
    return (
      <Container
        maxWidth="sm"
        style={{ minHeight: "100vh", marginTop: "20vh", textAlign: "center" }}
      >
        <AppLoading />
      </Container>
    );
  }
  if (internalAccountState.onLoading === true && loginType === "internal") {
    return (
      <Container
        maxWidth="sm"
        style={{ minHeight: "100vh", marginTop: "20vh", textAlign: "center" }}
      >
        <AppLoading />
      </Container>
    );
  }

  return (
    <div>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        style={{ marginBottom: "70px" }}
      >
        <Step key={"step-1"}>
          <StepLabel>{"Basic Information"}</StepLabel>
          <StepContent>
            <BasicInformation
              employeeId={employeeId}
              firstName={firstName}
              lastName={lastName}
              middleName={middleName}
              userEmail={userEmail}
              userPhone={userPhone}
              homePhone={homePhone}
              userAddress1={userAddress1}
              userAddress2={userAddress2}
              county={county}
              zipCode={zipCode}
              state={state}
              setState={setState}
              preFirstName={preFirstName}
              profileVisibility={profileVisibility}
              city={city}
              setCity={setCity}
              setPreFirstnName={setPreFirstnName}
              setMiddleName={setMiddleName}
              setZipCode={setZipCode}
              setCounty={setCounty}
              setUserPhone={setUserPhone}
              setHomePhone={setHomePhone}
              setUserEmail={setUserEmail}
              setUserAddress1={setUserAddress1}
              setUserAddress2={setUserAddress2}
              setFirstName={setFirstName}
              setLastName={setLastName}
              setProfileVisibility={setProfileVisibility}
              setEmployeeId={setEmployeeId}
            />
          </StepContent>
        </Step>
        <Step key={"step-2"}>
          <StepLabel>Education History</StepLabel>
          <StepContent>
            <div style={{ textAlign: 'center', paddingBottom: '20px', paddingTop: '10px' }}>
                Please provide any additional information you would like us to consider.
            </div>
            {map(eduInfos, (info, index) => {
              return (
                <>
                  <EducationInfo
                    info={info}
                    setInfo={(info) => {
                      let shadow = [];
                      Object.assign(shadow, eduInfos);
                      shadow[index] = info;
                      setEduInfos(shadow);
                    }}
                    handleRemove={() => {
                      eduInfos.splice(index, 1);
                      setEduInfos([...eduInfos]);
                    }}
                  />
                  <hr />
                </>
              );
            })}
            <div style={{ textAlign: "center" }}>
              <Button
                color="primary"
                variant="contained"
                style={{ marginLeft: "20px", maginTop: "10px" }}
                onClick={(e) => {
                  e.preventDefault();
                  setEduInfos([...eduInfos, { schoolName: "", graduated: "" }]);
                }}
              >
                Add
              </Button>
            </div>
          </StepContent>
        </Step>
        {loginType === "rickjames" && (
          <Step key={"step-internal-work"}>
            <StepLabel>Work Experience Within Company</StepLabel>
            <StepContent>
              {map(internalWorkInfos, (info, index) => {
                return (
                  <>
                    <InternalWorkInfo
                      info={info}
                      setInfo={(info) => {
                        let shadow = [];
                        Object.assign(shadow, internalWorkInfos);
                        shadow[index] = info;
                        setInternalWorkInfos(shadow);
                      }}
                      handleRemove={() => {
                        internalWorkInfos.splice(index, 1);
                        setInternalWorkInfos([...internalWorkInfos]);
                      }}
                    />
                    <hr />
                  </>
                );
              })}
              <div style={{ textAlign: "center" }}>
                <Button
                  color="primary"
                  variant="contained"
                  style={{ marginLeft: "20px", maginTop: "10px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    setInternalWorkInfos([...internalWorkInfos, {}]);
                  }}
                >
                  Add
                </Button>
              </div>
            </StepContent>
          </Step>
        )}
        <Step key={"step-3"}>
          <StepLabel>Work History</StepLabel>
          <StepContent>
            {map(workInfos, (info, index) => {
              return (
                <>
                  <WorkInfo
                    info={info}
                    setInfo={(info) => {
                      let shadow = [];
                      Object.assign(shadow, workInfos);
                      shadow[index] = info;
                      setWorkInfos(shadow);
                    }}
                    handleRemove={() => {
                      workInfos.splice(index, 1);
                      setWorkInfos([...workInfos]);
                    }}
                  />
                  <hr />
                </>
              );
            })}
            <div style={{ textAlign: "center" }}>
              <Button
                color="primary"
                variant="contained"
                style={{ marginLeft: "20px", maginTop: "10px" }}
                onClick={(e) => {
                  e.preventDefault();
                  setWorkInfos([...workInfos, {}]);
                }}
              >
                Add
              </Button>
            </div>
          </StepContent>
        </Step>
        <Step key={"step-resume"}>
          <StepLabel>{"Resume"}</StepLabel>
          <StepContent style={{ textAlign: "center" }}>
            {resume.exists === true ? (
              <>
                <label htmlFor="upload-resume">
                  <input
                    style={{ display: "none" }}
                    id="upload-resume"
                    name="resume"
                    accept=".txt,.pdf,.docx"
                    type="file"
                    onChange={handleUploadResume}
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                  />
                  <span>
                    {firstName + " " + lastName + "'s resume "}
                    <br />
                    {accountState.onUploading === true || internalAccountState.onUploading === true
                      ? "Uploading..."
                      : "Last Updated: " + resume.lastUpdated}
                  </span>
                  <IconButton color="secondary" component="span">
                    <CreateIcon />
                  </IconButton>
                </label>
                {loginType === "external" && (
                <IconButton
                  color="secondary"
                  component="span"
                  onClick={(e) => {
                    e.preventDefault();
                    if (loginType === "external") {
                      dispatch(deleteResume());
                    }
                    else {
                      let data = {attachmentId: resume.attachmentId};
                      dispatch(internalDeleteResume(data));
                    }
                  }}
                >
                  <DeleteForeverIcon />
                </IconButton>
                )}
              </>
            ) : (
              <label htmlFor="upload-resume">
                <input
                  style={{ display: "none" }}
                  id="upload-resume"
                  name="resume"
                  type="file"
                  accept=".txt,.pdf,.docx"
                  onChange={handleUploadResume}
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                />
                {(accountState.onUploading === true || internalAccountState.onUploading === true) ? (
                  <div>Uploading...</div>
                ) : (
                  <Button color="primary" variant="contained" component="span">
                    Upload
                  </Button>
                )}
                {accountState.uploadResumeErrorMessage && (
                  <div
                    style={{ color: "red", fontSize: "12px", marginTop: "5px" }}
                  >
                    Error:
                    {accountState.uploadResumeErrorMessage}
                  </div>
                )}
                {internalAccountState.uploadResumeErrorMessage && (
                  <div
                    style={{ color: "red", fontSize: "12px", marginTop: "5px" }}
                  >
                    Error:
                    {internalAccountState.uploadResumeErrorMessage}
                  </div>
                )}
              </label>
            )}
          </StepContent>
        </Step>
        <Step key={"step-cover-letter"}>
          <StepLabel>{"Cover Letter"}</StepLabel>
          <StepContent style={{ textAlign: "center" }}>
            {coverLetter.exists === true ? (
              <>
                <label htmlFor="upload-cl">
                  <input
                    style={{ display: "none" }}
                    id="upload-cl"
                    name="cover-letter"
                    type="file"
                    accept=".txt,.pdf,.docx"
                    onChange={handleUploadCoverLetter}
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                  />
                  <span>
                    {firstName + " " + lastName + "'s cover letter "}
                    <br />
                    {accountState.onUploading === true || internalAccountState.onUploading === true
                      ? "Uploading..."
                      : "Last Updated: " + coverLetter.lastUpdated}
                  </span>
                  <IconButton color="secondary" component="span">
                    <CreateIcon />
                  </IconButton>
                </label>
                {loginType === "external" && (
                <IconButton
                  color="secondary"
                  component="span"
                  onClick={(e) => {
                    e.preventDefault();
                    if (loginType === "external") {
                       dispatch(deleteCoverLetter());
                    }
                    else {
                      dispatch(internalDeleteCoverLetter());
                    }
                  }}
                >
                  <DeleteForeverIcon />
                </IconButton>
                )}
                </>
            ) : (
              <label htmlFor="upload-cl">
                <input
                  style={{ display: "none" }}
                  id="upload-cl"
                  name="cover-letter"
                  type="file"
                  accept=".txt,.pdf,.docx"
                  onChange={handleUploadCoverLetter}
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                />
                {(accountState.onUploading === true || internalAccountState.onUploading === true) ? (
                  <div>Uploading...</div>
                ) : (
                  <Button color="primary" variant="contained" component="span">
                    Upload
                  </Button>
                )}
                {accountState.uploadCoverLetterErrorMessage && (
                  <div
                    style={{ color: "red", fontSize: "12px", marginTop: "5px" }}
                  >
                    Error:
                    {accountState.uploadCoverLetterErrorMessage}
                  </div>
                )}
              </label>
            )}
          </StepContent>
        </Step>
        <Step key={"step-confirm-continue"}>
          <StepLabel>{"Confirm Profile"}</StepLabel>
          <StepContent style={{ textAlign: "center" }}>
            <Button
              color="primary"
              variant="contained"
              component="span"
              onClick={btnContinueClick}
            >
              Update Profile
            </Button>
          </StepContent>
        </Step>
      </Stepper>
      <MobileStepper
        variant="dots"
        steps={STEPS_COUNT}
        position="bottom"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === STEPS_COUNT - 1}
            style={{
              color: activeStep === STEPS_COUNT - 1 ? "#9c9c9c" : "#FFF",
              fontSize: "16px",
            }}
          >
            Next
            <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button
            size="small"
            onClick={handleBack}
            disabled={activeStep === 0}
            style={{
              color: activeStep === 0 ? "#9C9C9C" : "#FFF",
              fontSize: "16px",
            }}
          >
            <KeyboardArrowLeft />
            Back
          </Button>
        }
      />
    </div>
  );
};

export default withRouter(CandidateProfilePage);
