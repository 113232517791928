import React from "react";
import Loader from "react-loader-spinner";
import { useTheme } from "@material-ui/core/styles";

const AppLoading = () => {
  const theme = useTheme();
  return (
    <Loader
      type="Bars"
      color={theme.palette.secondary.main}
      height={80}
      width={80}
    />
  );
};

export default AppLoading;
