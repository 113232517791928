export const getAuthBaseUrl = () => {
  return process.env.REACT_APP_API_HOST;
};

export const getUserSessionAPI = () => {
  return process.env.REACT_APP_INIT_API;
};

export const getServiceBasePath = (service) => {
  const serviceMap = {
    auth: getAuthBaseUrl(),
    session: getUserSessionAPI(),
  };
  return serviceMap[service];
};

/*
export function parseUrlParams(url, param) {
  const parsed = queryString.parse(url);
  return isUndefined(param) ? parsed : parsed[param];
}*/
export function getUrlParameterByName(name, keepOrigin, url) {
  if (!url) url = window.location.href;
  if (!keepOrigin) {
    url = url.toLowerCase();
  }
  name = name.replace(/[[\]]/g, "\\$&").toLowerCase();
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2]);
}
