import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import map from "lodash/map";

const ApplicationQuestions = (props) => {
  const { questions, values, changeValue } = props;
  const [questionValues, setQuestionValues] = useState([]);
  useEffect(() => {
    setQuestionValues(values);
  }, [values]);

  return (
    <>
      {map(questions, (question, idx) => {
        switch (question.element) {
          case "textarea":
            return (
              <FormControl
                id={"question-form-control-" + idx}
                component="fieldset"
                style={{ marginTop: "20px", width: "100%" }}
                required={Boolean(question.required)}
              >
                <FormLabel component="legend">
                  {question.isRequired ? question.label + "*" : question.label}
                </FormLabel>
                <TextareaAutosize
                  label={question.label}
                  rowsMax={8}
                  rowsMin={4}
                  readOnly={false}
                  required={Boolean(question.required)}
                  value={questionValues[idx] ? questionValues[idx].value : null}
                  style={{
                    font: "inherit",
                    color: "inherit",
                    resize: "none",
                    background: "transparent",
                    padding: "10px",
                    marginTop: "5px",
                    borderRadius: "5px",
                  }}
                  onChange={(e) => {
                    changeValue(question.id, e.target.value);
                  }}
                ></TextareaAutosize>
              </FormControl>
            );
          case "radio":
            return (
              <FormControl
                id={"question-form-control-" + idx}
                component="fieldset"
                required={Boolean(question.required)}
                style={{ marginTop: "20px", display: "block" }}
              >
                <FormLabel component="legend">{question.label}</FormLabel>
                <RadioGroup
                  aria-label={question.label}
                  name={question.label}
                  value={questionValues[idx] ? questionValues[idx].value : null}
                  onChange={(e) => {
                    changeValue(question.id, e.target.value);
                  }}
                >
                  {map(question.options, (option) => {
                    return (
                      <FormControlLabel
                        value={option.value}
                        control={<Radio />}
                        label={option.label}
                      />
                    );
                  })}
                </RadioGroup>
              </FormControl>
            );
          case "select":
            return (
              <FormControl
                fullWidth
                variant="outlined"
                required={Boolean(question.required)}
                style={{ marginTop: "20px", display: "block" }}
              >
                <FormLabel
                  role="presentation"
                  htmlFor={"question-select-input-" + idx}
                >
                  {question.label}
                </FormLabel>
                <Select
                  value={
                    questionValues[idx]
                      ? questionValues[idx].value.toString()
                      : null
                  }
                  onChange={(e) => {
                    changeValue(question.id, e.target.value);
                  }}
                  inputProps={{
                    name: question.label,
                    id: "question-select-input-" + idx,
                  }}
                >
                  {map(question.options, (option) => {
                    return (
                      <MenuItem value={option.value.toString()}>
                        {option.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            );
          case "text":
          default:
            return (
              <TextField
                id={"question-text-control-" + idx}
                required={question.required}
                label={question.label}
                fullWidth
                variant="outlined"
                value={questionValues[idx] ? questionValues[idx].value : null}
                style={{ marginTop: "20px" }}
                onChange={(e) => {
                  changeValue(question.id, e.target.value);
                }}
              />
            );
            break;
        }
      })}
    </>
  );
};

export default ApplicationQuestions;
