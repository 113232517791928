import merge from "lodash/merge";
import fetch from "cross-fetch";

import { getServiceBasePath } from "./string_utility";
import { removeToken } from "../../features/auth/states/auth_slice";

const baseURL = getServiceBasePath("auth");

/**
 * Get endpoint url
 *
 * @param {string} path
 * @param {boolean} useFullPathFlag
 * @return {string}
 */
function apiEndpoint(path, useFullPathFlag) {
  if (useFullPathFlag) {
    return path;
  }
  return `${baseURL}/${path}`;
}

/**
 * Get request headers
 *
 * @param {boolean} ignoreContentType
 * @return {object}
 */
function headers(ignoreContentType) {
  const token = sessionStorage.getItem("ma-session")
    ? `Bearer ${sessionStorage.getItem("ma-session")}`
    : "";
  const defaultHeaders = {
    Accept: "application/json",
    Authorization: token,
  };
  if (ignoreContentType) {
    return defaultHeaders;
  }
  return Object.assign(
    {
      "Content-Type": "application/json",
    },
    defaultHeaders
  );
}

/**
 * Execute fetch request
 *
 * @param {object} thunkAPI
 * @param {string} url
 * @param {object} opt
 * @return {object} response
 */
export async function callApi(thunkAPI, endpoint, opt = {}) {
  const ignoreContentType = opt.ignoreContentType;
  const fullEndpoint = opt.fullEndpoint;
  const defaultOpt = {
    endpoint: apiEndpoint(endpoint, fullEndpoint),
    method: "GET",
    headers: headers(ignoreContentType),
    formatJson: true,
    credentials: 'include'
  };
  let options = merge(defaultOpt, opt);
  try {
    const response = await fetch(options.endpoint, options);
    if (response.status === 401) {
      thunkAPI.dispatch(removeToken());
    }
    let data;
    if (options.formatJson) {
      data = await response.clone().json();
    } else {
      data = await response.clone().text();
    }
    if (response.ok) {
      return data;
    } else {
      return thunkAPI.rejectWithValue(data);
    }
    /*
    fetch(options.endpoint, options)
      .then((res) => {
        console.log(res);
        if (res.status === 401) {
        }
        if (options.formatJson) {
          return res.clone().json();
        } else {
          return res.clone().text();
        }
      })
      .then((user) => {
        console.log(user);
      })
      .catch((err) => {
        console.error(err);
      });*/
  } catch (error) {
    //thunkAPI.dispatch(addError(error.message));
    return thunkAPI.rejectWithValue(error.message);
  }
}
