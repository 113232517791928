import React, { useState } from "react";
import PropTypes from "prop-types";
import MainNav from "../../features/nav/main_nav";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import { getUrlParameterByName } from "../../common/util/string_utility";

export const logoutApp = (company) => {
  const loginType =
    window.location.href.indexOf("internal") >= 0 ? "internal" : "external";
  sessionStorage.clear();
  //window.location.href = `/${loginType}/home?company=${company}`;
  switch (company.toLowerCase()) {
    
    case "ahold e-commerce sales company":
      case "ahold+e-commerce+sales+company":
      window.location.href = 'https://aholdecommercesales.careerswithus.com/'
      break;
    case "peapod digital labs llc":
      window.location.href = 'https://peapoddigitallabs.careerswithus.com/'
      break;
    case "ahold delhaize corp support llc":
      case "ahold+delhaize+corp+support+llc":
      window.location.href = 'https://aholddelhaizeusacorporatesupport.careerswithus.com/'
      break;
      case "giant+delivery+llc":
    case "giant delivery llc":
      window.location.href = 'https://giantdelivers.careerswithus.com/'
      break;
    case "adusa+distribution,+llc":
    case "adusa distribution, llc":
      window.location.href = 'https://ADUSADistribution.careerswithus.com/'
      break;
    case "adusa transportation, llc":
      case "adusa+transportation,+llc":
      window.location.href = 'https://ADUSATransportation.careerswithus.com/'
      break;
      case "adusa+procurement,+llc":
    case "adusa procurement, llc":
      window.location.href = 'https://ADUSAProcurement.careerswithus.com/'
      break;
    case "adusa supply chain services, llc":
    case "adusa supply chain, llc":
      window.location.href = 'https://ADUSASupplyChainServices.careerswithus.com/'
      break;
    case "food lion, llc":
      case "food+lion,+llc":
      window.location.href = 'https://FoodLion.careerswithus.com/'
      break;
      case "hannaford bros. co., llc":
        case "martin's foods of south burlington, llc":
        case "victory distributors, llc":
        case "hannaford+bros.+co.,+llc":
        case "martin's+foods+of+south+burlington,+llc":
        case "victory+distributors,+llc":

      window.location.href = 'https://Hannaford.careerswithus.com/'
      break;
      case "ahold delhaize usa":
      case "ahold delhaize usa, inc.":
      case "guiding star licensing company":
      case "ahold delhaize usa services, llc":
        case "ahold+delhaize+usa":
          case "ahold+delhaize+usa,+inc.":
          case "guiding+star+licensing+company":
          case "ahold+delhaize+usa+services,+llc":
      window.location.href = 'https://AholdDelhaizeUSA.careerswithus.com/'
      break;
    case "retail business services llc" :
      window.location.href = 'https://RetailBusinessServices.careerswithus.com/'
      break;
    case "the+stop+and+shop+supermarket+company+llc":
    case "the stop and shop supermarket company llc":
      window.location.href = 'https://stopandshop.careerswithus.com/'
      break;
      case "the+giant+company+llc":
    case "the giant company llc":
      window.location.href = 'https://thegiantcompany.careerswithus.com/'
      break;
    case "giant of maryland llc":
    case "giant+of+maryland+llc":
      window.location.href = 'https://giantfood.careerswithus.com/'
      break;
    default:
      window.location.href = 'https://giantdelivers.careerswithus.com/'
      break;
  }
};

const App = (props) => {
  const jobId = getUrlParameterByName("jobid");
  const company = getUrlParameterByName("company");
  const [openLogoutDialog, setOpenLogoutDialoge] = useState(false);
  if (!company) {
    return (
      <Typography
        style={{
          flexGrow: 1,
          textAlign: "center",
          padding: "10px",
        }}
      >
        Invalid request
      </Typography>
    );
  }
  return (
    <>
      <MainNav jobId={jobId} />
      <AppBar
        position="static"
        style={{ top: "100px", justifyContent: "center" }}
      >
        <Toolbar>
          <Typography
            style={{
              flexGrow: 1,
              textAlign: "center",
              padding: "10px",
              textTransform: "uppercase",
            }}
          >
            {props.title ? props.title : "First Time Applicant?"}
          </Typography>
          {sessionStorage.getItem("appvault-login") === "true" && (
            <Button
              color="inherit"
              onClick={(e) => {
                setOpenLogoutDialoge(true);
              }}
            >
              Logout
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Dialog fullWidth={true} maxWidth={"sm"} open={openLogoutDialog}>
        <DialogContent>
          <DialogContentText>
            <strong>Are you going to log out?</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            color="secondary"
            variant="contained"
            onClick={(e) => {
              logoutApp(company);
            }}
          >
            Logout
          </Button>
          <Button
            color="primary"
            onClick={(e) => {
              setOpenLogoutDialoge(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {props.children}
    </>
  );
};

export default App;

App.propTypes = {
  children: PropTypes.node,
};
