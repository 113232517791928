import React from "react";
import MenuItem from "@material-ui/core/MenuItem";

export const getShiftOptions = (company) => {
  if (!company) return null;
  if (process.env.REACT_APP_APP_COMPANY.toLowerCase() !== "addev") {
    return [
      <MenuItem value="">No Selection</MenuItem>,
      <MenuItem value="74696">Any time</MenuItem>,
      <MenuItem value="74697">Day</MenuItem>,
      <MenuItem value="74698">Day and/or Evening</MenuItem>,
      <MenuItem value="74699">Day and/or Night Shift</MenuItem>,
      <MenuItem value="74700">Evening</MenuItem>,
      <MenuItem value="74701">Evening and/or Night Shift</MenuItem>,
      <MenuItem value="74702">Night Shift</MenuItem>,
      <MenuItem value="74703">Not available on this day</MenuItem>,
    ];
  }
  return [
    <MenuItem value="">No Selection</MenuItem>,
    <MenuItem value="1405">Any time</MenuItem>,
    <MenuItem value="1402">Day</MenuItem>,
    <MenuItem value="1406">Day and/or Evening</MenuItem>,
    <MenuItem value="1407">Day and/or Night Shift</MenuItem>,
    <MenuItem value="1403">Evening</MenuItem>,
    <MenuItem value="1408">Evening and/or Night Shift</MenuItem>,
    <MenuItem value="1404">Night Shift</MenuItem>,
    <MenuItem value="1409">Not available on this day</MenuItem>,
  ];
};

export const getBooleanOptions = (company) => {
  if (!company) return null;
  if (process.env.REACT_APP_APP_COMPANY.toLowerCase() !== "addev") {
    return [
      <MenuItem value=""> </MenuItem>,
      <MenuItem value="781">Yes</MenuItem>,
      <MenuItem value="782">No</MenuItem>,
    ];
  }
  return [
    <MenuItem value=""> </MenuItem>,
    <MenuItem value="468">Yes</MenuItem>,
    <MenuItem value="467">No</MenuItem>,
  ];
};

export const checkBoolean = (val) => {
  return val && (val === "468" || val === "781");
};

export const getAcceptabelOptions = (company) => {
  if (!company) return null;
  if (process.env.REACT_APP_APP_COMPANY.toLowerCase() !== "addev") {
    return [
      <MenuItem value="">No Selection</MenuItem>,
      <MenuItem value="76644">No</MenuItem>,
      <MenuItem value="76645">Not Applicable</MenuItem>,
      <MenuItem value="76643">Yes</MenuItem>,
    ];
  }
  return [
    <MenuItem value="">No Selection</MenuItem>,
    <MenuItem value="92191">No</MenuItem>,
    <MenuItem value="92192">Not Applicable</MenuItem>,
    <MenuItem value="92190">Yes</MenuItem>,
  ];
};

export const getLearnOppotunityOptions = (company) => {
  if (!company) return null;
  if (process.env.REACT_APP_APP_COMPANY.toLowerCase() !== "addev") {
    return [
      <MenuItem value="" selected="">No Selection</MenuItem>,
      <MenuItem value="74372">AARP</MenuItem>,
      <MenuItem value="2679">CareerBuilder</MenuItem>,
      <MenuItem value="2669">Company Associate</MenuItem>,
      <MenuItem value="2670">Company Website</MenuItem>,
      <MenuItem value="74373">Craigslist</MenuItem>,
      <MenuItem value="74374">DICE</MenuItem>,
      <MenuItem value="2675">Glassdoor</MenuItem>,
      <MenuItem value="2677">Indeed</MenuItem>,
      <MenuItem value="2672">Job Fair / Campus Recruiting Event</MenuItem>,
      <MenuItem value="2678">LinkedIn</MenuItem>,
      <MenuItem value="2676">Monster.com</MenuItem>,
      <MenuItem value="74375">Organized Network Event</MenuItem>,
      <MenuItem value="2681">Other Advertisement (Billboard, flyer, newspaper, etc.)</MenuItem>,
      <MenuItem value="2680">Other Internet Sources</MenuItem>,
      <MenuItem value="74376">Professional Association</MenuItem>,
      <MenuItem value="2673">Radio Ad</MenuItem>,
      <MenuItem value="74377">Referral</MenuItem>,
      <MenuItem value="2671">Store Signage / In-store Announcement</MenuItem>,
      <MenuItem value="2674">TV Ad</MenuItem>,
      <MenuItem value="2682">Word of Mouth</MenuItem>,
    ];
  }
  return [
    <MenuItem value="">No Selection</MenuItem>,
    <MenuItem value="90757">AARP</MenuItem>,
    <MenuItem value="3372">CareerBuilder</MenuItem>,
    <MenuItem value="3362">Company Associate</MenuItem>,
    <MenuItem value="3363">Company Website</MenuItem>,
    <MenuItem value="90758">Craigslist</MenuItem>,
    <MenuItem value="90759">DICE</MenuItem>,
    <MenuItem value="3368">Glassdoor</MenuItem>,
    <MenuItem value="3370">Indeed</MenuItem>,
    <MenuItem value="3365">Job Fair / Campus Recruiting Event</MenuItem>,
    <MenuItem value="3371">LinkedIn</MenuItem>,
    <MenuItem value="3369">Monster.com</MenuItem>,
    <MenuItem value="90760">Organized Network Event</MenuItem>,
    <MenuItem value="3374">
      Other Advertisement (Billboard, flyer, newspaper, etc.)
    </MenuItem>,
    <MenuItem value="3373">Other Internet Sources</MenuItem>,
    <MenuItem value="92485">Peapod Digital Lab - Email</MenuItem>,
    <MenuItem value="90761">Professional Association</MenuItem>,
    <MenuItem value="3366">Radio Ad</MenuItem>,
    <MenuItem value="90762">Referral</MenuItem>,
    <MenuItem value="3364">, Store Signage / In-store Announcement</MenuItem>,
    <MenuItem value="3367">TV Ad</MenuItem>,
    <MenuItem value="3375">Word of Mouth</MenuItem>,
  ];
};

export const getRaceOptions = (company) => {
  if (!company) return null;
  if (process.env.REACT_APP_APP_COMPANY.toLowerCase() !== "addev") {
    return [
      <MenuItem value="">No Selection</MenuItem>,
      <MenuItem value="74249">Asian</MenuItem>,
      <MenuItem value="74247">American Indian/Alaskan Native</MenuItem>,
      <MenuItem value="74250">Black or African American</MenuItem>,
      <MenuItem value="74252">Hispanic or Latino</MenuItem>,
      <MenuItem value="74248">
        Native Hawaiian/Other Pacific Islander
      </MenuItem>,
      <MenuItem value="74251">White</MenuItem>,
      <MenuItem value="74254">Two or More Races</MenuItem>,
      <MenuItem value="74253">Choose Not to Disclose</MenuItem>,
    ];
  }
  return [
    <MenuItem value="">No Selection</MenuItem>,
    <MenuItem value="3410">Asian</MenuItem>,
    <MenuItem value="3409">American Indian/Alaskan Native</MenuItem>,
    <MenuItem value="3411">Black or African American</MenuItem>,
    <MenuItem value="3414">Hispanic or Latino</MenuItem>,
    <MenuItem value="3412">Native Hawaiian/Other Pacific Islander</MenuItem>,
    <MenuItem value="3413">White</MenuItem>,
    <MenuItem value="3416">Two or More Races</MenuItem>,
    <MenuItem value="3415">Choose Not to Disclose</MenuItem>,
  ];
};

export const getCommunicationOptions = (company) => {
  if (!company) return null;
  if (process.env.REACT_APP_APP_COMPANY.toLowerCase() !== "addev") {
    return [
      <MenuItem value="">No Selection</MenuItem>,
      <MenuItem value="74385">Email</MenuItem>,
      <MenuItem value="74387">Home Phone</MenuItem>,
      <MenuItem value="74386">Mobile Phone - Call</MenuItem>,
      <MenuItem value="76640">Mobile Phone - SMS</MenuItem>,
    ];
  }
  return [
    <MenuItem value="">No Selection</MenuItem>,
    <MenuItem value="3436">Email</MenuItem>,
    <MenuItem value="3438">Home Phone</MenuItem>,
    <MenuItem value="3437">Mobile Phone - Call</MenuItem>,
    <MenuItem value="92188">Mobile Phone - SMS</MenuItem>,
  ];
};

export const getSuffixOptions = (company) => {
  if (!company) return null;
  if (process.env.REACT_APP_APP_COMPANY.toLowerCase() !== "addev") {
    return [
      <MenuItem value="">No Selection</MenuItem>,
      <MenuItem value="1141">I</MenuItem>,
      <MenuItem value="1142">II</MenuItem>,
      <MenuItem value="556">III</MenuItem>,
      <MenuItem value="1143">IV</MenuItem>,
      <MenuItem value="557">Jr</MenuItem>,
      <MenuItem value="558">Sr</MenuItem>,
      <MenuItem value="1144">V</MenuItem>,
    ];
  }
  return [
    <MenuItem value="">No Selection</MenuItem>,
    <MenuItem value="2581">I</MenuItem>,
    <MenuItem value="2582">II</MenuItem>,
    <MenuItem value="466">III</MenuItem>,
    <MenuItem value="2583">IV</MenuItem>,
    <MenuItem value="464">Jr</MenuItem>,
    <MenuItem value="465">Sr</MenuItem>,
    <MenuItem value="2584">V</MenuItem>,
  ];
};
