import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getServiceBasePath } from "../../../common/util/string_utility";
import { callApi } from "../../../common/util/fetch";
import { isArray } from "lodash";
import { logoutApp } from "../../../app/components/app";

export const userLogin = createAsyncThunk(
  "external/login",
  async (params, thunkAPI) => {
    const baseUrl = getServiceBasePath("auth");
    return await callApi(thunkAPI, `${baseUrl}/login/external`, {
      method: "POST",
      body: JSON.stringify(params),
      fullEndpoint: true,
      // credentials: "include",
    });
  }
);

export const resetPassword = createAsyncThunk(
  "external/forgot-password",
  async (params, thunkAPI) => {
    const baseUrl = getServiceBasePath("auth");
    return await callApi(thunkAPI, `${baseUrl}/forgot-password/external`, {
      method: "POST",
      body: JSON.stringify(params),
      fullEndpoint: true,
      // credentials: "include",
    });
  }
);

export const initResetPassword = createAsyncThunk(
  "external/init-forgot-password",
  async (params, thunkAPI) => {
    const baseUrl = getServiceBasePath("auth");
    return await callApi(thunkAPI, `${baseUrl}/forgot-password/external`, {
      method: "get",
      fullEndpoint: true,
      // credentials: "include",
    });
  }
);

export const getUserSession = createAsyncThunk(
  "external/get-user-session",
  async (params, thunkAPI) => {
    const apiUrl = getServiceBasePath("session");
    return await callApi(thunkAPI, `${apiUrl}`, {
      method: "post",
      fullEndpoint: true,
    });
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    session: undefined,
    errorMessage: undefined,
    onLoading: undefined,
    success: undefined,
    onLoadingSession: undefined,
    loadingSessionSuccess: undefined,
    readyForgotpassword: undefined,
  },
  reducers: {
    removeToken: (state, action) => {
      state.session = undefined;
      window.sessionStorage.removeItem("ma-session");
      logoutApp();
      return state;
    },
  },
  extraReducers: {
    [initResetPassword.fulfilled]: (state, action) => {
      state.readyForgotpassword = true;
    },
    [userLogin.pending]: (state, action) => {
      state.session = undefined;
      state.errorMessage = undefined;
      state.onLoading = true;
      state.success = undefined;
    },
    [userLogin.fulfilled]: (state, action) => {
      state.errorMessage = undefined;
      state.session = action.payload.session;
      state.onLoading = undefined;
      state.success = true;
      sessionStorage.setItem("appvault-login", true);
    },
    [userLogin.rejected]: (state, action) => {
      state.onLoading = undefined;
      state.success = false;
      if (action.payload && isArray(action.payload.errors)) {
        state.errorMessage = action.payload.errors[0].message;
      } else {
        state.errorMessage =
          "Sorry, we are unable to log you in. Please try later";
      }
    },
    [resetPassword.pending]: (state, action) => {
      state.session = undefined;
      state.errorMessage = undefined;
      state.onLoading = true;
      state.success = undefined;
    },
    [resetPassword.fulfilled]: (state, action) => {
      state.errorMessage = undefined;
      state.session = action.payload.session;
      state.onLoading = undefined;
      state.success = true;
    },
    [resetPassword.rejected]: (state, action) => {
      state.onLoading = undefined;
      state.success = false;
      if (action.payload && isArray(action.payload.errors)) {
        state.errorMessage = action.payload.errors[0].message;
      } else {
        state.errorMessage =
          "Sorry, we are unable to reset your password. Please try later";
      }
    },
    [getUserSession.pending]: (state, action) => {
      state.session = undefined;
      state.loadingSessionSuccess = undefined;
      state.onLoadingSession = true;
      window.sessionStorage.removeItem("ma-session");
    },
    [getUserSession.fulfilled]: (state, action) => {
      state.onLoadingSession = undefined;
      state.loadingSessionSuccess = true;
      state.session = action.payload.session;
      window.sessionStorage.setItem("ma-session", action.payload.token);
    },
    [getUserSession.rejected]: (state, action) => {
      state.session = undefined;
      state.loadingSessionSuccess = false;
      state.onLoadingSession = undefined;
      window.sessionStorage.removeItem("ma-session");
    },
  },
});

export const { removeToken } = authSlice.actions;

export const selectAuth = (state) => state.auth;

export default authSlice.reducer;
