import React from "react";
import MenuItem from "@material-ui/core/MenuItem";

export const getShiftOptions = (company) => {
  if (!company) return null;
  if (process.env.REACT_APP_APP_COMPANY.toLowerCase() !== "addev") {
    return [
      <MenuItem value="">No Selection</MenuItem>,
      <MenuItem value="Any time">Any time</MenuItem>,
      <MenuItem value="Day">Day</MenuItem>,
      <MenuItem value="Day and/or Evening">Day and/or Evening</MenuItem>,
      <MenuItem value="Day and/or Night Shift">Day and/or Night Shift</MenuItem>,
      <MenuItem value="Evening">Evening</MenuItem>,
      <MenuItem value="Evening and/or Night Shift">Evening and/or Night Shift</MenuItem>,
      <MenuItem value="Night Shift">Night Shift</MenuItem>,
      <MenuItem value="Not available on this day">Not available on this day</MenuItem>,
    ];
  }
  return [
    <MenuItem value="">No Selection</MenuItem>,
      <MenuItem value="Any time">Any time</MenuItem>,
      <MenuItem value="Day">Day</MenuItem>,
      <MenuItem value="Day and/or Evening">Day and/or Evening</MenuItem>,
      <MenuItem value="Day and/or Night Shift">Day and/or Night Shift</MenuItem>,
      <MenuItem value="Evening">Evening</MenuItem>,
      <MenuItem value="Evening and/or Night Shift">Evening and/or Night Shift</MenuItem>,
      <MenuItem value="Night Shift">Night Shift</MenuItem>,
      <MenuItem value="Not available on this day">Not available on this day</MenuItem>,
  ];
};

export const getBooleanOptions = (company) => {
  if (!company) return null;
  if (process.env.REACT_APP_APP_COMPANY.toLowerCase() !== "addev") {
    return [
      <MenuItem value=""> </MenuItem>,
      <MenuItem value="Yes">Yes</MenuItem>,
      <MenuItem value="No">No</MenuItem>,
    ];
  }
  return [
    <MenuItem value=""> </MenuItem>,
      <MenuItem value="Yes">Yes</MenuItem>,
      <MenuItem value="No">No</MenuItem>,
  ];
};

export const checkBoolean = (val) => {
  return val && (val === "Yes");
};

export const getAcceptabelOptions = (company) => {
  if (!company) return null;
  if (process.env.REACT_APP_APP_COMPANY.toLowerCase() !== "addev") {
    return [
      <MenuItem value="">No Selection</MenuItem>,
      <MenuItem value="No">No</MenuItem>,
      <MenuItem value="Not Applicable">Not Applicable</MenuItem>,
      <MenuItem value="Yes">Yes</MenuItem>,
    ];
  }
  return [
    <MenuItem value="">No Selection</MenuItem>,
      <MenuItem value="No">No</MenuItem>,
      <MenuItem value="Not Applicable">Not Applicable</MenuItem>,
      <MenuItem value="Yes">Yes</MenuItem>,
  ];
};

export const getLearnOppotunityOptions = (company) => {
  if (!company) return null;
  if (process.env.REACT_APP_APP_COMPANY.toLowerCase() !== "addev") {
    return [
      <MenuItem value="" selected="">No Selection</MenuItem>,
      <MenuItem value="AARP">AARP</MenuItem>,
      <MenuItem value="CareerBuilder">CareerBuilder</MenuItem>,
      <MenuItem value="Company Associate">Company Associate</MenuItem>,
      <MenuItem value="Company Website">Company Website</MenuItem>,
      <MenuItem value="Craigslist">Craigslist</MenuItem>,
      <MenuItem value="DICE">DICE</MenuItem>,
      <MenuItem value="Glassdoor">Glassdoor</MenuItem>,
      <MenuItem value="Indeed">Indeed</MenuItem>,
      <MenuItem value="Job Fair / Campus Recruiting Event">Job Fair / Campus Recruiting Event</MenuItem>,
      <MenuItem value="LinkedIn">LinkedIn</MenuItem>,
      <MenuItem value="Monster.com">Monster.com</MenuItem>,
      <MenuItem value="Organized Network Event">Organized Network Event</MenuItem>,
      <MenuItem value="Other Advertisement (Billboard, flyer, newspaper, etc.)">Other Advertisement (Billboard, flyer, newspaper, etc.)</MenuItem>,
      <MenuItem value="Other Internet Sources">Other Internet Sources</MenuItem>,
      <MenuItem value="Professional Association">Professional Association</MenuItem>,
      <MenuItem value="Radio Ad">Radio Ad</MenuItem>,
      <MenuItem value="Referral">Referral</MenuItem>,
      <MenuItem value="Store Signage / In-store Announcement">Store Signage / In-store Announcement</MenuItem>,
      <MenuItem value="TV Ad">TV Ad</MenuItem>,
      <MenuItem value="Word of Mouth">Word of Mouth</MenuItem>,
    ];
  }
  return [
    <MenuItem value="" selected="">No Selection</MenuItem>,
      <MenuItem value="AARP">AARP</MenuItem>,
      <MenuItem value="CareerBuilder">CareerBuilder</MenuItem>,
      <MenuItem value="Company Associate">Company Associate</MenuItem>,
      <MenuItem value="Company Website">Company Website</MenuItem>,
      <MenuItem value="Craigslist">Craigslist</MenuItem>,
      <MenuItem value="DICE">DICE</MenuItem>,
      <MenuItem value="Glassdoor">Glassdoor</MenuItem>,
      <MenuItem value="Indeed">Indeed</MenuItem>,
      <MenuItem value="Job Fair / Campus Recruiting Event">Job Fair / Campus Recruiting Event</MenuItem>,
      <MenuItem value="LinkedIn">LinkedIn</MenuItem>,
      <MenuItem value="Monster.com">Monster.com</MenuItem>,
      <MenuItem value="Organized Network Event">Organized Network Event</MenuItem>,
      <MenuItem value="Other Advertisement (Billboard, flyer, newspaper, etc.)">Other Advertisement (Billboard, flyer, newspaper, etc.)</MenuItem>,
      <MenuItem value="Other Internet Sources">Other Internet Sources</MenuItem>,
      <MenuItem value="Professional Association">Professional Association</MenuItem>,
      <MenuItem value="Radio Ad">Radio Ad</MenuItem>,
      <MenuItem value="Referral">Referral</MenuItem>,
      <MenuItem value="Store Signage / In-store Announcement">Store Signage / In-store Announcement</MenuItem>,
      <MenuItem value="TV Ad">TV Ad</MenuItem>,
      <MenuItem value="Word of Mouth">Word of Mouth</MenuItem>,
  ];
};

export const getRaceOptions = (company) => {
  if (!company) return null;
  if (process.env.REACT_APP_APP_COMPANY.toLowerCase() !== "addev") {
    return [
      <MenuItem value="">No Selection</MenuItem>,
      <MenuItem value="Asian">Asian</MenuItem>,
      <MenuItem value="American Indian/Alaskan Native">American Indian/Alaskan Native</MenuItem>,
      <MenuItem value="Black or African American">Black or African American</MenuItem>,
      <MenuItem value="Hispanic or Latino">Hispanic or Latino</MenuItem>,
      <MenuItem value="Native Hawaiian/Other Pacific Islander">
        Native Hawaiian/Other Pacific Islander
      </MenuItem>,
      <MenuItem value="White">White</MenuItem>,
      <MenuItem value="Two or More Races">Two or More Races</MenuItem>,
      <MenuItem value="Choose Not to Disclose">Choose Not to Disclose</MenuItem>,
    ];
  }
  return [
    <MenuItem value="">No Selection</MenuItem>,
      <MenuItem value="Asian">Asian</MenuItem>,
      <MenuItem value="American Indian/Alaskan Native">American Indian/Alaskan Native</MenuItem>,
      <MenuItem value="Black or African American">Black or African American</MenuItem>,
      <MenuItem value="Hispanic or Latino">Hispanic or Latino</MenuItem>,
      <MenuItem value="Native Hawaiian/Other Pacific Islander">
        Native Hawaiian/Other Pacific Islander
      </MenuItem>,
      <MenuItem value="White">White</MenuItem>,
      <MenuItem value="Two or More Races">Two or More Races</MenuItem>,
      <MenuItem value="Choose Not to Disclose">Choose Not to Disclose</MenuItem>,
  ];
};

export const getCommunicationOptions = (company) => {
  if (!company) return null;
  if (process.env.REACT_APP_APP_COMPANY.toLowerCase() !== "addev") {
    return [
      <MenuItem value="">No Selection</MenuItem>,
      <MenuItem value="Email">Email</MenuItem>,
      <MenuItem value="Home Phone">Home Phone</MenuItem>,
      <MenuItem value="Mobile Phone - Call">Mobile Phone - Call</MenuItem>,
      <MenuItem value="Mobile Phone - SMS">Mobile Phone - SMS</MenuItem>,
    ];
  }
  return [
    <MenuItem value="">No Selection</MenuItem>,
      <MenuItem value="Email">Email</MenuItem>,
      <MenuItem value="Home Phone">Home Phone</MenuItem>,
      <MenuItem value="Mobile Phone - Call">Mobile Phone - Call</MenuItem>,
      <MenuItem value="Mobile Phone - SMS">Mobile Phone - SMS</MenuItem>,
  ];
};

export const getSuffixOptions = (company) => {
  if (!company) return null;
  if (process.env.REACT_APP_APP_COMPANY.toLowerCase() !== "addev") {
    return [
      <MenuItem value="">No Selection</MenuItem>,
      <MenuItem value="I">I</MenuItem>,
      <MenuItem value="II">II</MenuItem>,
      <MenuItem value="III">III</MenuItem>,
      <MenuItem value="IV">IV</MenuItem>,
      <MenuItem value="Jr">Jr</MenuItem>,
      <MenuItem value="Sr">Sr</MenuItem>,
      <MenuItem value="V">V</MenuItem>,
    ];
  }
  return [
    <MenuItem value="">No Selection</MenuItem>,
      <MenuItem value="I">I</MenuItem>,
      <MenuItem value="II">II</MenuItem>,
      <MenuItem value="III">III</MenuItem>,
      <MenuItem value="IV">IV</MenuItem>,
      <MenuItem value="Jr">Jr</MenuItem>,
      <MenuItem value="Sr">Sr</MenuItem>,
      <MenuItem value="V">V</MenuItem>,
  ];
};
