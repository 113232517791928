/*const theme = {
  spacing: 4,
  palette: {
    primary: "#007bff",
  },
};*/
import { createMuiTheme } from "@material-ui/core/styles";

const PepodTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#5EA000",
      light: "#8bed00",
      dark: "#406d00",
    },
    secondary: {
      main: "#7cd300",
    },
    default: {
      main: "#1976d2",
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Avenir"',
      '"Nunito"',
      "Arial",
      "sans-serif",
    ].join(","),
    lineHeight: "1",
  },
  overrides: {
    MuiDropzoneAreaRoot: {
      minHeight: "0",
    },
    MuiButtonGroup: {
      root: {
        background: "#FFF",
      },
    },
    MuiInputLabel: {
      color: "grey",
      root: {
        "&$focused": {
          color: "grey",
        },
      },
    },
    MuiIconButton: {
      root: {
        fontSize: "1rem",
      },
    },
    MuiRadio: {
      colorSecondary: {
        "&$checked": {
          color: "inherit",
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        height: "50px",
        fontSize: "16px",
      },
      containedSecondary: {
        color: "#FFF",
        backgroundColor: "#406d00",
        textTransform: "none",
      },
    },
    MuiMobileStepper: {
      root: {
        background: "#406d00",
        fontSize: "16px",
      },
      dotActive: { backgroundColor: "#FFF" },
    },
    MuiOutlinedInput: {
      root: {
        background: "transparent",
        position: "relative",
        "& $notchedOutline": {
          borderColor: "grey",
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            borderColor: "grey",
          },
        },
        "&$focused $notchedOutline": {
          borderColor: "grey",
          borderWidth: 2,
        },
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "currentColor",
          opacity: 0.54,
        },
      },
    },
  },
});

const ADTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#09BF1E",
      light: "#8bed00",
      dark: "#406d00",
      contrastText: "#fff",
    },
    secondary: {
      main: "#00BB1E",
    },
    default: {
      main: "#1976d2",
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Avenir"',
      '"Nunito"',
      "Arial",
      "sans-serif",
    ].join(","),
    lineHeight: "1",
  },
  overrides: {
    MuiDropzoneAreaRoot: {
      minHeight: "0",
    },
    MuiButtonGroup: {
      root: {
        background: "#FFF",
      },
    },
    MuiInputLabel: {
      color: "grey",
      root: {
        "&$focused": {
          color: "grey",
        },
      },
    },
    MuiIconButton: {
      root: {
        fontSize: "1rem",
      },
    },
    MuiRadio: {
      colorSecondary: {
        "&$checked": {
          color: "inherit",
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        height: "50px",
        fontSize: "16px",
      },
      containedSecondary: {
        color: "#FFF",
        backgroundColor: "#00BB1E",
        textTransform: "none",
      },
    },
    MuiMobileStepper: {
      root: {
        background: "#00BB1E",
        fontSize: "16px",
      },
      dotActive: { backgroundColor: "#FFF" },
    },
    MuiOutlinedInput: {
      root: {
        background: "transparent",
        position: "relative",
        "& $notchedOutline": {
          borderColor: "grey",
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            borderColor: "grey",
          },
        },
        "&$focused $notchedOutline": {
          borderColor: "grey",
          borderWidth: 2,
        },
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "currentColor",
          opacity: 0.54,
        },
      },
    },
  },
});

const AesTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#0b6aba",
      light: "#7993cc",
      dark: "#004bb4",
    },
    secondary: {
      main: "#7993cc",
    },
    default: {
      main: "#1976d2",
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Avenir"',
      '"Nunito"',
      "Arial",
      "sans-serif",
    ].join(","),
    lineHeight: "1",
  },
  overrides: {
    MuiDropzoneAreaRoot: {
      minHeight: "0",
    },
    MuiButtonGroup: {
      root: {
        background: "#FFF",
      },
    },
    MuiInputLabel: {
      color: "grey",
      root: {
        "&$focused": {
          color: "grey",
        },
      },
    },
    MuiIconButton: {
      root: {
        fontSize: "1rem",
      },
    },
    MuiRadio: {
      colorSecondary: {
        "&$checked": {
          color: "inherit",
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        height: "50px",
        fontSize: "16px",
      },
      containedSecondary: {
        color: "#FFF",
        backgroundColor: "#7993cc",
        textTransform: "none",
      },
    },
    MuiMobileStepper: {
      root: {
        background: "#7993cc",
        fontSize: "16px",
      },
      dotActive: { backgroundColor: "#FFF" },
    },
    MuiOutlinedInput: {
      root: {
        background: "transparent",
        position: "relative",
        "& $notchedOutline": {
          borderColor: "grey",
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            borderColor: "grey",
          },
        },
        "&$focused $notchedOutline": {
          borderColor: "grey",
          borderWidth: 2,
        },
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "currentColor",
          opacity: 0.54,
        },
      },
    },
  },
});

const GDTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#702877",
      light: "#8bed00",
      dark: "#b409c5",
    },
    secondary: {
      main: "#b409c5",
    },
    default: {
      main: "#1976d2",
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Avenir"',
      '"Nunito"',
      "Arial",
      "sans-serif",
    ].join(","),
    lineHeight: "1",
  },
  overrides: {
    MuiDropzoneAreaRoot: {
      minHeight: "0",
    },
    MuiButtonGroup: {
      root: {
        background: "#FFF",
      },
    },
    MuiInputLabel: {
      color: "grey",
      root: {
        "&$focused": {
          color: "grey",
        },
      },
    },
    MuiIconButton: {
      root: {
        fontSize: "1rem",
      },
    },
    MuiRadio: {
      colorSecondary: {
        "&$checked": {
          color: "inherit",
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        height: "50px",
        fontSize: "16px",
      },
      containedSecondary: {
        color: "#FFF",
        backgroundColor: "#702877",
        textTransform: "none",
      },
    },
    MuiMobileStepper: {
      root: {
        background: "#702877",
        fontSize: "16px",
      },
      dotActive: { backgroundColor: "#FFF" },
    },
    MuiOutlinedInput: {
      root: {
        background: "transparent",
        position: "relative",
        "& $notchedOutline": {
          borderColor: "grey",
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            borderColor: "grey",
          },
        },
        "&$focused $notchedOutline": {
          borderColor: "grey",
          borderWidth: 2,
        },
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "currentColor",
          opacity: 0.54,
        },
      },
    },
  },
});
const SCTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#262C26",
      light: "#FFFFFF",
      dark: "#26AD4A",
    },
    secondary: {
      main: "#056868",
    },
    default: {
      main: "#262C26",
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Avenir"',
      '"Nunito"',
      "Arial",
      "sans-serif",
    ].join(","),
    lineHeight: "1",
  },
  overrides: {
    MuiDropzoneAreaRoot: {
      minHeight: "0",
    },
    MuiButtonGroup: {
      root: {
        background: "#FFF",
      },
    },
    MuiInputLabel: {
      color: "grey",
      root: {
        "&$focused": {
          color: "grey",
        },
      },
    },
    MuiIconButton: {
      root: {
        fontSize: "1rem",
      },
    },
    MuiRadio: {
      colorSecondary: {
        "&$checked": {
          color: "inherit",
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        height: "50px",
        fontSize: "16px",
      },
      containedSecondary: {
        color: "#FFF",
        backgroundColor: "#262C26",
        textTransform: "none",
      },
    },
    MuiMobileStepper: {
      root: {
        background: "#262C26",
        fontSize: "16px",
      },
      dotActive: { backgroundColor: "#FFF" },
    },
    MuiOutlinedInput: {
      root: {
        background: "transparent",
        position: "relative",
        "& $notchedOutline": {
          borderColor: "grey",
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            borderColor: "grey",
          },
        },
        "&$focused $notchedOutline": {
          borderColor: "grey",
          borderWidth: 2,
        },
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "currentColor",
          opacity: 0.54,
        },
      },
    },
  },
});
const FLTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#0756b1",
      light: "#ffffff",
      dark: "#000000",
    },
    secondary: {
      main: "#0756b1",
    },
    default: {
      main: "#0756b1",
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Avenir"',
      '"Nunito"',
      "Arial",
      "sans-serif",
    ].join(","),
    lineHeight: "1",
  },
  overrides: {
    MuiDropzoneAreaRoot: {
      minHeight: "0",
    },
    MuiButtonGroup: {
      root: {
        background: "#FFF",
      },
    },
    MuiInputLabel: {
      color: "grey",
      root: {
        "&$focused": {
          color: "grey",
        },
      },
    },
    MuiIconButton: {
      root: {
        fontSize: "1rem",
      },
    },
    MuiRadio: {
      colorSecondary: {
        "&$checked": {
          color: "inherit",
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        height: "50px",
        fontSize: "16px",
      },
      containedSecondary: {
        color: "#FFF",
        backgroundColor: "#0756b1",
        textTransform: "none",
      },
    },
    MuiMobileStepper: {
      root: {
        background: "#0756b1",
        fontSize: "16px",
      },
      dotActive: { backgroundColor: "#FFF" },
    },
    MuiOutlinedInput: {
      root: {
        background: "transparent",
        position: "relative",
        "& $notchedOutline": {
          borderColor: "grey",
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            borderColor: "grey",
          },
        },
        "&$focused $notchedOutline": {
          borderColor: "grey",
          borderWidth: 2,
        },
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "currentColor",
          opacity: 0.54,
        },
      },
    },
  },
});
const HATheme = createMuiTheme({
  palette: {
    primary: {
      main: "#DA291C",
      light: "#C0B8B0",
      dark: "#6F625A",
    },
    secondary: {
      main: "#B32317",
    },
    default: {
      main: "#DA291C",
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Avenir"',
      '"Nunito"',
      "Arial",
      "sans-serif",
    ].join(","),
    lineHeight: "1",
  },
  overrides: {
    MuiDropzoneAreaRoot: {
      minHeight: "0",
    },
    MuiButtonGroup: {
      root: {
        background: "#FFF",
      },
    },
    MuiInputLabel: {
      color: "grey",
      root: {
        "&$focused": {
          color: "grey",
        },
      },
    },
    MuiIconButton: {
      root: {
        fontSize: "1rem",
      },
    },
    MuiRadio: {
      colorSecondary: {
        "&$checked": {
          color: "inherit",
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        height: "50px",
        fontSize: "16px",
      },
      containedSecondary: {
        color: "#FFF",
        backgroundColor: "#DA291C",
        textTransform: "none",
      },
    },
    MuiMobileStepper: {
      root: {
        background: "#DA291C",
        fontSize: "16px",
      },
      dotActive: { backgroundColor: "#FFF" },
    },
    MuiOutlinedInput: {
      root: {
        background: "transparent",
        position: "relative",
        "& $notchedOutline": {
          borderColor: "grey",
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            borderColor: "grey",
          },
        },
        "&$focused $notchedOutline": {
          borderColor: "grey",
          borderWidth: 2,
        },
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "currentColor",
          opacity: 0.54,
        },
      },
    },
  },
});
const RBSTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#046768",
      light: "#2E3028",
      dark: "#046768",
    },
    secondary: {
      main: "#702076",
    },
    default: {
      main: "#046768",
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Avenir"',
      '"Nunito"',
      "Arial",
      "sans-serif",
    ].join(","),
    lineHeight: "1",
  },
  overrides: {
    MuiDropzoneAreaRoot: {
      minHeight: "0",
    },
    MuiButtonGroup: {
      root: {
        background: "#FFF",
      },
    },
    MuiInputLabel: {
      color: "grey",
      root: {
        "&$focused": {
          color: "grey",
        },
      },
    },
    MuiIconButton: {
      root: {
        fontSize: "1rem",
      },
    },
    MuiRadio: {
      colorSecondary: {
        "&$checked": {
          color: "inherit",
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        height: "50px",
        fontSize: "16px",
      },
      containedSecondary: {
        color: "#FFF",
        backgroundColor: "#046768",
        textTransform: "none",
      },
    },
    MuiMobileStepper: {
      root: {
        background: "#046768",
        fontSize: "16px",
      },
      dotActive: { backgroundColor: "#FFF" },
    },
    MuiOutlinedInput: {
      root: {
        background: "transparent",
        position: "relative",
        "& $notchedOutline": {
          borderColor: "grey",
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            borderColor: "grey",
          },
        },
        "&$focused $notchedOutline": {
          borderColor: "grey",
          borderWidth: 2,
        },
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "currentColor",
          opacity: 0.54,
        },
      },
    },
  },
});
const SNSTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#86C300",
      light: "#E7004C",
      dark: "#86C300",
    },
    secondary: {
      main: "#E7004C",
    },
    default: {
      main: "#86C300",
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Avenir"',
      '"Nunito"',
      "Arial",
      "sans-serif",
    ].join(","),
    lineHeight: "1",
  },
  overrides: {
    MuiDropzoneAreaRoot: {
      minHeight: "0",
    },
    MuiButtonGroup: {
      root: {
        background: "#FFF",
      },
    },
    MuiInputLabel: {
      color: "grey",
      root: {
        "&$focused": {
          color: "grey",
        },
      },
    },
    MuiIconButton: {
      root: {
        fontSize: "1rem",
      },
    },
    MuiRadio: {
      colorSecondary: {
        "&$checked": {
          color: "inherit",
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        height: "50px",
        fontSize: "16px",
      },
      containedSecondary: {
        color: "#FFF",
        backgroundColor: "#86C300",
        textTransform: "none",
      },
    },
    MuiMobileStepper: {
      root: {
        background: "#86C300",
        fontSize: "16px",
      },
      dotActive: { backgroundColor: "#FFF" },
    },
    MuiOutlinedInput: {
      root: {
        background: "transparent",
        position: "relative",
        "& $notchedOutline": {
          borderColor: "grey",
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            borderColor: "grey",
          },
        },
        "&$focused $notchedOutline": {
          borderColor: "grey",
          borderWidth: 2,
        },
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "currentColor",
          opacity: 0.54,
        },
      },
    },
  },
});
const GFTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#702076",
      light: "#f3f3f0",
      dark: "#702076",
    },
    secondary: {
      main: "#702076",
    },
    default: {
      main: "#702076",
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Avenir"',
      '"Nunito"',
      "Arial",
      "sans-serif",
    ].join(","),
    lineHeight: "1",
  },
  overrides: {
    MuiDropzoneAreaRoot: {
      minHeight: "0",
    },
    MuiButtonGroup: {
      root: {
        background: "#FFF",
      },
    },
    MuiInputLabel: {
      color: "grey",
      root: {
        "&$focused": {
          color: "grey",
        },
      },
    },
    MuiIconButton: {
      root: {
        fontSize: "1rem",
      },
    },
    MuiRadio: {
      colorSecondary: {
        "&$checked": {
          color: "inherit",
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        height: "50px",
        fontSize: "16px",
      },
      containedSecondary: {
        color: "#FFF",
        backgroundColor: "#702076",
        textTransform: "none",
      },
    },
    MuiMobileStepper: {
      root: {
        background: "#702076",
        fontSize: "16px",
      },
      dotActive: { backgroundColor: "#FFF" },
    },
    MuiOutlinedInput: {
      root: {
        background: "transparent",
        position: "relative",
        "& $notchedOutline": {
          borderColor: "grey",
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            borderColor: "grey",
          },
        },
        "&$focused $notchedOutline": {
          borderColor: "grey",
          borderWidth: 2,
        },
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "currentColor",
          opacity: 0.54,
        },
      },
    },
  },
});
const GCTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#E4002B",
      light: "#FFFFFF",
      dark: "#E4002B",
    },
    secondary: {
      main: "#E4002B",
    },
    default: {
      main: "#E4002B",
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Avenir"',
      '"Nunito"',
      "Arial",
      "sans-serif",
    ].join(","),
    lineHeight: "1",
  },
  overrides: {
    MuiDropzoneAreaRoot: {
      minHeight: "0",
    },
    MuiButtonGroup: {
      root: {
        background: "#FFF",
      },
    },
    MuiInputLabel: {
      color: "grey",
      root: {
        "&$focused": {
          color: "grey",
        },
      },
    },
    MuiIconButton: {
      root: {
        fontSize: "1rem",
      },
    },
    MuiRadio: {
      colorSecondary: {
        "&$checked": {
          color: "inherit",
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        height: "50px",
        fontSize: "16px",
      },
      containedSecondary: {
        color: "#FFF",
        backgroundColor: "#E4002B",
        textTransform: "none",
      },
    },
    MuiMobileStepper: {
      root: {
        background: "#E4002B",
        fontSize: "16px",
      },
      dotActive: { backgroundColor: "#FFF" },
    },
    MuiOutlinedInput: {
      root: {
        background: "transparent",
        position: "relative",
        "& $notchedOutline": {
          borderColor: "grey",
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            borderColor: "grey",
          },
        },
        "&$focused $notchedOutline": {
          borderColor: "grey",
          borderWidth: 2,
        },
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "currentColor",
          opacity: 0.54,
        },
      },
    },
  },
});
export const getTheme = (company) => {
  if (company) {
    switch (company.toLowerCase()) {
      case "ahold e-commerce sales company":
        case "ahold+e-commerce+sales+company":
        return AesTheme;
      case "peapod digital labs holdings llc":
      case "peapod digital labs llc":
        return PepodTheme;
      case "ahold delhaize corp support llc":
        case "ahold+delhaize+corp+support+llc":
        return ADTheme;
      case "giant delivery llc":
        case "giant+delivery+llc":
        return GDTheme;
        case "adusa+distribution,+llc":
      case "adusa distribution, llc":
        return SCTheme;
      case "adusa transportation, llc":
        case "adusa+transportation,+llc":
        return SCTheme;
      case "adusa procurement, llc":
        case "adusa+procurement,+llc":
        return SCTheme;
      case "adusa supply chain services, llc":
      case "adusa supply chain, llc":
        return SCTheme;
      case "food lion, llc":
        case "food+lion,+llc":
        return FLTheme;
        case "hannaford bros. co., llc":
          case "martin's foods of south burlington, llc":
          case "victory distributors, llc":
          case "hannaford+bros.+co.,+llc":
          case "martin's+foods+of+south+burlington,+llc":
          case "victory+distributors,+llc":
        return HATheme;
      case "ahold delhaize usa":
      case "ahold delhaize usa, inc.":
      case "guiding star licensing company":
      case "retail business services llc" :
        case "ahold+delhaize+usa":
          case "ahold+delhaize+usa,+inc.":
          case "guiding+star+licensing+company":
          case "ahold+delhaize+usa+services,+llc":
        return RBSTheme;
      case "the+stop+and+shop+supermarket+company+llc":
      case "the stop and shop supermarket company llc":
        return SNSTheme;
        case "the+giant+company+llc":
      case "the giant company llc":
        return GCTheme;
      case "giant of maryland llc":
        case "giant+of+maryland+llc":
        return GFTheme;
      case "aedev":
        return AesTheme;
      case "gddev":
        return GDTheme;
      case "addev":
        return ADTheme;
      case "pddev":
      default:
        return PepodTheme;
    }
  }
  return PepodTheme;
};
